import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { cachedAPIWrapper } from "../apiCaching/apiWrapper";
import { getImageReplacemnt } from "./imageHelper";
import { updateVDomElement, VNode } from "../util/virtualDom";

let page = 1;
let isLoading = false;

export class TestimonialVDOM {
    private readonly data: any;
    private pageNumber: number;
    private readonly pageSize: number;
    private readonly conceptId: any;
    private readonly countryId: any;
    private wrapperElement: any;
    private selectedStarRating: number;
    readonly popoverElement: HTMLElement | null;
    readonly upArrow: HTMLElement | null;
    readonly downArrow: HTMLElement | null;
    readonly resetLink: HTMLElement | null;
    private overAllRatings: string;
    private ratingCache: { [key: number]: any } = {}; // Cache for storing rating data
    private isFirstLoad = true; // check if its first load
    private static instance: TestimonialVDOM;

    static getVDOMInstance(): TestimonialVDOM {
        if (!TestimonialVDOM.instance) {
            TestimonialVDOM.instance = new TestimonialVDOM();
        }
        return TestimonialVDOM.instance;
    }
    constructor() {
        this.resetLink = document.getElementById('resetLink');
        this.upArrow = document.getElementById('upArrow');
        this.downArrow = document.getElementById('downArrow');
        this.popoverElement = document.getElementById('popover');
        this.pageNumber = 1; // Initial page number
        this.pageSize = window.innerWidth >= 768 ? 30 : 4; // Number of items per page
        this.conceptId = (document.getElementById("conceptId") as HTMLInputElement)?.value;
        this.selectedStarRating = 0;
        this.overAllRatings = '5/5';
        if (sessionStorage.getItem('countryCode') != null) {
            this.countryId = sessionStorage.getItem('countryCode')?.toLowerCase() == "us" ? "1" : "2";
        } else {
            this.countryId = (document.getElementById("countryCode") as HTMLInputElement)?.value.toLowerCase() == "us" ? "1" : "2";
        }

        document.addEventListener('DOMContentLoaded', () => {

            const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value;

            if (pageType && pageType.toLocaleLowerCase() !== "opus 2 location map") {
                const testimonialDOMisPresent = document.querySelector(".testimonials-section")
                if (testimonialDOMisPresent)
                    this.getData();
            }

            this.wrapperElement = document.querySelector('.testimonials-section .container');
            let btn = `<div class="testi-btn-wrapper">                
                 <a href="#" class="primary-btn"> See More...</a>                
                 </div>`
            this.wrapperElement?.insertAdjacentHTML('beforeend', btn);

            const seeMoreButton = document.querySelector('.testi-btn-wrapper .primary-btn');
            if (seeMoreButton) {
                seeMoreButton.addEventListener('click', this.seeMoreData.bind(this));
            }

            this.getTotalReviews();
            //Show the list of star rating dropdown on click event of dropdown arrow
            const starRatingDrpdwn = document.getElementById('popoverBtn');
            if (starRatingDrpdwn) {
                starRatingDrpdwn.addEventListener('click', () => {
                    this.handleDropdownToggle();
                });
            }
            //Show the list of star rating dropdown on click event of stars displayed
            const starsDisplay = document.getElementById('starsDisplay');
            if (starsDisplay) {
                starsDisplay.addEventListener('click', () => {
                    this.handleDropdownToggle();
                });
            }


            this.resetLink?.addEventListener('click', () => {
                this.onResetClick();
            });

        });
    }

    private async getData() {

        let weblocationId: string;
        const storedWebLocationId = localStorage.getItem("franchiseWebLocationId");
        const webLocationFromElement = (document.getElementById('weblocationId') as HTMLInputElement)?.value || '';

        // decide which to use base don availability
        if (storedWebLocationId) {
            weblocationId = storedWebLocationId;
        } else {
            weblocationId = webLocationFromElement;
        }

        let selectedRating = this.selectedStarRating ? this.selectedStarRating : 5;
        try {
            // selected rating
            const result = await this.getCompleteData(weblocationId, selectedRating);

            if (!result?.length) {
                this.showNoReviewSection();
            } else {
                this.hideNoReviewSection();
                this.displayTestimonialData(result, selectedRating);
                this.checkAndCacheNextPageData(result, weblocationId, selectedRating);

            }

        } catch (error) {
            this.wrapperElement?.classList.add('hidden');
            isLoading = false;
            this.showNoReviewSection();
        }
    }

    private checkAndCacheNextPageData(result: any, weblocationId: any, selectedRating: number) {

        // If there are more items than the page size
        if (result?.length >= this.pageSize) {
            // Cache next page of data if it's available
            this.cacheNextPageData(weblocationId, selectedRating, 2);
        }
    }

    private async cacheNextPageData(weblocationId: any, selectedRating: number, pageNumber: number) {
        try {
            const nextPageData = await this.fetchTestimonialData(selectedRating, weblocationId, pageNumber);

            // Cache if data exists for the next page
            if (nextPageData?.length > 0) {
                this.ratingCache[selectedRating] = this.ratingCache[selectedRating] || [];
                this.ratingCache[selectedRating][pageNumber] = nextPageData;
            }
        } catch (error) {
            console.error('Error fetching or caching next page data:', error);
        }
    }

    private async getCompleteData(weblocationId: any, selectedRating: number) {
        const ratingParams = [5, 4, 3, 2, 1];

        try {
            // Fetch the 5-star rating data first
            if (this.isFirstLoad) {
                const results = await this.fetchTestimonialData(5, weblocationId);
                this.ratingCache[5] = results; // Cache the 5-star data
                // Silent fetch 4, 3, 2, and 1 star data in parallel
                ratingParams.slice(1).forEach(rating => {
                    this.fetchTestimonialData(rating, weblocationId)
                        .then(data => {
                            this.ratingCache[rating] = data;
                        })
                        .catch(error => {
                            console.error(`Error fetching ${rating} star testimonial data:`, error);
                        });
                });

                // set the first load to false after completion
                this.isFirstLoad = false;
                return results;
            }

            // If it's not the first load, just return the selected rating data
            if (this.ratingCache[selectedRating]) {
                return this.ratingCache[selectedRating];
            }

            // If the selected rating data is not in cache, fetch it
            const selectedRatingData = await this.fetchTestimonialData(selectedRating, weblocationId);
            this.ratingCache[selectedRating] = selectedRatingData;
            return selectedRatingData;

        } catch (error) {
            console.error('Error fetching testimonial data:', error);
            throw error;
        }
    }


    private fetchTestimonialData(rating: number, weblocationId: any, pageNumber?: number) {
        const pageNumber1 = pageNumber ?? this.pageNumber;
        const url = apiConfig.TESTIMONIAL_REVIEWS + `/${this.conceptId}/${weblocationId || 0}/${this.countryId}/0/${pageNumber1}/${this.pageSize}/${rating}`;
        return cachedAPIWrapper(url, apiCaching.TestimonyAndReview);
    }
    private displayTestimonialData(result: any, rating: number) {
        const seeMoreButton = document.querySelector('.testi-btn-wrapper') as HTMLElement;
        this.wrapperElement?.classList.remove('hidden');
        if (seeMoreButton) {
            if (result.length === 0 || result.length < this.pageSize) {
                seeMoreButton.style["display"] = "none";
                isLoading = false;
            } else {
                seeMoreButton.style["display"] = "flex";
                isLoading = true;
            }
        }

        const lists = result.map((item: any) => this.createTestimonialVNode(item) ?? null).filter((item: any) => item !== null);
        const testimonialListNode: VNode = {
            type: 'ul',
            props: {},
            children: lists,
        };

        const testimonialsList = document.querySelector('.testimonials-section .container .testimonial-wrapper') as HTMLElement;
        if (testimonialsList) {
            testimonialsList.innerHTML = '';
            updateVDomElement(testimonialsList, testimonialListNode);
        }
    }

    public generateRatingStars(rating: number): string {
        const fullStar = '<span class="star full">★</span>';
        const emptyStar = '<span class="star empty">☆</span>';
        const numberOfFullStars = Math.round(rating);
        const hasHalfStar = rating % 1 !== 0;
        const numberOfEmptyStars = hasHalfStar ? 5 - numberOfFullStars - 1 : 5 - numberOfFullStars;
        return fullStar.repeat(numberOfFullStars) + (hasHalfStar ? '<span class="star half">★</span>' : '') + emptyStar.repeat(numberOfEmptyStars);
    }

    public createTestimonialVNode(data: any): VNode | null {
        if(!data.reviewAuthorName){
            return null
        }
        const photoDiv = data.reviewPhotoUrl === null ?
        (getImageReplacemnt(data.reviewAuthorName, 'testimonialVdom') ) :
        {
            type: 'div',
            props: { class: 'testi-profile' },
            children: [
                {
                    type: 'img',
                    props: {
                        src: data.reviewPhotoUrl,
                        alt: 'profile photo',
                        width: 72,
                        height: 73
                    },
                    children: []
                }
            ]
        };
        // rating stars
        const ratingStars = [];
        const starCountString = this.generateRatingStars(data.reviewStarRating ? Math.round(data.reviewStarRating) : 0);
        const starCount = (starCountString.match(/★/g) || []).length;
        for (let i = 0; i < 5; i++) {
            ratingStars.push({
                type: 'span',
                props: { class: `star ${i < starCount ? 'full' : ''}` },
                children: ['★']
            });
        }
        return {
            type: 'li',
            props: { class: 'testi-main-wrap' },
            children: [
                {
                    type: 'div',
                    props: { class: 'testi-main' },
                    children: [
                        {
                            type: 'div',
                            props: { class: 'testi-details' },
                            children: [
                                photoDiv,
                                {
                                    type: 'div',
                                    props: { class: 'testi-bio' },
                                    children: [
                                        { type: 'strong', props: { class: 'user-name' }, children: [data.reviewAuthorName] },
                                        data.city ? { type: 'address', props: { class: 'address' }, children: [data.city] } : '',
                                        {
                                            type: 'div',
                                            props: { class: 'rating' },
                                            children: ratingStars
                                        },
                                        data.reviewSourceType ? {
                                            type: 'span',
                                            props: { class: 'review-source' },
                                            children: [`via ${data.reviewSourceType}`]
                                        } : ''
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'div',
                            props: { class: 'testi-comment' },
                            children: [
                                data.reviewBody ? { type: 'p', props: { class: 'body-text-sm' }, children: [`“${data.reviewBody}”`] } : '',
                                data.reviewResponseBody ? {
                                    type: 'div',
                                    props: { class: 'testi-reply' },
                                    children: [
                                        { type: 'p', props: { class: 'body-text-sm' }, children: ['Owner Response:'] },
                                        { type: 'p', props: { class: 'body-text-sm' }, children: [`“${data.reviewResponseBody}”`] }
                                    ]
                                } : ''
                            ]
                        }
                    ]
                }
            ]
        };
    }
    private seeMoreData(event: any) {
        event.preventDefault();
        this.pageNumber++; // Increment the page number for the next page of data
        let ratingParam = `${this.selectedStarRating ? this.selectedStarRating : 5}`;

        const url = apiConfig.TESTIMONIAL_REVIEWS + `/${this.conceptId}/${localStorage.getItem("franchiseWebLocationId") ?? 0}/${this.countryId}/0/${this.pageNumber}/${this.pageSize}/${ratingParam}`;

        cachedAPIWrapper(url)
            .then((result: any) => {
                if (!result?.length) {
                    this.showNoReviewSection();
                    return;
                }
                this.hideNoReviewSection();
                const lists = result.map((item: any) => {
                    return this.createTestimonialVNode(item);
                });
                const testimonialsList = document.querySelector('.testimonials-section .container .testimonial-wrapper') as HTMLElement;
                if (testimonialsList) {
                    const testimonialListNode: VNode = {
                        type: 'ul',
                        props: {},
                        children: lists,
                    };
                    updateVDomElement(testimonialsList, testimonialListNode);
                }
                const seeMoreButton = document.querySelector('.testi-btn-wrapper') as HTMLElement;
                if (!result || result.length == 0 || result.length < this.pageSize) {
                    seeMoreButton.style["display"] = "none";
                }
                else {
                    seeMoreButton.style["display"] = "flex";
                    this.fetchNextPageSilently();
                }
            })
            .catch((err) => {
                console.log(err);
                this.showNoReviewSection();
            });
    }

    private fetchNextPageSilently() {
        // Increment pageNumber to load the next page
        const nextPageNumber = this.pageNumber + 1;

        let ratingParam = `${this.selectedStarRating ? this.selectedStarRating : 5}`;
        const url = apiConfig.TESTIMONIAL_REVIEWS + `/${this.conceptId}/${localStorage.getItem("franchiseWebLocationId") ?? 0}/${this.countryId}/0/${nextPageNumber}/${this.pageSize}/${ratingParam}`;

        // Make the silent API call to fetch the next page 
        cachedAPIWrapper(url)
            .then((result: any) => {
                if (result?.length) {
                    console.log(`Page ${nextPageNumber} cached silently.`);
                }
            })
            .catch((err) => {
                console.error("Failed to fetch next page silently:", err);
            });
    }

    private handleStarRatingFilter() {
        const ratingForm = document.getElementById("ratingForm");
        if (ratingForm) {
            const radioButtons = ratingForm.querySelectorAll('input[name="rating"]');
            // Add a change event listener to each radio button
            radioButtons?.forEach((radioButton) => {
                (radioButton as HTMLInputElement).onclick = () => {
                    const selectedRadio = ratingForm.querySelector('input[name="rating"]:checked') as HTMLInputElement;
                    if (selectedRadio) {
                        this.selectedStarRating = parseInt(selectedRadio.value, 10);
                        this.pageNumber = 1;//reset page number to 1
                        this.getData();
                        this.showHideRatingDrpDwn();
                        this.updateStarsDisplay(this.selectedStarRating);
                        this.handleRatingDisplayChanges();
                    }
                }
            });
        }
    }

    private handleDropdownToggle() {
        if (this.popoverElement) {
            this.showHideRatingDrpDwn()
            this.handleStarRatingFilter();
        }
    }

    private showHideRatingDrpDwn() {
        this.popoverElement?.classList?.toggle("hidden");
        this.upArrow?.classList?.toggle("hidden");
        this.downArrow?.classList?.toggle("hidden");
    }

    private updateStarsDisplay(rating: number) {
        const starsContainer = document.getElementById('starsDisplay');
        if (starsContainer) {
            starsContainer.innerHTML = "";
            for (let i = 0; i < rating; i++) {
                const starElement = document.createElement("span");
                starElement.className = "star full";
                starElement.innerHTML = '&#9733;';
                starsContainer.appendChild(starElement);
            }
        }
    }

    private handleRatingDisplayChanges() {
        const ratingText = document.getElementById('ratingText');
        const ratingsInfo = document.getElementById('ratingsInfo');
        if (this.resetLink?.classList.contains("hidden")) {
            this.resetLink?.classList.remove("hidden");
            this.resetLink.classList.add('reset-text');
            if (ratingText) {
                ratingText.textContent = 'Showing:';
                ratingText.classList.remove('ratings-text');
                ratingText.classList.add('showing-text');
                ratingsInfo?.classList.remove('ratings-info');
                ratingsInfo?.classList.add('hidden');
            }
        }
    }

    private onResetClick() {
        const ratingText = document.getElementById('ratingText');
        const ratingsInfo = document.getElementById('ratingsInfo');
        if (ratingText) {
            ratingText.textContent = this.overAllRatings;
            ratingText.classList.remove('showing-text');
            ratingText.classList.add('ratings-text');
        }
        this.resetLink?.classList.add('hidden');
        this.updateStarsDisplay(5);
        const ratingForm = document.getElementById("ratingForm");
        if (ratingForm) {
            const radioButtons = ratingForm.querySelectorAll('input[name="rating"]');
            // Add a change event listener to each radio button
            radioButtons?.forEach((radio) => {
                const inputRadio = radio as HTMLInputElement;
                inputRadio.checked = false; 
            });
        }
        ratingsInfo?.classList.remove('hidden');
        ratingsInfo?.classList.add('ratings-info');
        this.selectedStarRating = 0;
        this.pageNumber = 1;
        this.getData();
    }

    async getTotalReviews() {
        let weblocationId: any;
        // Check sessionStorage first
        if (localStorage.getItem("franchiseWebLocationId")) {
            weblocationId = localStorage.getItem("franchiseWebLocationId");
        } else {
            // Check if the value exists in the DOM element
            const weblocationElement = document.getElementById('weblocationId') as any;
            weblocationId = weblocationElement?.value ? weblocationElement.value : '';
        }
        const url = apiConfig.TESTIMONIAL_REVIEWS + `/${this.conceptId}/${weblocationId || 0}/${this.countryId}/1/1/1`;

        try {
            let result = await cachedAPIWrapper(url, apiCaching.TestimonyAndReview);
            if (result.length) {
                const totalReviews = document.querySelector("#ratingsInfo p");
                if (totalReviews) {
                    totalReviews.textContent = `${result[0]?.grandTotal} Ratings`;

                }
                const overAllRatingsdiv = document.getElementById("ratingText");
                if (overAllRatingsdiv) {
                    this.overAllRatings = `${result[0]?.overallRating}/5`;
                    overAllRatingsdiv.textContent = this.overAllRatings;
                }
            }
        }
        catch {
            console.log("Unable to fetch total ratings");
        }

    }

    private showNoReviewSection() {
        const testimonialsSection = document.querySelector('.testimonials-section');
        const noReviewSection = document.querySelector('.no-review-section');
        testimonialsSection?.classList.add('hidden');
        noReviewSection?.classList.remove("hidden");
    }
    private hideNoReviewSection() {
        const testimonialsSection = document.querySelector('.testimonials-section');
        const noReviewSection = document.querySelector('.no-review-section');
        testimonialsSection?.classList.remove('hidden');
        noReviewSection?.classList.add("hidden");
    }
}
const testimonial = new TestimonialVDOM();
