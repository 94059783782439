import { cachedAPIWrapper } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { getBrandDetailsFromBrandJson } from "./share";
import { updateVDomElement } from "./virtualDom";

export async function toggleHoursOfOperation( serveHoursClass: string,
    serveHoursListClass: string,
    serveHoursListItemClass: string){
    const brandJSON = localStorage.getItem("brandDetails");
    if(brandJSON){
        const brandDetails = JSON.parse(brandJSON);
        if(brandDetails.disableHoursOfOperation){
            hideHoursOfOperation(serveHoursClass);
            return
        }
         getHoursOfOperation(serveHoursListClass, serveHoursListItemClass);
    }else{
        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        if (conceptCodeElement) {
            const conceptCode = conceptCodeElement.value;
            try {
                    const result :any = await getBrandDetailsFromBrandJson(conceptCode);
                   
                if (result.code === conceptCode) {
                    // Handle the found brand directly
                    const brandDetails = result;
                    localStorage.setItem("brandDetails", JSON.stringify(brandDetails));
                    if (brandDetails.disableHoursOfOperation) {
                        hideHoursOfOperation(serveHoursClass);
                        return
                    }
                    getHoursOfOperation(serveHoursListClass, serveHoursListItemClass);
                } 
            } catch (error) {
                console.error('Error in fetching getBrandDetailsFromBrandJson API data:', error);
                return [];
            }
        }
    }

    // If 'fullAttrOnLoad' is not present in localStorage, fetch it from API
    const fullAttrOnLoad = localStorage.getItem("fullAttrOnLoad");
    if (!fullAttrOnLoad) {
        try {
            const localWebID = localStorage.getItem("franchiseWebLocationId");
            const webID = document.getElementById('weblocationId') as HTMLInputElement;
            let weblocationID = webID?.value ? webID?.value : localWebID;
            if (weblocationID) {
                const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${weblocationID}`;
                const response = await cachedAPIWrapper(url);
                const fullAttributeData = response;
                localStorage.setItem("fullAttrOnLoad", JSON.stringify(fullAttributeData));
                // Once data is available, proceed to set hours of operation
                setHoursOfOperation(serveHoursListClass);
            }

        } catch (error) {
            console.error('Error fetching full attribute data:', error);
        }
     }
}

function hideHoursOfOperation(serveHoursClass: string){
    const contactElement = document.querySelector(serveHoursClass) as HTMLLIElement;
    if(contactElement)
        contactElement.style.display = 'none';
}

function setCardTextColor() {
    // to set color to current day
    const cardTextElements = document.querySelectorAll('.card-text');
    cardTextElements.forEach((element) => {
        const cardTextElement = element as HTMLElement;
        cardTextElement.style.color = 'var(--primary-500)';
    });
}

function getHoursOfOperation(serveHoursListClass: string, serveHoursListItemClass: string) {
    try {
        const ulElement = document.querySelector(serveHoursListClass) as HTMLUListElement;
        const items = document.querySelectorAll(serveHoursListItemClass);
        //Checking if the Hours are already authored by CP
        if (ulElement && ulElement.children.length<7) {
            ulElement.innerHTML = '';
            setHoursOfOperation(serveHoursListClass);
        }
        else if(ulElement && ulElement.children.length===7 && items){
            let authoredArray:any = []
            items.forEach((element)=>authoredArray.push(element.textContent))

            //adding -1 as CP is starting the week from Monday instead of Sunday
            const curDay = (new Date().getDay())-1;
            authoredArray.splice(0,0, ...authoredArray.slice(curDay));
            authoredArray.splice(7,(7-curDay));
            const authoredVNode = {
                type: 'ul',
                props: {
                  class: 'get_in_touch_hrs_operation',
                },
                children: authoredArray.map((hour: string, index: number) => ({
                  type: 'li',
                  props: {
                    class: (index === 0) ? 'card-text' : 'card-listtext',
                  },
                  children: [hour],
                })),
              };
                updateVDomElement(ulElement, authoredVNode);
                setCardTextColor();
        }
        
    } catch (error) {
        console.log(error);
    }
}

function setHoursOfOperation(serveHoursListClass: string) {
    const ulElement = document.querySelector(serveHoursListClass) as HTMLUListElement;
    const fullAttrOnLoad = localStorage.getItem("fullAttrOnLoad");

    //checking if the said entry exists in the local storage
    if (fullAttrOnLoad && JSON.parse(fullAttrOnLoad)?.hoursofOperation) {
        const data = JSON.parse(fullAttrOnLoad);
        const DaysOfWeek: { [key: number]: string } = {
            0: 'Sunday',
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday'
        };

         //get the current date of the week in numerical
        const curDay = new Date().getDay();
        // Sorting to ensure hours are in ascending order of days
        const hoursofOperation = data.hoursofOperation.sort((a: { dayOfWeek: number; }, b: { dayOfWeek: number; }) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1);

        // Ensure current day is the first item in the week array
        hoursofOperation.splice(0, 0, ...hoursofOperation.slice(curDay));
        hoursofOperation.splice(7, (7 - curDay));

        // Safely initialize the array `hoursofOperationbyCurDay`
        const hoursofOperationbyCurDay = hoursofOperation.map((item:any, index:any) => {
            let NormalHour = "";
            if (!item.isOpenForNormalHours) {
                NormalHour = "Closed";
            } else {
                //section to convert minutes to hours
                let curStartHour: any = Math.floor(parseInt(item.startMinutes) / 60);
                let curStartMinute: any = Math.floor(parseInt(item.startMinutes) % 60).toString();
                let curEndHour: any = Math.floor(parseInt(item.endMinutes) / 60);
                let curEndMinute: any = Math.floor(parseInt(item.endMinutes) % 60).toString();

                //Setting the AM and PM based on hour value
                const curStartHour_suffix = (curStartHour >= 12) ? " PM" : " AM";
                const curEndHour_suffix = (curEndHour >= 12) ? " PM" : " AM";

                //Prefixing 0 if the value of minute is a single digit
                curStartMinute = curStartMinute.length == 1 ? "0" + curStartMinute : curStartMinute;
                curEndMinute = curEndMinute.length == 1 ? "0" + curEndMinute : curEndMinute;

                //Converting the 24 hour format to a 12 hour format
                curStartHour = ((curStartHour + 11) % 12 + 1) + ":" + curStartMinute + curStartHour_suffix;
                curEndHour = ((curEndHour + 11) % 12 + 1) + ":" + curEndMinute + curEndHour_suffix;

                //Final time format in hours and minutes (in a 12 hour format)
                NormalHour = curStartHour + " - " + curEndHour;
            }
            const formattedHours = `${DaysOfWeek[item.dayOfWeek]} ${NormalHour}`;

            return {
                type: 'li',
                props: {
                    class: index === 0 ? 'card-text' : 'card-listtext'
                },
                children: [formattedHours]
            };
        });

        // Update the actual DOM based on the virtual DOM
        if (ulElement) {
            updateVDomElement(ulElement, {
                type: 'ul',
                props: {},
                children: hoursofOperationbyCurDay
            });
            setCardTextColor();
        }
    }
}
