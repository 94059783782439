export function getServiceAccordionPath(skipgroup: string): string {
    const url = window.location.origin;
    const localWebID = localStorage.getItem("franchiseWebLocationId");
    const webID = document.getElementById('weblocationId') as HTMLInputElement;
    const weblocationID = webID?.value ? webID?.value : localWebID as string;
    const dbaName: string | null = localStorage.getItem('dbaName');
    let pathSegments = [];
    const tabsUl = document.querySelector('.residential-accordion .req-call-tabs') as HTMLElement;

    if (dbaName && tabsUl) {
        pathSegments.push('serviceaccordion', 'show', skipgroup, weblocationID, dbaName);
    } else if (dbaName) {
        pathSegments.push('serviceaccordion', 'hide',skipgroup, weblocationID, dbaName);
    }

    return `${url}/${pathSegments.join('/')}`;
}

export function toggleTabs(targetID: string) {
    const residentialDom = document.querySelector('.residential-accordion') as HTMLDivElement;
    const allItems = residentialDom?.querySelectorAll(".accordion-icon-list .card-list");
    const ulAccordion = document.querySelector('.residential-accordion .accordion-icon-list');
    if (ulAccordion) {
        (ulAccordion as HTMLElement).style.display = ''; // This removes the inline display style set in CP for ul
    }
    allItems?.forEach((elem: any) => {
        (elem).style.display = "none"; // Hide all items
    });

    // Define the authorable sections
    const authorableSections: any = {
        residential: residentialDom?.querySelectorAll('.authorable-residential'),
        commercial: residentialDom?.querySelectorAll('.authorable-commercial'),
        auto: residentialDom?.querySelectorAll('.authorable-auto'),
        emergency: residentialDom?.querySelectorAll('.authorable-emergency'),
    };

    // Hide all authorable sections by default
    Object.values(authorableSections)?.forEach((sections: any) => {
        sections?.forEach((section: any) => {
            section.style.display = 'none'; // Hide each section
        });
    });

    // Handle target elements based on the tab ID
    const targetClass = `service-acc-tab--${targetID.replace("accor-", "")}`;
    const targetElements = residentialDom?.querySelectorAll(`.accordion-icon-list .${targetClass}`);
    targetElements?.forEach((elem: any) => {
        (elem).style.display = "flex"; // Show target elements
    });

    // Show the appropriate authorable section based on the targetID
    const sectionMap: any = {
        'accor-residential': ['residential'],
        'accor-commercial': ['commercial'],
        'accor-auto': ['auto'],
        'accor-emergency': ['emergency'],
    };

    // Show the sections based on the targetID
    const sectionKey = sectionMap[targetID] || [];
    if (sectionKey) {
        const sectionsToShow = authorableSections[sectionKey];
        sectionsToShow.forEach((section: any) => {
            section.style.display = 'flex'; // Show all sections for this type (e.g., all residential)
        });
    }
}

export function attachNewTabListeners() {

    const residentialDom = document.querySelector('.residential-accordion') as HTMLDivElement;
    const tabsBtn = residentialDom?.querySelector('.req-call-tabs');
    tabsBtn?.addEventListener('click', (event) => {
        const target = event.target as HTMLElement;
        const label = target as HTMLLabelElement; // Cast to HTMLLabelElement
        const radioInput = residentialDom.querySelector(`#${label.htmlFor}`) as HTMLInputElement;
        if (radioInput) {
            // Programmatically check the corresponding radio button
            radioInput.checked = true;

            // Extract the ID or data attribute to identify the tab (if necessary)
            const tabId = radioInput.dataset.nbly;

            // Call toggleTabs with the tab ID (if your logic requires it)
            if (tabId) {
                toggleTabs(tabId);
            }
        }
    });
}