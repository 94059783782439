import { tns } from "tiny-slider";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { startLoader, stopLoader } from "../util/loader";
import { allAddress, beforeUnloadHandler, eventListner, fullData, isLeadData, changeZip, setchangeZip } from "./step1";
import { inspectionFeeObj, serviceSelect, serviceSelectCat, serviceSelectFloors, addressFromInput, serviceStype,serviceSkuId} from './step2'
import { REGEX } from "../constants/regex";
import { getBrandDetailsFromBrandJson, removeAllEventListeners, storeBookingApiPayload } from "../util/share";
import { address1Details, handleMissingData } from "../util/discovery";
import { isTestFcn } from "../mly/booking-payload";
import { disclaimerHandleCheck } from "../util/disclaimerService";
import { DomainIdentifier } from "../util/domainService";
import { LocalizationIdentifier } from "../util/localizationService";
import { getSessionAndCorrelationID } from "../util/getSessionCorrelationId";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { requestEstimate } from "../util/requestEstimate";

let allAppData : any = []

let moment = require('moment');
// const FilesUrl: any = []
let date = ''
let time = ''
let schaduleId = ''
let timeShown = ''

export class AppointmentForm {
  private buttonName: any = [];
  private onlineScheduling :boolean;
  private onlineSchedulingFlagValidation :boolean;
  private isDateTime:boolean = false;
  private shgSolutionType:any = null;
  private sentCommentAndNote :boolean = true;
  constructor(onlineScheduling:boolean) {
    this.onlineScheduling = onlineScheduling;
    if (this.onlineScheduling !== null && this.onlineScheduling !== undefined) {
      sessionStorage.setItem('onlineSchedule', this.onlineScheduling.toString());
    } 
    this.onlineSchedulingFlagValidation = onlineScheduling;
    if(this.onlineScheduling){
      this.setAppointmainCalander();
    }
    this.init();
  }

  private init() {
    this.enableDisableCalendarBtn();
    const container = this.removeAllEventListeners('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement

    const checkBoxContainer = removeAllEventListeners('#link-checkbox')

    if (checkBoxContainer) {
      checkBoxContainer.addEventListener('click', this.handleDisableApp.bind(this))
    }
    // container.disabled = true
    // container.style.opacity = '0.3'
    container?.addEventListener('click', this.handleSubmit.bind(this))

    // const cancleBtn = document.querySelectorAll('.contact-us-section .btn-cancel')

    const cancleBtn = removeAllEventListeners('.contact-us-section .btn-cancel')
    if (cancleBtn) {
      cancleBtn.addEventListener('click', () => {
        const formNext = document.querySelectorAll('#form-section .contact-us-section');
        const activeFormIndex = Array.from(formNext).findIndex(form => form.classList.contains('form-step-active'));
        const progress = document.querySelectorAll('.progressbar .progress-step')
        const progressBar: any = document.getElementById('progress')
        const form1 = formNext[activeFormIndex]
        const form2 = formNext[activeFormIndex-1]
        const progress1 = progress[activeFormIndex]
        const progress2 = progress[activeFormIndex-1];
        const totalSteps = formNext.length;
        const newProgressWidth = ((activeFormIndex - 1) / (totalSteps-1)) * 100;
        progressBar.style.width = `${newProgressWidth}%`;
        // progressBar.style.width = "50%"
        form1.classList.remove('form-step-active')
        form2.classList.add('form-step-active')
        progress1.classList.remove('progress-step-active')
        if(activeFormIndex==1){
          progress1.classList.remove('progress-step-confirmed')
        }
        progress2.classList.add('progress-step-active')
      })


    }

  }


  removeDuplicateDates(resultCollection: any, hasSameDay: any) {
    const uniqueDates = new Set();
    const filteredResultCollection = [];
    for (const item of resultCollection) {
      const startDate = item.startDate.split('T')[0]; // Extract the date part
      if (!uniqueDates.has(startDate)) {
        uniqueDates.add(startDate);
        filteredResultCollection.push(item);
      }
    }

    const hasSameDaate = moment().format('DD MMM, YYYY')
    if (hasSameDay == false){
      
      const data =  filteredResultCollection.filter((x: any) => hasSameDaate != moment(x.startDate).format('DD MMM, YYYY'));

      return data
    }
    else{
      return filteredResultCollection;
    }
  }



 async setAppointmainCalander() {
    let shgAppointment = '';
    let solutionRadioBtn = document.querySelector("input[type='radio'][name=solution-type]");
    let zipValue = (document.getElementById('zip-code') as HTMLInputElement)?.value;
    if(solutionRadioBtn){
      let solutionAppointment = (document.querySelector("input[type='radio'][name=solution-type]:checked")as HTMLInputElement)?.value;
      if(solutionAppointment){
        this.shgSolutionType = solutionAppointment;
      }else{
        this.shgSolutionType = 'Shelf';
      }

      solutionAppointment = solutionAppointment ?? 'both';
      if(this.onlineScheduling && sessionStorage.getItem('solutionAppointment') == 'true'){
        shgAppointment = `&ShgAppointmentType=${solutionAppointment}`
      } else if(this.onlineScheduling && !window.offersClosetAppointments){
        shgAppointment = `&ShgAppointmentType=Shelf`
      }
    }
   
    if(REGEX.sendCaPostalCode.test(zipValue)){
      //if control comes here means entered zip is for CA locations
      zipValue = zipValue?.indexOf(" ") == -1 ? zipValue?.replace(/^(.{3})(.*)$/, "$1 $2") : zipValue;
    }
    try {
      const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
      const brandData = await getBrandDetailsFromBrandJson(conceptCode);
      const vendorId = brandData?.vendorId;
      let dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${localStorage.getItem("franchiseWebLocationId")}&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=true&apikey=${process.env.JS_API_KEY}${shgAppointment}&VendorId=${vendorId}`
   
      let calendarTypeId = window.calendarTypeId;
      if(calendarTypeId == 2 && serviceSkuId){
        dynamic_url += '&ServiceSkuId=' + serviceSkuId
      }
      const request = {
        method: 'GET',
        url: dynamic_url,
      };
      
      if (date == "" || shgAppointment || (calendarTypeId == 2 && serviceSkuId)){ 
        this.calendarApiRequest(request);
      }else{
        if(changeZip){
          setchangeZip(false);
          this.calendarApiRequest(request);
        }
      }
        
    }
    catch (err) {
      // 
    }
  }

  private getCheckedCheckboxes = () => {
    let checkedCheckboxes: any = [];
    checkedCheckboxes = Array.from(document.querySelectorAll('.received-updates') as NodeListOf<HTMLInputElement>)
        .filter((checkbox: { checked: any }) => checkbox.checked)
        .map((checkbox: { value: any }) => checkbox.value);
    return checkedCheckboxes.join(" , ");
};

  private getCheckedPreferedComunnication = () => {

    const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"][data-preferred-communication]:checked');
    let checkboxString = '';

    checkedCheckboxes.forEach((checkbox, index) => {
      const preferredCommunication = checkbox.getAttribute('data-preferred-communication');
      checkboxString += this.capitalizeString(preferredCommunication as any);
      if (index < checkedCheckboxes.length - 1) {
        checkboxString += ',';
      }
    });

    return checkboxString;

  };
  

  setTimeUl(item:any){
    const calendarRow = document.querySelector('.calendar-row');
    const sampleData : any = allAppData?.filter((x:any)=> moment(x.startDate).format('DD MMM, YYYY') ==  moment(item.startDate).format('DD MMM, YYYY'))
    
      const ulTimeData =   sampleData?.map((x:any, i:any)=>{
        return(`<li class="calendar-row-item"><button type="button" value="${x.scheduleID}" class="calendar-col btn-time  ${x.isAvailable == false ? 'disable-btn' : ''} "  id="${x.displayText}">${x.displayText}</button></li>`)
      })

      if(calendarRow)
        calendarRow.innerHTML = ulTimeData.join('')
      this.setListnerOnUlBtn();
      this.setAppointmentTimeText(sampleData);
  }

  setListnerOnUlBtn(){
    const timesBtn = document.querySelectorAll('.calendar-col');
    timesBtn.forEach(e => e.classList?.remove('active'));
    if (timesBtn && timesBtn.length > 0) {
      timesBtn.forEach((item: any) => {
        
        item.addEventListener('click', (e: any) => {
          timesBtn.forEach(e => e.classList.remove('active'));
          item.classList.toggle('active');
          const timecontainer = document.querySelector('.select-time')
          if (timecontainer) 
            timecontainer.innerHTML = item.innerHTML
          schaduleId = item.value;
          time = item.id;
          this.isDateTime = true;
          this.enableButton()
          this.hideErrorMessage();
          if(timecontainer?.innerHTML) {
            const val1 = timecontainer?.innerHTML
          sessionStorage.setItem('time', `${val1}`);
          }
        })
      
      })
    }
  }
  timeFormatChanger(timeButtons: NodeListOf<Element>) {
    const remobeTime = document.querySelectorAll('.calendar-col.btn-time');
    remobeTime.forEach(element => {
      const textContent = element.textContent;
      if (textContent) {
        let timeRange = textContent.trim().split(' - ');
        let modifiedTimeRange = timeRange.map(time => {
          time = time.replace(/^0/, '');
          time = time.replace('PM', 'pm').replace('AM', 'am');
          return time;
        });
        let modifiedTime = modifiedTimeRange.join(' - ');
        element.textContent = modifiedTime;
      } else {
        console.warn("Text content of the element is null.");
      }
    });
  }

  setAppointmentTimeText(data: any) {
    const timeButtons = document.querySelectorAll('.calendar-col.btn-time');
    this.timeFormatChanger(timeButtons);
    timeButtons.forEach((item) => {
      data?.forEach((element: any) => {
        if (item.innerHTML == element.displayText) {
          item.setAttribute("value", element.scheduleID);
          if (!element.isAvailable)
            item.setAttribute("disabled", "");
        }
      });

    })
  }

  setTinySlider(currentIndex:any) {

    try {
      var dtlslider = tns({
        container: '.date-slider',
        loop: false,
        items: 7,
        slideBy: 'page',
        nav: false,
        autoplay: false,
        speed: 400,
        swipeAngle: false,
        autoplayButtonOutput: false,
        mouseDrag: true,
        lazyload: true,
        // startIndex:currentIndex,
        controlsContainer: "#customize-controls",
        responsive: {
          350: {
            items: 5,
          },
          640: {
            items: 5,
          },
          768: {
            items: 5,
          },
          1024: {
            items: 7,
          }
        }

      });

      dtlslider.goTo(currentIndex);

    } catch (error) {

    }
  }

  addListner() {
    const dateApps = document.querySelectorAll('.dateapp');
    dateApps.forEach((item: any) => {
      item.addEventListener('click', (e: any) => {
        // const btn : any =  document.getElementById('dateapp')
        const rm = document.querySelectorAll('.dateapp')
        rm.forEach(e => e.classList.remove('date-details-active'));

        date = moment(item.querySelector('.getDateApp').value).format('ddd DD MMM, YYYY');
        time = '';
        schaduleId = item.querySelector('.scheduleID').value
        this.isDateTime = false;
        item.classList.toggle('date-details-active')

      const itemFInd =   allAppData?.find((x:any)=> x.scheduleID == schaduleId)
     
        document.querySelector('.appointment-section-text')!.innerHTML = `${moment(item.querySelector('.getDateApp').value).format('ddd MMM DD, YYYY')}, <span class="select-time"> Please Select a Time </span>`;
              
        this.setTimeUl(itemFInd)

      })
    })
  }



  private setCard = (data: any) => {
    return data.map((item: any) => {
      const filterData : any = allAppData?.some((x:any)=> moment(x.startDate).format('DD MMM, YYYY') ==  moment(item.startDate).format('DD MMM, YYYY') && x.isAvailable)
      return (`
                                      <li class="date-details-item">
                                        <button type="button" class="${ !filterData ? 'date-details-disable' : moment(item.startDate).format('ddd DD MMM, YYYY') === moment(date).format('ddd DD MMM, YYYY') ? 'date-details-inactive date-details-active dateapp' : 'date-details-inactive dateapp'}"
                                        value=${item.startDate}>
                                            <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('MMM')}</span>
                                            <strong value=${item.startDate} class="date-text-normal">${moment(item.startDate).format('DD')}</strong>
                                            <input class="getDateApp" hidden value=${item.startDate}>
                                            <input class="scheduleID" hidden value=${item.scheduleID}>                                
                                            <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('ddd')} </span>
                                        </button>
                                    </li>
      `)
    })
    
  }

  hideErrorMessage(){
    const dateTime = document.getElementById('appointment-date-time-error-msg') as HTMLElement;
    const requestCallbackCheckbox = document.getElementById('link-checkbox-error-msg') as HTMLElement;
    if (dateTime) dateTime.classList.add('hidden');
    if (requestCallbackCheckbox) requestCallbackCheckbox.classList.add('hidden');
  }
  private validateForm(): boolean {
    const dateTime = document.getElementById('appointment-date-time-error-msg') as HTMLElement;
    const requestCallbackCheckbox = document.getElementById('link-checkbox-error-msg') as HTMLElement;

    const linkCheckbox: HTMLInputElement | null = document.getElementById('link-checkbox') as HTMLInputElement | null;

    if ((date && time) || linkCheckbox?.checked || !this.onlineSchedulingFlagValidation) {
        return true;
    } else {
        if (dateTime) {
           dateTime.classList.remove('hidden');
           if(!dateTime?.innerText){
            dateTime.innerText = "Please select a date and time";
           }
        }
        if (requestCallbackCheckbox) {
            requestCallbackCheckbox.classList.remove('hidden');

            if(!requestCallbackCheckbox?.innerText){
              requestCallbackCheckbox.innerText = "or choose to get a call back.";
             }
        }
        return false;
    }
}

  private getValueService() {
    const checkedRadio = document.querySelector('input[name="service-type"]:checked') as HTMLInputElement;

    // Check if a radio input is checked
    if (checkedRadio) {
        // Get the label associated with the checked radio input
        const labelForCheckedRadio = document.querySelector(`label[for="${checkedRadio.id}"]`) as HTMLLabelElement;

        // Get the text content of the label
        const labelText = labelForCheckedRadio?.textContent;

        // Use labelText as needed
        return labelText 
    }
    
    return 'Residential' 
}

getVisibleServiceReminderCheckboxChecked(): boolean {
  // Get all divs with the class 'service-reminder-main'
  const serviceReminderDivs = document.querySelectorAll('.service-reminder-main');
  // Check if any elements were found
  if (serviceReminderDivs.length === 0) {
    return false;
  }
  // Loop through each div and check if it's not hidden
  for (let div of serviceReminderDivs) {
    // Check if the div is not hidden
    if (!div.classList.contains('hidden')) {
      //to find the checkbox inside the visible div
      const checkbox = div.querySelector('input[type="checkbox"]'); 
      // If checkbox exists, return its checked state
      if (checkbox instanceof HTMLInputElement) {
        return checkbox.checked;
      }
    }
  }
  // If no visible checkbox is found or checkbox is optional, return null (or false)
  return false;
}

  getValues() {
    return {
      name: (document.getElementById('user-full-name') as HTMLInputElement)?.value,
      lastName: (document.getElementById('user-last-name') as HTMLInputElement)?.value,
      phone: (document.getElementById('user-phone-number') as HTMLInputElement)?.value,
      email: (document.getElementById('user-email') as HTMLInputElement)?.value,
      address: (document.getElementById('user-service-address') as HTMLInputElement)?.value,
      address2: (document.getElementById('user-address-2') as HTMLInputElement)?.value,
      zip: (document.getElementById('zip-code') as HTMLInputElement)?.value,
      floorLabel : (document.querySelector('label[for="select-floor"].label-basic') as HTMLElement)?.innerText,
      notes: (document.getElementById('send-note') as HTMLTextAreaElement)?.value,
      service: serviceSelect ? serviceSelect : (document.getElementById('select-service') as HTMLSelectElement)?.value,
      catService: serviceSelectCat ? serviceSelectCat : (document.getElementById('select-service-category') as HTMLSelectElement)?.value,
      floorService : serviceSelectFloors ?? '', //: (document.getElementById('select-floor') as HTMLSelectElement)?.value,
      emailCheckbox: (document.getElementById('default-checkbox') as HTMLInputElement)?.value,
      now: (document.getElementById('checked-checkbox') as HTMLInputElement)?.value,
      howAboutUsSelect: (document.getElementById('how-about-us') as HTMLSelectElement)?.value,
      isCalanderDIsable: (document.getElementById('link-checkbox') as HTMLInputElement)?.checked ? (document.getElementById('link-checkbox') as HTMLInputElement)?.checked : isLeadData,
      serviceCheck: this.getVisibleServiceReminderCheckboxChecked()
    }

  }

   capitalizeString(str:string) {
    if (!str) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
 
  public async handleSubmit(event: Event, isFlag = true): Promise<void> {
    event.preventDefault();
    const formNext = document.querySelectorAll('#form-section .contact-us-section');
    const calendarIndex = Array.from(formNext).findIndex(form => form.classList.contains('appointment-details-section'));

    if (this.validateForm()) {
      if(calendarIndex ==2){ // Checking current index for OS FLow step 
        this.bookingProcess(event, isFlag)
      }else{
        const formNext = document.querySelectorAll('#form-section .contact-us-section')
        const progress = document.querySelectorAll('.progressbar .progress-step')
        const progressBar: any = document.getElementById('progress')
        const form1 = formNext[1]
        const form2 = formNext[2]
        const progress1 = progress[1]
        const progress2 = progress[2]
        progressBar.style.width = "100%"
        form1.classList.remove('form-step-active')
        form2.classList.add('form-step-active')
        progress1.classList.remove('progress-step-active')
        progress1.classList.add('progress-step-confirmed')
        progress2.classList.add('progress-step-active')
      }
    } else {
      stopLoader()
    }
  }

  public async bookingProcess(event: Event, isFlag = true){
    startLoader();

    const data = {
      franchiseWebLocationId: '',
      franchiseId: ''
    }
    const campaignName = sessionStorage.getItem('campaignName') ?? 'N/A';
    var host = window.location.hostname.toLowerCase();
    let IsTest = isTestFcn();
    const formData = this.getValues();    

    const selectedServiceType:any = this.geServiceTypetValue();

    let servicePayload = formData?.catService !== undefined ? ", ServiceCat: " + formData?.catService: '' 
    let serviceLead = formData?.howAboutUsSelect ? `${formData?.notes ? ',':""}, Lead Source: ` +  formData?.howAboutUsSelect: '';
    let serviceFloor, selectedFloor;
    if(formData?.floorLabel === undefined){
      serviceFloor = "";
      selectedFloor = "";
    } 
    else{
      serviceFloor = formData?.floorService !== undefined ? `, ${formData?.floorLabel}:` + formData?.floorService: '';
      selectedFloor = formData?.floorService !== undefined ? formData?.floorService: '';
    }
    let serviceServiceType = formData?.service ? ", ServiceType: " + formData?.service: '' 
    let serviceType = selectedServiceType !== undefined ? `${serviceLead && formData.notes ? ', ':'' }Service: ` + selectedServiceType: '' 
    let serviceSkuTypeId = window?.calendarTypeId ==2 && serviceSkuId ? ", Service Sku Id: " + serviceSkuId: '' 

    sessionStorage.setItem("serviceType",selectedServiceType);
    if(formData?.notes){
      sessionStorage.setItem("os_note",formData?.notes);
    }
    sessionStorage.setItem("floorService",formData?.floorService);
    sessionStorage.setItem("floorLabel", formData?.floorLabel);
   

    let manualCity = sessionStorage.getItem("manualCity");
    let manualState = sessionStorage.getItem("manualState");
   
    let city = manualCity ? manualCity : "";
    let state = manualState ? manualState: "";
    const zipCode = formData.zip?.toUpperCase();
    
    const brandName: any = document.getElementById('brandName') as HTMLInputElement
    let finalAddress:any = formData.address;
    let getsuggestionFlag = sessionStorage.getItem("suggestion");
    const suggestAddr = sessionStorage.getItem('suggestedAddress') as string;
    if(suggestAddr && getsuggestionFlag=="true"){
        city = JSON.parse(suggestAddr).city;
        state = JSON.parse(suggestAddr).stateCode;
        finalAddress = JSON.parse(suggestAddr).addr1;
    }
    const manualAddress = sessionStorage.getItem("manualAddress");
    
    const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const url = window.location.origin; 
    let vendorId;
    let vendorName;

    const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
    if(brandData){
        vendorId = brandData.vendorId;
        vendorName = brandData.vendorName;
    }
      
      const getCheckedValues = this.getCheckedCheckboxes();
      let preferredCommunication =  getCheckedValues ? `, Preferred method of communication: ${getCheckedValues}`:""; 
      let getCheckedPreferedComunnication = this.getCheckedPreferedComunnication();

      if(!getCheckedPreferedComunnication){
          getCheckedPreferedComunnication = 'Email,Phone';
      }

      if(brandData?.enable_line_break_bpro){
        
        if(preferredCommunication){
          preferredCommunication = `<br>${preferredCommunication}`
        }

        if(serviceLead){
          serviceLead = `<br>${serviceLead}`
        }

        if(serviceType){
          serviceType = `<br>${serviceType}`
        }

        if(servicePayload){
          servicePayload = `<br>${servicePayload}`
        }

        if(serviceServiceType){
          serviceServiceType = `<br>${serviceServiceType}`
        }

        if(serviceFloor){
          serviceFloor = `<br>${serviceFloor}`
        }

    }
    
    const lastName = formData.lastName ?? '';
    const emailOptOut = brandData.enable_emailOptOut;
    const defaultLeadSourceId = brandData.default_leadSourceID || 0;


    let requestBody: BookingPayloadModel = {
      isLocalized: true,
      firstName: formData.name.split(' ')[0] ? formData.name.split(' ')[0] : '',
      lastName: formData.name?.split(' ')[1] ? formData.name?.split(' ')[1] : ' ',
      zipCode: zipCode.trim(),
      postalCode: zipCode.trim(),
      email: formData.email,
      hasSameDayScheduling: fullData?.options['hasSameDayScheduling'] ? fullData?.options['hasSameDayScheduling'] : true,
      state: state,
      city: city,
      phone: formData.phone,
      address: finalAddress,
      address2: formData?.address2 ?? '',
      comments: '',
      preferredCommunicationType: getCheckedPreferedComunnication,
      note: [
        formData?.notes,
        serviceLead,
        serviceType,
        servicePayload,
        serviceServiceType,
        serviceFloor,
        preferredCommunication
      ]
        .filter(value => value !== undefined && value !== null && value !== '') // Filter empty values
        .join(' ') || '',
      signUpForUpdates: false,
      conceptId: Number(conceptId),
      currentPage: url,
      leadSource: "WEB",
      leadOrigin: "WEB",
      phoneType: '',
      isLeadOnly: formData.isCalanderDIsable,
      scheduleID: formData.isCalanderDIsable ? '' : (schaduleId ? schaduleId : ''),
      campaignName: campaignName,
      callType: "WEB",
      conceptCalledId: Number(conceptId),
      serviceType: formData?.service,
      // IsEstimate: false,
      fee: inspectionFeeObj ? Number(inspectionFeeObj?.fee) : 0,
      emailOptOut: emailOptOut,
      vendorId: Number(vendorId),
      isTest: IsTest,
      conceptCode: conceptCode,
      vendorName: vendorName,

      "fileUrls": [{}],
      customerType: selectedServiceType ?? 'Residential',
      leadSourceId: defaultLeadSourceId,
      webLocationId: 0
    };

    if(this.shgSolutionType){
      requestBody.serviceType = this.shgSolutionType;
    }

    if(selectedFloor!=="")
      requestBody.floor= selectedFloor;

    if(lastName){
      requestBody.lastName = lastName;
      requestBody.firstName = formData.name ?? '';
    }

    if(schaduleId){
      requestBody.timeBlock = {
        "conceptId": Number(conceptId),
        "googleCalendarEventId": "",
        "googleCalendarEmail": "",
        "timeSlotTypeId": 0,
        "serviceSkuId": 0,
        "jobPromisedDate": date ? `${date}` : '',
        "isAvailable": schaduleId ? true : false,
        "isFranchiseInPOS": true,
        "displayText": ""
      }
    }

    if (localStorage.getItem("franchiseWebLocationId")) {
      requestBody.webLocationId = Number(localStorage.getItem("franchiseWebLocationId"));
      requestBody.franchiseId = Number(sessionStorage.getItem('franchiseId'));
    }
    
    const genericLeadSourceId = document.getElementById('how-about-us') as HTMLSelectElement;
    const selectedOption = genericLeadSourceId?.options[genericLeadSourceId.selectedIndex];
    const selectedLeadSourceId = selectedOption?.getAttribute('data-sync-generic-lead-source-id');
    
    if(brandData?.send_leadsource_id && selectedLeadSourceId ){
      requestBody.leadSourceId = Number(selectedLeadSourceId);
    }

    if(conceptCode?.toLocaleLowerCase() === 'dvw'){

      const howAboutUsSelector = document.getElementById('how-about-us') as HTMLSelectElement;
      const selectedOption = howAboutUsSelector?.options[howAboutUsSelector.selectedIndex];
      const selectedDataReferalId = selectedOption?.getAttribute('data-referral-type-id');
      const selectedValue = selectedOption.value;

      if(selectedDataReferalId){
        requestBody.referralTypeId = selectedDataReferalId;
      }
      if(selectedValue){
        requestBody.referralType = selectedValue;
      }

      if(!requestBody.preferredCommunicationType){
          requestBody.preferredCommunicationType = 'Email,Phone'
      }

    }

    if(window.calendarTypeId==2 && serviceSkuId){
        requestBody.serviceSkuId = Number(serviceSkuId);
    }

    if(requestBody.note && this.sentCommentAndNote){
      requestBody.comments = requestBody.note;
    }

      const modalVisible = false;
      const form = document.querySelectorAll('#form-section .contact-us-section')[0];
      const parentWithFormId = form.closest('[id*="form"]');
      const domain = DomainIdentifier();
      const localisation = LocalizationIdentifier();
      // Default value to pass if checkbox is not found
      let checkboxId = '';
      const countryZip = !REGEX.sendCaPostalCode.test(zipCode?.trim()) ? 'us' : 'ca';
      if (((domain === "us" && !localisation) || (domain === "us" && localisation=="us")) && countryZip === 'us') {
          checkboxId = 'lead-flow-service-reminder-us';
      } else {
          checkboxId = 'lead-flow-service-reminder-ca';
      }
        if (parentWithFormId) {

          const noteWithRequestBody: any =  disclaimerHandleCheck(modalVisible, parentWithFormId as HTMLElement, requestBody, zipCode, checkboxId);
          if (noteWithRequestBody?.comments) {
            requestBody.comments = noteWithRequestBody.comments;
        }
        if (noteWithRequestBody?.note) {
          requestBody.note = noteWithRequestBody.note;
      }
        requestBody.optIn = noteWithRequestBody.optIn;
        requestBody.emailOptOut = noteWithRequestBody.emailOptOut;
        }

    handleMissingData(city,state,zipCode).then((out:any)=>{
        requestBody.city = out?.city;
        requestBody.state = out?.state;
        this.callBookingAPI(requestBody);
    });
    
  }

  async callBookingAPI(requestBody:any){
    let sessionID: string | undefined;
    let correlationID: string | undefined;
    const sessionAndCorelation = getSessionAndCorrelationID();
    sessionID = sessionAndCorelation.sessionID;
    correlationID = sessionAndCorelation.correlationID;
    if (correlationID)
      requestBody.correlationId = correlationID;

    const bookingPayload = {...requestBody};
    const formData = this.getValues(); 
    bookingPayload.serviceCat = formData?.catService ?? '';
    sessionStorage.setItem('booking', JSON.stringify(bookingPayload));
    let url = '';
    if (correlationID && sessionID) {
      url = `${apiConfig.BOOKING_API_URL}&correlationId=${correlationID}&sessionId=${sessionID}`
    }
    else {
      url = apiConfig.BOOKING_API_URL;
    }
    
    const submitType = requestBody.isLeadOnly ? 'submit_and_continue' : 'full_form_submit';
    let formDesc: any = '';

    const referralMethod = document.querySelector('#how-about-us') as HTMLInputElement;
    formDesc = referralMethod ? referralMethod.value.replace(/ /g, "_") : '';

    if (requestBody.conceptCode == 'MLY') {
      const mlyTypeOfCleaningRadioElement = document.querySelector('input[name="mly-cleaning-radio"]:checked') as HTMLInputElement;
      const selectedTypeOfCleaning = mlyTypeOfCleaningRadioElement?.value;
      const frequencyCleaningRadioElement = document.querySelector('input[name="frequency-cleaning-radio"]:checked') as HTMLInputElement;
      const frequencyRadios = frequencyCleaningRadioElement?.value;
      formDesc = selectedTypeOfCleaning + '_' + frequencyRadios;
    }

    const serviceType = document.querySelector('input[name="service-type"]:checked');
    if (serviceType) {
      formDesc = serviceType.nextElementSibling?.textContent;
    }

  await bookingUsPostCallWrapper(url, requestBody)
      .then((response: any) => {
        // window.removeEventListener("beforeunload", beforeUnloadHandler);
        requestEstimate(submitType, 'long-form', 'success', formDesc);
        stopLoader();
        storeBookingApiPayload(requestBody,response);
        window.history.replaceState({}, '', '/confirmation');
        window.location.href = '/confirmation'

        // Removed campaign name session storage
        sessionStorage.removeItem('campaignName');
      })
      .catch((err) => {
        requestEstimate(submitType, 'long-form', 'fail', formDesc);
        stopLoader();
        this.throwBookingError();
      });
  }
  throwBookingError(){
    const btnClick = document.getElementById('error-modal-id')
    btnClick?.click();
    const errorMessage = document.getElementById('modal-appoinment-slot');
    const errorModal = document.getElementById('error-modal');
    errorModal?.classList.remove('hidden');
    errorMessage?.classList.remove('hidden');
  }
  handleDisableApp() {
    const getContainer: any = document.querySelector('.calendar-container')
    const checkBoxContainer = removeAllEventListeners('#link-checkbox');

    if (checkBoxContainer) {
      checkBoxContainer.addEventListener('click', this.handleDisableApp.bind(this));
    }
    if (getContainer && checkBoxContainer.checked) {
      getContainer.classList.add('disable-calendar');
      sessionStorage.setItem('onlineSchedule', "false");
    }
    else if (getContainer && !(checkBoxContainer.checked)) {
      getContainer.classList.remove('disable-calendar');
      sessionStorage.setItem('onlineSchedule', "true");
    }

    const callbackwrapper = document.querySelector('.callback-wrapper')
    callbackwrapper?.classList.toggle('callback-requested')
    this.enableDisableCalendarBtn();
    this.hideErrorMessage();
  }
  geServiceTypetValue() {
    const checkedRadio = document.querySelector('input[name="service-type"]:checked') as HTMLInputElement;
    // Check if a radio input is checked
    if (checkedRadio) {
        // Get the label associated with the checked radio input
        const labelForCheckedRadio = document.querySelector(`label[for="${checkedRadio.id}"]`) as HTMLLabelElement;

        // Get the text content of the label
        const labelText = labelForCheckedRadio?.textContent;
        return labelText;
    }

    //     // Use labelText as needed
    //     return labelText == 'Commercial' ? 1 : 0
    // } else {
    //     return 0
    // }
  }


  enableButton() {
    const container = document.querySelector('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement
    if (container) {
      container.disabled = false
      container.style.opacity = '1'
    }

  }

  disableButton() {
    const container = document.querySelector('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement
    if (container) {
      container.disabled = true
      container.style.opacity = '0.3'
    }
    
  }

  enableDisableCalendarBtn(){
    const checkActiveSlot = document.getElementsByClassName("calendar-col active");
    const linkCheckbox: any = document.getElementById('link-checkbox');
    if (checkActiveSlot?.length != 0) {
      this.enableButton();
    } else if (linkCheckbox?.checked) {
      this.onlineScheduling = false;
      sessionStorage.setItem('onlineSchedule', this.onlineScheduling.toString());
      this.enableButton();
    }
    
  }
  async calendarApiRequest(request:any){
    startLoader();
    try{
      const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
      const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
      const maxWeeks = brandData?.calendarMaxWeeksCap ? brandData?.calendarMaxWeeksCap: 8;
      const maxDays = maxWeeks * 7;
      request.url = request.url + `&NumberOfDays=${maxDays}`; // Added NumberOfDays to get the data from brand json 
      const response = await getCachedAPIResponse(request.url, apiCaching.TechAvailability);
      const calendarContainer:any = document.getElementsByClassName("calendar-container");
      const hasSameDay = fullData?.options['hasSameDayScheduling'] ? fullData?.options['hasSameDayScheduling'] : true
      allAppData  = response?.resultCollection
      let currentIndex = allAppData.findIndex((dt:any)=>dt.isAvailable);
      const submitBtn = document.querySelector('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement;
      if(currentIndex === -1){
          const linkCheckbox: any = document.getElementById('link-checkbox');
          if (calendarContainer && calendarContainer.length > 0) {
          calendarContainer[0].style.display = "none"; 
          }
          const calendarHeading = document.getElementsByClassName("appointment-heading");
          const systemErrorMsg = document.getElementById("modal-appoinment-slot")?.innerHTML;
          if (calendarHeading && calendarHeading.length > 0) {
          calendarHeading[0].innerHTML = systemErrorMsg ? systemErrorMsg : "";
          }
          this.enableButton();
          if(linkCheckbox){
              linkCheckbox.checked = true;
            }else{
           
              if(submitBtn){
                submitBtn.classList.add("hidden");
              }
            }
          return;
      }
      if(submitBtn){
        submitBtn.classList.remove("hidden");
      }
      calendarContainer[0].style.display = "flex";
      // currentIndex = Math.floor(currentIndex/3.5);
      const filterData  = this.removeDuplicateDates(allAppData, hasSameDay);
      date = allAppData?.find((x: any) => x.isAvailable).startDate;
      currentIndex = filterData. 
      findIndex((dt:any)=>moment(dt.startDate).format('ddd DD MMM, YYYY') == moment(date).format('ddd DD MMM, YYYY'));
      // const dataObject = allAppData?.filter((x: any) => moment(x.startDate).format('ddd DD MMM, YYYY') == moment(date).format('ddd DD MMM, YYYY'));
      const cards = this.setCard(filterData);
      
      this.buttonName = document.querySelectorAll('.date-details-item .dateapp');
      time = '';//allAppData?.find((x: any) => x.isAvailable).displayText;
      const scId : any = allAppData?.find((x: any) => x.isAvailable);

      this.setTimeUl(scId);

      schaduleId = scId?.scheduleID;

      document.querySelector('.appointment-section-text')!.innerHTML = `${moment(date).format('ddd MMM DD, YYYY')}, <span class="select-time"> Please Select a Time </span>`

      let containner:any = (document.querySelector('.date-slider') || document.querySelector('.date-details-list'));
      containner.innerHTML ='';
      if (containner)
          containner.innerHTML = cards.join('')
      setTimeout(() => {
        this.addListner()
        this.setTinySlider.call(this,currentIndex)
      }, 500)

      setTimeout(() => stopLoader(), 1000);
    }
    catch{
      stopLoader();
      const linkCheckbox: any = document.getElementById('link-checkbox');
      const calendarContainer:any = document.getElementsByClassName("calendar-container");
      //calendarContainer[0]?.classList.add("hidden");
      if (calendarContainer && calendarContainer.length > 0) {
      calendarContainer[0].style.display = "none"; 
      }
      const calendarHeading = document.getElementsByClassName("appointment-heading");
      const systemErrorMsg = document.getElementById("modal-appoinment-slot")?.innerHTML;
      if (calendarHeading && calendarHeading.length > 0) {
      calendarHeading[0].innerHTML = systemErrorMsg ? systemErrorMsg : "";
      }
      this.enableButton();
      if(linkCheckbox){
        linkCheckbox.checked = true;
      }else{
        const submitBtn = document.querySelector('.appointment-details-section .btn-container .primary-btn') as HTMLButtonElement;
        if(submitBtn){
          submitBtn.classList.add("hidden");
        }
      }
      return;
    }
  }
  removeAllEventListeners(selector: any) {
    const element = document.querySelector(selector);
    if (element) {
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
        return newElement;
    }
    return null;
}
}



// Initialize the form handling

