import { getBrandDetailsFromBrandJson } from "./share";

export function LocalizationIdentifier(): any {
    const dbaName = localStorage.getItem('dbaName');
    const countryCode = sessionStorage.getItem('countryCode');
    if (dbaName && countryCode) {
        return countryCode;
    }
    return null;
}
export async function getDisplayPhoneNumber(): Promise<string | null> {
    const localization = document.getElementById("weblocationId") as HTMLInputElement | null;
    let isLocalized = false;
    if (localization && Number(localization.value) > 0) {
        isLocalized = true;
    }
    let phoneNumber: string | null = localStorage.getItem('phoneNumber') ?? localStorage.getItem('localPhoneNumber');

    //If there is no phone number and if the user is not localized then fetch the phone number from the brand json
    if (!phoneNumber && !isLocalized) {
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        if (conceptCode) {
            try {
                const brandData = await getBrandDetailsFromBrandJson(conceptCode);
                if (brandData) {
                    phoneNumber = brandData.phone;
                }
            } catch (error) {
                console.error('Error in fetching brand details for getDisplayPhoneNumber:', error);
            }
        }
    }
    return phoneNumber;
}
