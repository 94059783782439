import { getCachedAPIResponse } from '../apiCaching/apiWrapper';
import { apiCaching } from '../config/cachingConfig';
import { attachNewTabListeners, getServiceAccordionPath } from '../header/brandLocalAccordion';

export class ServiceList {
    maxCharacterCount: number = 375;
    authorableItems: NodeListOf<HTMLLIElement> | undefined;
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    private async initialize() {
        this.showReadMoreButton();
        const dbaName: string | null = localStorage.getItem('dbaName');
        const webId: string | null = localStorage.getItem('weblocationId');
        if (webId && dbaName && !window.location.href.includes(dbaName)) {
            this.localAccordion();
        }
        this.appendAuthorableItems();
        this.updateAuthorableItems();
        attachNewTabListeners();

    }

    appendAuthorableItems() {
        // Define the tabs and corresponding authorable classes in an array
        const tabs = [
            { tabId: 'accor-residential', authorableClass: '.authorable-residential' },
            { tabId: 'accor-commercial', authorableClass: '.authorable-commercial' },
            { tabId: 'accor-auto', authorableClass: '.authorable-auto' },
            { tabId: 'accor-emergency', authorableClass: '.authorable-emergency' }
        ];

        // Loop through each tab and append the authorable item if the tab exists
        tabs.forEach(tab => {
            const tabElement = document.querySelector(`#${tab.tabId}`);
            const authorableItem = document.querySelector(tab.authorableClass);

            // If the tab and the corresponding authorable item exist, append the item
            if (tabElement && authorableItem) {
                const accordionList = document.querySelector('.accordion-icon-list#accordion-service');
                accordionList?.appendChild(authorableItem);
            }
        });
    }


    async localAccordion() {
        const pageSettingsElement = document.getElementById('page-settings');
        // Check if the element exists and has a value
        if (pageSettingsElement && this.hasServiceAccordion(pageSettingsElement.outerHTML)) {
            const skipGroupValue = this.hasSkipgroup(pageSettingsElement.outerHTML);
            const urlSiteConfig = getServiceAccordionPath(skipGroupValue);
            try {
                if (urlSiteConfig) {
                    let result = await getCachedAPIResponse(urlSiteConfig, apiCaching.serviceAccordion, false);
                    if (result) {
                        // Append li's inside `ser-accordion-service-modifier` to `ul.req-call-tabs`
                        this.appendListItems("#ser-accordion-service-modifier li", "ul.req-call-tabs");
                        this.appendListItems("#ser-accordion li", "ul.accordion-icon-list#accordion-service");
                        attachNewTabListeners();
                    }
                }
            }
            catch {
                console.error("Error in fetching accordion infor");
            }
        }


    }

    private hasSkipgroup(elementHtml: string): string {
        const rawString = elementHtml.split("value=")[1];
        const str = rawString?.split("skipgrouping")[1]?.split(":")[1]?.split(",")[0]?.trim();
        //found and value is 'true', return 'skipgroup'
        if (str && str === 'true') {
            return 'skipgroup';
        }     
        //doesn't exist or value is not 'true', return 'showgroup'
        return 'showgroup';
    }
    private hasServiceAccordion(elementHtml: string): boolean {
        const rawString = elementHtml.split("value=")[1];
        const str = rawString?.split("containsserviceaccordion")[1]?.split(":")[1]?.split(",")[0]?.trim();
        const pageScope = rawString?.split('"pagescope":"')[1]?.split('"')[0]?.trim();
        return str === 'true' && pageScope === 'national';
    }

    private appendListItems(sourceSelector: string, targetSelector: string): void {
        const sourceItems = document.querySelectorAll(sourceSelector);
        const targetElement = document.querySelector(targetSelector);

        if (sourceItems && targetElement) {
            sourceItems.forEach(item => {
                targetElement.appendChild(item.cloneNode(true)); // Clone and append items
            });
        }
    }


    private showReadMoreButton() {
        const pTag = document.querySelector('.read-more-wrap .residential-text') as HTMLElement;
        const showMoreButton = document.getElementById("readMoreButton") as HTMLElement;

        if (pTag && showMoreButton) {
            const numberOfCharacters = pTag.innerText.length;
            if (numberOfCharacters < this.maxCharacterCount) {
                showMoreButton.classList.add("hidden");
            }
        }
    }
    private updateAuthorableItems() {
        const newItems: string[] = [];
        this.authorableItems = document.querySelectorAll('ul#accordion-service > li');
        let ulElement = document.getElementById('accordion-service');

        const checkedRadio = document.querySelector('input[name="request-call"]:checked') as HTMLInputElement;

        // Determine which section to show based on the checked radio button
        let sectionToShow = '';
        if (checkedRadio) {
            const value = checkedRadio.value;
            if (value === '0') {
                sectionToShow = 'residential'; // "0" for residential
            } else if (value === '1') {
                sectionToShow = 'commercial'; // "1" for commercial
            } else if (value === '2') {
                sectionToShow = 'auto'; // "2" for auto
            } else if (value === '3') {
                sectionToShow = 'emergency'; // "3" for emergency
            }
        }

        // Count the number of existing service-acc-tab elements for residential and commercial
        const existingResidentialCount = document.querySelectorAll('.service-acc-tab--residential').length;
        const existingCommercialCount = document.querySelectorAll('.service-acc-tab--commercial').length;
        const existingAutoCount = document.querySelectorAll('.service-acc-tab--auto').length;
        const existingEmergencyCount = document.querySelectorAll('.service-acc-tab--emergency').length;

        let residentialCounter = existingResidentialCount + 1; // Start from the next available number for residential
        let commercialCounter = existingCommercialCount + 1; // Start from the next available number for commercial
        let autoCounter = existingAutoCount + 1;
        let emergencyCounter = existingEmergencyCount + 1;

        this.authorableItems?.forEach((item, index) => {
            // Only process items with the class that starts with 'authorable-'
            if (Array.from(item.classList).some(className => className.startsWith('authorable-'))) {

                item.style.display = 'none';

                const categoryTitle = item.querySelector('.card-title')?.textContent;
               
                let itemClass = 'residential'; // Default

                if (item.classList.contains('authorable-commercial')) {
                    itemClass = 'commercial';
                } else if (item.classList.contains('authorable-auto')) {
                    itemClass = 'auto';
                } else if (item.classList.contains('authorable-emergency')) {
                    itemClass = 'emergency';
                }
                let styleDisplay;
                if (itemClass === sectionToShow) {
                    styleDisplay = 'flex'; // Show the matching section
                }
                else {
                    styleDisplay = 'none';
                }
                
                let counter: number;

                if (itemClass === 'residential') {
                    counter = residentialCounter;
                } else if (itemClass === 'commercial') {
                    counter = commercialCounter;
                } else if (itemClass === 'auto') {
                    counter = autoCounter;
                } else if (itemClass === 'emergency') {
                    counter = emergencyCounter;
                } else {
                    counter = 0; // Default value
                }

                const uniqueId = `service-${itemClass}-${counter}`;

                let innerHTML = '';

                // Scenario 1: Standard button structure for residential/commercial when subcategories are present
                if (item.querySelector('button')) {
                    const innerList = item.querySelector('.accordion-list');
                    const isEmpty = !innerList || innerList.children.length === 0; // Check if <ul> is empty

                    const buttonClass = isEmpty ? 'list-accordion-btn disabled' : 'list-accordion-btn';
                    const buttonDisabled = isEmpty ? 'disabled' : '';

                    innerHTML += `
                        <li class="card-list service-acc-tab--${itemClass}" style="display: ${styleDisplay};">
                            <button type="button" class="${buttonClass}" data-accordion-target="#${uniqueId}" aria-expanded="false" aria-controls="${uniqueId}" ${buttonDisabled}>
                                <div class="icon-block">
                                    <div class="icon-wrap">
                                        <svg class="icon">
                                            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                                        </svg>
                                    </div>
                                    <div class="icon-block-text">
                                        <h3 class="card-title">${categoryTitle}</h3>
                                    </div>
                                </div>
                                ${!isEmpty ? `
                                    <div class="accordion-close accordion-arrow">
                                        <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
                                    </div>
                                    <div class="accordion-open accordion-arrow">
                                        <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
                                    </div>
                                ` : ''}
                            </button>
                            <div class="card-info hidden" id="${uniqueId}">
                                <ul class="accordion-list">
                    `;

                    if (innerList) {
                        innerList.querySelectorAll('li').forEach(subItem => {
                            const subCategoryTitle = subItem.textContent;
                            const anchorElement = subItem.querySelector('a');
                            const subCategoryLink = anchorElement ? anchorElement.getAttribute('href') : '#';
                            const target = anchorElement ? anchorElement.getAttribute('target') : '_self';
                            innerHTML += `
                                <li><a href="${subCategoryLink}" target="${target}">${subCategoryTitle}</a></li>
                            `;
                        });
                    }

                    innerHTML += `
                                </ul>
                            </div>
                        </li>
                    `;
                }

                // Scenario 2: anchor tag with redirection
                else if (item.querySelector('a')) {
                    const anchorElement = item.querySelector('a');
                    const href = anchorElement ? anchorElement.getAttribute('href') : '#'; // Fallback if no link
                    const target = anchorElement ? anchorElement.getAttribute('target') : '_self';
                    innerHTML += `
                        <li class="card-list service-acc-tab--${itemClass}" style="display: ${styleDisplay};">
                            <a id="${uniqueId}" href="${href}" target="${target}" class="list-accordion-btn">
                                <div class="icon-block">
                                    <div class="icon-wrap">
                                        <svg class="icon">
                                            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                                        </svg>
                                    </div>
                                    <div class="icon-block-text">
                                        <h3 class="card-title">${categoryTitle}</h3>
                                    </div>
                                </div>
                                <svg class="accordion-arrow">
                                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#arrow-small-right-black"></use>
                                </svg>
                            </a>
                        </li>
                    `;
                }

                // Scenario 3: Button disabled when subcategories are not present
                else {
                    innerHTML += `
                        <li class="card-list service-acc-tab--${itemClass}" style="display: ${styleDisplay};">
                            <button class="list-accordion-btn disabled" disabled aria-disabled="true">
                                <div class="icon-block">
                                    <div class="icon-wrap">
                                        <svg class="icon">
                                            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                                        </svg>
                                    </div>
                                    <div class="icon-block-text">
                                        <h3 class="card-title">${categoryTitle}</h3>
                                    </div>
                                </div>
                                <div class="accordion-close accordion-arrow hidden">
                                    <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
                                </div>
                                <div class="accordion-open accordion-arrow hidden">
                                    <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
                                </div>
                            </button>
                            <div class="card-info hidden" id="${uniqueId}">
                                <ul class="accordion-list">
                                </ul>
                            </div>
                        </li>
                    `;
                }

                newItems.push(innerHTML);
                // Increment the respective counter based on the item class
                if (itemClass === 'residential') {
                    residentialCounter++;
                } else if (itemClass === 'commercial') {
                    commercialCounter++;
                } else if (itemClass === 'auto') {
                    autoCounter++;
                } else if (itemClass === 'emergency') {
                    emergencyCounter++;
                }

                item.remove();
            }
        });

        newItems.forEach(item => {
            ulElement?.insertAdjacentHTML('beforeend', item);
            // Get all <li> elements inside the ulElement (including newly inserted ones)
            const allListItems = ulElement?.querySelectorAll('li');
            // Iterate through each <li> element and check if it has the 'service-acc-tab-residential' class
            allListItems?.forEach(item => {
                if (item.classList.contains('service-acc-tab--residential')) {
                    item.style.display = 'flex';  // Set display to 'flex'
                }
            });
        });

    }

}

// Instantiate the ServiceList class
const serviceList = new ServiceList();
