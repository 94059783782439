export function blogPageLocationURL(dbaName: string):string {
    const brandDetails = localStorage.getItem("brandDetails");
    if(brandDetails!=="undefined" && brandDetails !==null){

        const data = JSON.parse(brandDetails);
        const blogUseFlag = data?.weblocationUrlPath_blog;
        const webLocationPath = data.weblocation_path_group;
        const isStateName = data.include_statename && data.include_statename_blog;
        const weblocationInfo = getWebLocationInfo();
        
        if(blogUseFlag && webLocationPath && !isStateName){
            return `${data.weblocation_path_group}/${dbaName}`;
        }else if(blogUseFlag && webLocationPath && isStateName && weblocationInfo?.stateName){
            return `${data.weblocation_path_group}/${weblocationInfo.stateName}/${dbaName}`;
        }else if(blogUseFlag && !webLocationPath && isStateName && weblocationInfo?.stateName){
            return `${weblocationInfo.stateName}/${dbaName}`;
        }
        else{
            return `${dbaName}`;
        }
    }
    console.error("Error while setting a localized blog URL")
    return "error";
}

function getWebLocationInfo(): any {
    const weblocationInfo = window.crownpeakWeblocationsByIdDetail;
    if (!weblocationInfo) {
        console.error("Error while getting weblocationInfo");
        return null;
    }
    return weblocationInfo;
}