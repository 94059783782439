
import { Concert } from "../../global";
import { cachedAPIWrapper } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { getBrandDetailsFromBrandJson } from "../util/share";
export class OurTeam {
  public staffTeamContainer: HTMLElement | null;
  public employeeTeamContainer: HTMLElement | null;
  public currentPage: number = 1;
  teamTitle: HTMLElement | null;;
  teamTitleMob: HTMLElement | null;
  teamDescription: HTMLElement | null;
  teamPhoto: HTMLElement | null;
  constructor() {
    this.staffTeamContainer = null;
    this.employeeTeamContainer = null;
    this.teamTitleMob = null;
    this.teamDescription = null;
    this.teamTitle = null;
    this.teamPhoto = null;
    this.init();
  }

  init() {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    getBrandDetailsFromBrandJson(conceptCode)
      .then((brandDetail) => {
        const enable_local_meet_my_team = brandDetail?.enable_local_meet_my_team_fte as boolean;
        if (enable_local_meet_my_team) {const concert = new Concert();}
        else {
          this.staffTeamContainer = document.querySelector('.our-team-section .container .team-wrapper .heavy-list');
          this.employeeTeamContainer = document.querySelector('.our-team-section .container .team-wrapper .team-list');
          const teamDataLinks = document.querySelectorAll('.heavy-list .team-list-item');

          this.teamTitle = document.querySelector('.section-50-50.glb-primary-hero .image-content-warpper.media-main .text-wrapper .primary-heading');
          this.teamTitleMob = document.querySelector('.section-50-50.glb-primary-hero .right-image-content.media-main .media-area .primary-heading');
          this.teamDescription = document.querySelector('.section-50-50.glb-primary-hero .image-content-warpper .text-wrapper .body-text');
          this.teamPhoto = document.querySelector('.section-50-50 .image-content-warpper .media-wrapper img');
          if (!this.teamTitle || !this.teamTitleMob || !this.teamDescription || !this.teamPhoto) {
            this.injectHTMLElement();
          }
          if (this.staffTeamContainer || this.employeeTeamContainer || teamDataLinks.length > 0) {
            if (this.teamTitle) this.teamTitle.innerHTML = '';
            if (this.teamTitleMob) this.teamTitleMob.innerHTML = '';
            if (this.teamDescription) this.teamDescription.innerHTML = '';
            if (this.teamPhoto) {
              this.teamPhoto?.setAttribute("src", '');
              this.teamPhoto?.setAttribute("alt", '');
            }
            const franchiseWebLocationId = localStorage.getItem("franchiseWebLocationId");
            const localWeblocId = (document.getElementById('weblocationId') as HTMLInputElement)?.value;
            if (localWeblocId || franchiseWebLocationId) {
              this.getOurTeamData();
            } else {
              const concert = new Concert();
              teamDataLinks.forEach((link, index) => {
                link.addEventListener('click', this.teamDataCp.bind(this, link, index))
              });
            }
          }
        }
      })
  }

  injectHTMLElement() {
    const ownerDOM = `<section class="section-50-50 glb-primary-hero">
      <div class="container">
        <div class="image-content-warpper right-image-content media-main">
          <div class="text-wrapper">
            <h2 class="primary-heading">Robin - Owner</h2>
            <div class="body-text-wraper">
              <div class="body-text wysiwyg">
                Robin - Owner
              </div>
            </div>
            <div class="cta-wrapper"></div>
          </div>
          <div class="media-area">
            <div class="primary-heading">
              Robin - Owner
            </div>
            <div class="media-wrapper">
              <div class="media-container"><img alt="Robin - Owner" src="https://www.neighborly.com/us/en-us/_assets/images/homeanimation/nei-primary-heroimage-0921_home-desktop-3040x1266.svg"></div>
            </div>
          </div>
        </div>
      </div>
    </section>`;

    const ourteam = document.querySelector(".our-team-section") as HTMLElement;
    if (ourteam) ourteam.insertAdjacentHTML('beforebegin', ownerDOM);
    this.setDOMValues();
  }

  removeHTMLElement() {
    const ownerDOM = document.querySelector(".section-50-50.glb-primary-hero");
    if (ownerDOM)
      ownerDOM.remove();
  }


  setDOMValues() {
    this.teamTitle = document.querySelector(".section-50-50.glb-primary-hero .image-content-warpper.media-main .text-wrapper .primary-heading") as HTMLElement;
    this.teamTitleMob = document.querySelector(".section-50-50.glb-primary-hero .right-image-content.media-main .media-area .primary-heading") as HTMLElement;
    this.teamDescription = document.querySelector(".section-50-50.glb-primary-hero .image-content-warpper .text-wrapper .body-text") as HTMLElement;
    this.teamPhoto = document.querySelector(".section-50-50 .image-content-warpper .media-wrapper img") as HTMLElement;
  }

  teamDataCp(e: any, link: any, i: any) {
    const modal: any = document.getElementById('teams-modal');
    const modalContent = modal.querySelector('.modal-member-wrap');
    if (modalContent) {
      modalContent.querySelector('.img-wrap img')!.src = e.querySelector('.member-image img')?.src
      modalContent.querySelector('.data-wrap .member-name')!.innerHTML = e.querySelector('.member-data .member-name')?.innerHTML
      modalContent.querySelector('.data-wrap .member-detail')!.innerHTML = e.querySelector('.member-data .member-detail')?.innerHTML
      modalContent.querySelector('.data-wrap .member-bio')!.innerHTML = e.querySelector('.member-bio')?.innerHTML;
    }

  }

  public getOurTeamData() {
    const ourTeamData = sessionStorage.getItem("ourTeamObject");
    if (ourTeamData != null) {
      let ourTeamObject = JSON.parse(ourTeamData);
      this.rendarOurTeams(ourTeamObject);
      return;
    }
    const localWebID = localStorage.getItem("franchiseWebLocationId");
    const webID = document.getElementById('weblocationId') as HTMLInputElement;
    const webLocationId = webID?.value ? webID?.value : localWebID;
    const apiUrl = apiConfig.ourTeam + webLocationId;

    cachedAPIWrapper(apiUrl)
      .then((result: any) => {
        sessionStorage.setItem('ourTeamObject', JSON.stringify(result));
        this.rendarOurTeams(result);
      })
      .catch((err) => {
        console.log('Error in rendering our teams section.');
        this.hideOurTeamSection();
      });
  }
  rendarOurTeams(result: any) {
    if (result.teamInfo != null || result.teamSize > 0) {
      const photoType = result.photoType.toString().toLowerCase();
      let staffTeamDetails: string[] = [];
      let employeeTeamDetails: string[] = [];
      let cobminedTeams: string[] = [];
      let heavyClass = document.querySelector(".our-team-section .container .team-wrapper ul") as HTMLInputElement;
      let checkForHeavyClass = heavyClass.classList.contains("heavy-list");
      let count = 0;
      if (result.teamInfo && result.teamInfo.photoDescription && result.teamInfo.squarePhotoUrl) {
        const { name, photoDescription, squarePhotoUrl } = result.teamInfo;
        const elements = [this.teamTitle, this.teamTitleMob, this.teamDescription, this.teamPhoto];

        if (elements.every(el => el)) {
          this.teamTitle!.innerHTML = name;
          this.teamTitleMob!.innerHTML = name;
          this.teamDescription!.innerHTML = photoDescription;
          this.teamPhoto!.setAttribute("src", squarePhotoUrl);
          this.teamPhoto!.setAttribute("alt", name);
        }
      }

      else {
        let teamInfo = document.querySelector(".section-50-50.glb-primary-hero");
        teamInfo?.remove();
      }

      result.employeePhotoInfo = result.employeePhotoInfo?.filter((item: any) => item.displayName);
      result.staffPhotoInfo = result.staffPhotoInfo?.filter((item: any) => item.name);
      cobminedTeams = [...result.employeePhotoInfo, ...result.staffPhotoInfo];
      if (cobminedTeams.length > 0) {
        result.employeePhotoInfo.forEach((value: any) => {
          const profileImage = photoType == 'circle' ? value.franchiseProfileEmployeeBioDetail.circularPhotoUrl : value.franchiseProfileEmployeeBioDetail.squarePhotoUrl;
          const name = value.displayName || '';
          const title = value.franchiseProfileEmployeeBioDetail.title || '';
          const photoDescription = checkForHeavyClass ? "" : (value.description || '');

          const employeeTeamHTML = `
                      <li class="team-list-item">
                        <div class="team-data">
                        ${profileImage != null ? `<div class="member-image">
                            <img src="${profileImage}" alt="team member image" width="226" height="214">
                          </div>` : ''}
                          <div class="member-data">
                            <h3 class="member-name">${name}</h3>
                            <p class="member-detail">${title}</p>
                            <button type="button" class="primary-outline-btn bio-btn" id="toggle-member-bio-${count}">
                              Read Bio
                              <svg>
                                <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-primary" />
                              </svg>
                            </button>
                            <div class="member-bio" id="member-bio-${count}">${photoDescription}</div>
                          </div>
                        </div>
                      </li>
                    `;
          employeeTeamDetails.push(employeeTeamHTML);
          count++;
        });

        const itemsPerPage = checkForHeavyClass ? 8 : result.staffPhotoInfo.length;
        const startIndex = (this.currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        let staffPhotoInfoSlice = result.staffPhotoInfo.slice(startIndex, endIndex);

        staffPhotoInfoSlice.forEach((value: any, index: number) => {
          const profileImage = photoType == 'circle' ? value.circularPhotoUrl : value.squarePhotoUrl;
          const name = value.name || '';
          const title = value.title || '';
          const photoDescription = value.photoDescription || '';
          let staffTeamHTML: any
          if (checkForHeavyClass) {
            staffTeamHTML = `
                      <li class="team-list-item">
                        <a href="#" class="team-data" data-modal-target="teams-modal" data-modal-toggle="teams-modal" data-index=${index}>
                        ${profileImage != null ? `<div class="member-image">
                            <img src="${profileImage}" alt="team member image" width="239" height="255">
                          </div>` : ''}
                          <div class="member-data">
                            ${name != null ? `<h3 class="member-name">${name}</h3>` : ''}
                            <p class="member-detail">${title}</p>
                          </div>
                        </a>
                        <button type="button" class="primary-outline-btn bio-btn" id="toggle-member-bio-${count}">
                          Read Bio
                          <svg>
                            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-primary" />
                          </svg>
                        </button>
                        <div class="member-bio" id="member-bio-${count}">
                        ${photoDescription}
                        </div>
                      </li>
                    `;


          } else {
            staffTeamHTML = `
            <li class="team-list-item">
              <div class="team-data">
              ${profileImage != null ? `<div class="member-image">
                  <img src="${profileImage}" alt="team member image" width="226" height="214">
                </div>` : ''}
                <div class="member-data">
                  <h3 class="member-name">${name}</h3>
                  <p class="member-detail">${title}</p>
                  <button type="button" class="primary-outline-btn bio-btn" id="toggle-member-bio-${count}">
                    Read Bio
                    <svg>
                      <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-primary" />
                    </svg>
                  </button>
                  <div class="member-bio" id="member-bio-${count}">${photoDescription}</div>
                </div>
              </div>
            </li>
          `;
          }


          staffTeamDetails.push(staffTeamHTML);
          count++;
        });
        employeeTeamDetails = [...employeeTeamDetails, ...staffTeamDetails];
        staffTeamDetails = [...employeeTeamDetails, ...staffTeamDetails];
        this.updateTeamContainer(this.staffTeamContainer, staffTeamDetails.join(''));
        this.updateTeamContainer(this.employeeTeamContainer, employeeTeamDetails.join(''));
        this.attachPaginationHandlers(result.staffPhotoInfo.length);

        const teamDataLinks = document.querySelectorAll('.heavy-list .team-list-item');
        teamDataLinks.forEach((link, index) => {
          link.addEventListener('click', this.teamData.bind(this, index, photoType, cobminedTeams))

        });

        // const closeModalBtn = document.querySelector('.close-modal-btn');
        // if (closeModalBtn) {
        //   closeModalBtn.addEventListener('click', () => {
        //     const modal = document.getElementById('teams-modal');
        //     if (modal) {
        //       modal.classList.add('hidden');
        //     }
        //   });
        // }
        new Concert();
      }
      else {
        this.hideOurTeamSection();
      }


    }
    else {
      this.hideOurTeamSection();
      this.removeHTMLElement();
    }
  }
  hideOurTeamSection() {
    let teamWrapper = document.querySelector('.our-team-section') as HTMLInputElement;
    if (teamWrapper)
      teamWrapper.style.display = 'none';
  }

  closeModal() {
    const closeModalBtn = document.querySelector('.teams-modal .close-modal-btn');
    if (closeModalBtn) {
      closeModalBtn.addEventListener('click', () => {
        const modal = document.getElementById('teams-modal');
        if (modal) {
          modal.style.display = 'none';
          (document.querySelector(".modalgrey") as HTMLElement)?.remove();
        }
      });
    }
  }

  private teamData(index: any, photoType: any, staffPhotoInfoSlice: any) {
    const modal = document.getElementById('teams-modal');
    if (modal) {
      modal.style.display = 'flex'
      modal.classList.remove('hidden');
      const modalBody = modal.querySelector('.modal-body') as HTMLElement;
      if (modalBody) {
        modalBody.style.justifyContent = 'center';
      }
      if (!(document.getElementsByClassName('modalgrey').length)) {
        let bodycomponent = document.querySelector('body') as HTMLElement;
        bodycomponent.insertAdjacentHTML('beforeend', `<div modal-backdrop="" class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 modalgrey"></div>`)
      }
      const modalContent = modal.querySelector('.modal-member-wrap');
      if (modalContent) {
        const profileImage = photoType == 'circle' ? (staffPhotoInfoSlice[index].circularPhotoUrl || staffPhotoInfoSlice[index]?.franchiseProfileEmployeeBioDetail.circularPhotoUrl) : (staffPhotoInfoSlice[index].squarePhotoUrl || staffPhotoInfoSlice[index]?.franchiseProfileEmployeeBioDetail.squarePhotoUrl);
        const name = staffPhotoInfoSlice[index]?.name || staffPhotoInfoSlice[index]?.displayName;
        const title = (staffPhotoInfoSlice[index]?.title ? staffPhotoInfoSlice[index]?.title : "") || (staffPhotoInfoSlice[index]?.franchiseProfileEmployeeBioDetail?.title ? staffPhotoInfoSlice[index]?.franchiseProfileEmployeeBioDetail?.title : "");
        const bio = staffPhotoInfoSlice[index]?.photoDescription || staffPhotoInfoSlice[index].description;

        modalContent.innerHTML = `
                    <div class="img-wrap">
                        <img src="${profileImage}" alt="team member image">
                    </div>
                    <div class="data-wrap">
                        <h2 class="member-name">${name}</h2>
                        <p class="member-detail">${title}</p>
                        <p class="member-bio">${bio}</p>
                    </div>
                `;
      }
    }
    this.closeModal();
  }
  public updateTeamContainer(container: HTMLElement | null, html: string): void {
    if (container) {
      container.innerHTML = html;
    }
  }
  public attachPaginationHandlers(totalItems: number) {
    const paginationWrapper = document.querySelector('.pagination-wrapper');
    if (!paginationWrapper) {
      return;
    }

    const pageLinks = paginationWrapper.querySelectorAll('.page-number');
    const prevBtn = paginationWrapper.querySelector('.prev-btn');
    const nextBtn = paginationWrapper.querySelector('.next-btn');

    pageLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const newPage = parseInt(link.textContent || '1');
        this.currentPage = newPage; // Update the current page

        this.getOurTeamData();
      });
    });

    prevBtn?.addEventListener('click', (event) => {
      event.preventDefault();
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.getOurTeamData();
      }
    });

    nextBtn?.addEventListener('click', (event) => {
      event.preventDefault();
      if (this.currentPage < totalItems) {
        this.currentPage += 1;
        this.getOurTeamData();
      }
    });
  }

}
document.addEventListener('DOMContentLoaded', () => {
  const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value;
  if (pageType && pageType.toLocaleLowerCase() == 'opus 2 our team page template') {
    new OurTeam();
  }
});
