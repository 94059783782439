export const contactUsPayloadModelKeys: { [key: string]: string } = {
    franchiseWebLocationId: 'number',
    conceptId: 'number',
    isLocalized: 'boolean',
    firstName: 'string',
    lastName: 'string',
    zipCode: 'string',
    email: 'string',
    phone: 'string',
    isNewCustomer: 'string',
    comments: 'string',
    signUpForUpdates: 'boolean',
    isTest: 'boolean',
    vendorId: 'number',
    howDidYouHearAboutUs: 'string',
    city: 'string',
    state: 'string',
    country: 'string',
    address: 'string',
    address2: 'string',
    legacyLeadTableId: 'number',
    legacyLeadTableId2: 'number',
    correlationId: 'string',
    conceptCode: 'string',
    optIn: 'boolean',
    emailOptOut: 'boolean'
};