import { FranchiseDetail } from "./models/FranchiseDetail";
import { locationSearchMapConfig, debugConfig } from './config';
import { cachedAPIWrapper } from "../apiCaching/apiWrapper";

export async function getFranchiseWebLocations(): Promise<void | FranchiseDetail[]>{        
        return getDataFromApi();
}

const getDataFromApi =  () => {
    return cachedAPIWrapper(locationSearchMapConfig.franchiseWebLocationsApiCall)
        .then(response => {
            return response;
        })
        .then(response =>
        {
            return filterData(response);
        })
        .catch(error =>
        {
            //console.log("Error calling getFranchiseWebLocations:", error);
        });
}

const filterData = (data: any): FranchiseDetail[] => {
    let details = data.flatMap((f: { franchiseDetails: any; })=>f.franchiseDetails);
    // details = details.filter((c: { country: string; })=>c.country ==='CAN' || c.country ==='Canada');
    let result = [];  
    // remove duplicates
    const set = new Set();
    for(let detail of details) {
        const businessName = detail?.doingBusinessAs?.toLocaleLowerCase() || ""; 
		const isTestAccount = businessName.includes("test account");
        if(!set.has(detail.franchiseWebLocationId) && detail.websiteUrl !== null && !isTestAccount) {
            result.push(detail);
            set.add(detail.franchiseWebLocationId);
        }
    }
    return result;
}
