import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { contactUsPostCallWrapper } from "../apiCaching/POST-Requests/apiContactCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { ContactUsPayloadModel } from "../apiCaching/POST-Requests/interface/contact/contactInterface";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { disclaimerHandleCheck, DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { allAddressList, discoverSuggestions, getAddressDetails, handleMissingData } from "../util/discovery";
import { DomainIdentifier } from "../util/domainService";
import { hideTermsPolicy, toggleTermsPolicyBasedOnCountry } from "../util/hideTermsPolicy";
import { startLoader, stopLoader } from "../util/loader";
import { LocalizationIdentifier } from "../util/localizationService";
import { commonStates, debounce, getBrandDetailsFromBrandJson, getCampaignName, getFranchiseDetails, getFranchiseWebLocationId, removeAllEventListeners } from "../util/share";

const errorMessage = {
    fname: "Invalid name format",
    lname: "Invalid last name format",
    email: "Invalid email format",
    phone: "Invalid phone format",
    zip: "Invalid zip code",
    city: "Invalid city",
    state: "Invalid state",
    address: "Invalid address",
    address2: "Invalid address2"
}

export class FinancesService {

    public financeBtn: any;
    public financeFirstName: any;
    public financeLastName: any;
    public financeEmail: any;
    public financePhone: any;
    public financeZip: any;
    public financeCity: any;
    public financeState: any;
    public financeAddress: any;
    public financeAddress2: any;
    private financeFormService!: NodeListOf<HTMLElement>;
    private noteWithRequestBody: any;
    zipCodeInput: any;

    constructor() {
        this.initFinanceInputs();
        this.fetchAllState();
        document.querySelector('.property-address-modal .cta-wrapper .primary-btn')?.addEventListener("click", (e) => {
            const enteredAddr: any = this.financeAddress?.value.concat(" ", this.financeAddress2.value, " ", this.financeZip?.value);
            const suggestAddr = this.financeAddress?.getAttribute("data-suggestion");
            let output: any;
            const suggestAddrInp = document.getElementById('suggested-address-radio') as HTMLInputElement;
            sessionStorage.setItem("suggestion", "true");
            if (suggestAddrInp?.checked) {
                const suggestJSON = suggestAddr !== 'undefined' && JSON.parse(suggestAddr);
                output = suggestJSON?.address.label;
                this.financeAddress.value = output;
                const suggestedAddress = getAddressDetails(suggestJSON?.address);
                sessionStorage.setItem('suggestedAddress', JSON.stringify(suggestedAddress));
            }
            else {
                output = enteredAddr;
            }
            sessionStorage.setItem('sAddressParam', output);
            this.handleFinanceClick(e);
        });
    }

    public initFinanceInputs() {

        this.financeBtn = removeAllEventListeners('#financing-form .primary-btn');
        this.financeFirstName = document.getElementById('finance-fname');
        this.financeLastName = document.getElementById('finance-lname');
        this.financeEmail = document.getElementById('finance-email');
        this.financePhone = document.getElementById('finance-phone');
        this.financeZip = document.getElementById('finance-zip-code');
        this.financeCity = document.getElementById('finance-city');
        this.financeState = document.getElementById('finance-state');
        this.financeAddress = document.getElementById('finance-address');
        this.financeAddress2 = document.getElementById('finance-address2');
        this.financeFormService = document.querySelectorAll('[id*="financing-form-service-reminder"]');
        try {


            if (this.financeBtn) {
                this.financeBtn.addEventListener("click", this.handleFinanceClick.bind(this));
            }

            if (this.financeFirstName) {
                this.financeFirstName.addEventListener('input', () => this.handleCommonInputError(this.financeFirstName, `${this.financeFirstName.id}-error-msg`, REGEX.sendName, errorMessage.fname));
            }

            if (this.financeLastName) {
                this.financeLastName.addEventListener('input', () => this.handleCommonInputError(this.financeLastName, `${this.financeLastName.id}-error-msg`, REGEX.sendName, errorMessage.lname));
            }

            if (this.financeEmail) {
                this.financeEmail.addEventListener('input', () => this.handleCommonInputError(this.financeEmail, `${this.financeEmail.id}-error-msg`, REGEX.sendEmail, errorMessage.email));
            }

            if (this.financePhone) {
                this.financePhone.addEventListener('input', this.handleMobileInput.bind(this));
            }

            if (this.financeZip) {
                this.financeZip.addEventListener('input', () => this.handleCommonInputError(this.financeZip, `${this.financeZip.id}-error-msg`, REGEX.sendZip, errorMessage.zip));
                this.financeZip?.addEventListener('focusout', this.handleFocusOut.bind(this));
            }

            if (this.financeCity) {
                this.financeCity.addEventListener('input', () => this.handleCommonInputError(this.financeCity, `${this.financeCity.id}-error-msg`, REGEX.sendCity, errorMessage.city));
            }

            if (this.financeAddress) {
                this.financeAddress.addEventListener('input', () => this.handleCommonInputError(this.financeAddress, `${this.financeAddress.id}-error-msg`, REGEX.sendAddress1, errorMessage.address));
                const debouncedSetAutoSuggested = debounce(this.setAutoSuggested.bind(this), 300);
                this.financeAddress?.addEventListener('input', debouncedSetAutoSuggested);
            }

            if (this.financeAddress2) {
                this.financeAddress2.addEventListener('input', () => this.handleCommonInputError(this.financeAddress2, `${this.financeAddress2.id}-error-msg`, REGEX.sendAddress2, errorMessage.address2));
            }

            if (this.financeState) {
                this.financeState.addEventListener('change', () => this.handleCommonInputError(this.financeState, `${this.financeState.id}-error-msg`, REGEX.sendName, errorMessage.state));
            }
            this.financeFormService?.forEach((checkbox) => {
                checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
            });
            window.onload = () => {
                const financeConfirmationBookingData = sessionStorage.getItem('financeConfirmationBookingData');
                if (financeConfirmationBookingData) {
                    this.confirmBookingData();
                }
            };
            const localisation = DomainIdentifier();
            const countryCode = getCountryCode();
            const localisedcountryCode = sessionStorage.getItem('countryCode');
            if (localisation == 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') {
                hideTermsPolicy('#financing-form .sendus-form', 'terms-policy-text');
            }

        } catch (error) {
            console.error('Error in initializing finance inputs:', error);

        }
    }

    private readonly setAutoSuggested = (e: any) => {
        e.preventDefault()
        sessionStorage.setItem("suggestion", "false");
        this.financeAddress?.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        this.debouncedSearch(this.financeZip, this.financeAddress);
    }
    private readonly debouncedSearch = debounce(async (zipCode: string, address: string) => {
        await discoverSuggestions(zipCode, address);
    }, 500);

    handleFocusOut() {
        const zipCode = this.financeZip?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
        toggleTermsPolicyBasedOnCountry('#financing-form .sendus-form', zipCode);
    }
    handleCheckboxChange() {
        const forms = document.getElementById('financing-form');
        const serviceReminderDivs = forms?.querySelectorAll('.service-reminder-main');
        let errorID = null;

        // Check if any divs with the class 'service-reminder-main' are found
        if (!serviceReminderDivs || serviceReminderDivs.length === 0) {
            console.log('Servcie Disclaimer not authored in CP');
            return;
        }

        serviceReminderDivs.forEach((div: Element) => {
            // Check if the div is visible (does not have the 'hidden' class)
            if (div && !div.classList.contains('hidden')) {
                // Find the checkbox input inside this div
                const checkbox = div.querySelector('input[type="checkbox"]');
                // Find the corresponding error message element
                const errorMsg = div.querySelector('.error-msg');

                // Check if the checkbox and errorMsg exist and if the checkbox is required
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    // Add event listener to handle checkbox state change
                    checkbox.addEventListener('change', () => {
                        // If the checkbox is checked, hide the error message
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            // If the checkbox is unchecked, show the error message
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    // Initial check: if the checkbox is required and unchecked when the page loads
                    if (checkbox.required && !checkbox.checked) {
                        errorMsg.classList.remove('hidden');
                        errorID = errorMsg?.id;
                    }
                }
            }
        });
        return errorID;
    }

    public handleMobileInput(e: any): void {
        const phoneInput = e.target;
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = phoneInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
        if (phoneInput?.value != '' && phoneInput?.value.length < 14 && formattedVal.length < 14) {
            const getErrMsg = (document.getElementById(`${phoneInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`${phoneInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? errorMessage.phone;
            }
        }
        else {
            this.hideError(phoneInput?.id)
        }

        if (x) {
            phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }

    hideError(id: any) {
        const field: any = document.getElementById(`${id}`);
        const fieldError: any = document.getElementById(`${id}-error-msg`);
        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }

    public handleCommonInputError(inputElement: any, errorId: any, validationRegex: any, errorMessage: any) {
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (inputElement.value !== '' && !validationRegex.test(inputElement.value)) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = getErrMsg ?? errorMessage;
            }
        } else {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    public async handleFinanceClick(event: Event) {
        event.preventDefault();
        if (this.validateFields()) {
            startLoader();
            try {
                    const addrFlag = sessionStorage.getItem("suggestion");
                    const enteredAddr = this.financeAddress?.value.concat(" ", this.financeAddress2.value, " ", this.financeZip?.value);
                    const suggestAddr = this.financeAddress?.getAttribute("data-suggestion") as string;
                    if (addrFlag === "false" && suggestAddr != null && suggestAddr !== 'undefined' && enteredAddr) {
                        this.openPropertyValidationModal(enteredAddr, suggestAddr);
                    } else {
                        let zipCode = this.financeZip?.value;

                        if (zipCode) {
                            this.franchiseLookupAddressApi(zipCode);
                        } else {
                            // Display the error msg if zipcode is empty
                            stopLoader();
                            this.showFailure();                            
                        }
                    }

            } catch (error) {
                console.error(`Error in finance.ts file in the handleFinanceClick function. Error message: ${error}`);
                this.hideForm();
                this.showFailure();
                stopLoader();
            }
        }
    }
    openPropertyValidationModal(addressParam: string, suggestStr: any) {
        stopLoader();
        const callPopupModal = document.getElementById('address-modal')
        callPopupModal?.click();
        const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
        const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
        const suggestJSON = suggestStr !== 'undefined' && JSON.parse(suggestStr);
        if (enteredAddr && suggestAddr) {
            enteredAddr.textContent = addressParam;
            suggestAddr.textContent = suggestJSON.address.label;
        }
    }

    public async handleNoServicedArea() {
        stopLoader();
        const btnClick = document.getElementById('finance-form-error-modal-id');
        btnClick?.click();
        const noServiceEle = document.getElementById('finance-form-no-service');
        if (noServiceEle) {
            noServiceEle.classList.remove('hidden');
            const insuranceSystemError = document.getElementById('finance-form-system-error');
            if (insuranceSystemError) {
                insuranceSystemError.classList.add('hidden');
            }
        }

    }
    public async franchiseLookupAddressApi(zipCode: any) {
        if (!zipCode) return;

        try {
            const result = await getFranchiseDetails(zipCode); // RoundRobin is true
            await this.handleApiResponse(result);
        } catch (error: any) {
            await this.handleError(error);
        }
    }

    public async handleApiResponse(result: any) {
        if (result?.length == 0) {
            //contact api flag check
            const brandData = await this.getBrandData();
            if (brandData?.finance_form_contactUs_flag) {
                this.contactus_call(brandData);
            } else {
                stopLoader();
                this.hideForm();
                this.showNoService();
            }
        } else if (result?.length > 0) {
            this.bookingApiCall(result[0]);
        }
    }
    public async contactus_call(brandDataInfo: any) {
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const franchiseId = await getFranchiseWebLocationId(this.financeZip.value);
          const brandData = brandDataInfo;
        let localised;
            if (franchiseId) {
                localised = true;
            }
            else {
                localised = false;
            }
            const franchiseWebLocationId: any = document.getElementById('weblocationId') as HTMLInputElement || document.getElementById('local_weblocationId') as HTMLInputElement
            let requestBody = this.createRequestBody(brandData, franchiseId);
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();
            // Default value to pass if checkbox is not found
            let checkboxId = '';
            const countryZip = !REGEX.sendCaPostalCode.test(this.financeZip?.value.trim()) ? 'us' : 'ca';
            if (((domain === "us" && !localisation) || (domain === "us" && localisation == "us")) && countryZip === 'us') {
                checkboxId = 'financing-form-service-reminder-us';
            } else {
                checkboxId = 'financing-form-service-reminder-ca';
            }
            const financeForm: any = document.getElementById('financing-form')
            this.noteWithRequestBody = disclaimerHandleCheck(false, financeForm, requestBody, requestBody.zipCode, checkboxId);
            requestBody = this.noteWithRequestBody;
            requestBody.optIn = this.noteWithRequestBody.optIn;
            requestBody.emailOptOut = this.noteWithRequestBody.emailOptOut;
            const contactUsPayload: ContactUsPayloadModel = {
                franchiseWebLocationId: franchiseId ? parseInt(franchiseId.toString()) : parseInt(franchiseWebLocationId?.value.toString()),
                conceptId: Number(conceptId),
                isLocalized: localised,
                firstName: requestBody.firstName,
                lastName: requestBody.lastName,
                zipCode: this.financeZip?.value,
                email: this.financeEmail?.value,
                phone: requestBody.phone,
                comments: requestBody.comments
                    ? `${requestBody.comments}, ${brandData.finance_form_contactUs_comments || ''}` // Add financeFormContactUsComments if available
                    : brandData.finance_form_contactUs_comments || '',
                signUpForUpdates: requestBody.signUpForUpdates,
                isTest: requestBody.isTest,
                vendorId: requestBody.vendorId,
                city: requestBody.city,
                state: requestBody.state,
                country: requestBody.country,
                address: requestBody.address,
                address2: requestBody.address2,
                conceptCode: requestBody.conceptCode,
                optIn: requestBody.optIn,
                emailOptOut: requestBody.emailOptOut
            };
            const financeConfirmationBookingData = {
                "FirstName": requestBody.firstName,
                "LastName": requestBody.lastName,
                "ZipCode": requestBody.zipCode,
                "Email": requestBody.email,
                "State": requestBody.state,
                "City": requestBody.city,
                "Phone": requestBody.phone,
                "Address": requestBody.address,
                "address2": requestBody.address2,
            };
            sessionStorage.setItem('financeConfirmationBookingData', JSON.stringify(financeConfirmationBookingData));
            await contactUsPostCallWrapper(apiConfig.CONTACT_US_SEND, contactUsPayload);
            this.showConfirmation();
    }
    public async bookingApiCall(result: any) {
        startLoader();
        const brandData = await this.getBrandData();
        let zipCode = this.financeZip?.value;
        let getFranchiseWebLocationIdDetails;
        if(result?.franchiseId && result?.franchiseWebLocationId)  {
            getFranchiseWebLocationIdDetails = {
                franchiseWebLocationId: result?.franchiseWebLocationId,
                franchiseId: result?.franchiseId
            }
        }else{
            getFranchiseWebLocationIdDetails = await getFranchiseWebLocationId(zipCode);
        }      
        let requestBody = this.createRequestBody(brandData, getFranchiseWebLocationIdDetails);
        const financeConfirmationBookingData = {
            "FirstName": requestBody.firstName,
            "LastName": requestBody.lastName,
            "ZipCode": requestBody.zipCode,
            "Email": requestBody.email,
            "State": requestBody.state,
            "City": requestBody.city,
            "Phone": requestBody.phone,
            "Address": requestBody.address,
            "address2": requestBody.address2,
        };
        sessionStorage.setItem('financeConfirmationBookingData', JSON.stringify(financeConfirmationBookingData));
        const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
        requestBody.city = out?.city || requestBody.city;
        requestBody.state = out?.state || requestBody.state;
        try {
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();
            // Default value to pass if checkbox is not found
            let checkboxId = '';
            const countryZip = !REGEX.sendCaPostalCode.test(this.financeZip?.value.trim()) ? 'us' : 'ca';
            if (((domain === "us" && !localisation) || (domain === "us" && localisation == "us")) && countryZip === 'us') {
                checkboxId = 'financing-form-service-reminder-us';
            } else {
                checkboxId = 'financing-form-service-reminder-ca';
            }
            const financeForm: any = document.getElementById('financing-form')
            this.noteWithRequestBody = disclaimerHandleCheck(false, financeForm, requestBody, requestBody.zipCode, checkboxId);
            requestBody = this.noteWithRequestBody;
            requestBody.optIn = this.noteWithRequestBody.optIn;
            requestBody.emailOptOut = this.noteWithRequestBody.emailOptOut;

        } catch (error) {
            console.error('An error occurred in disclaimerHandleCheck in Refer file:', error);
        }
        await this.sendRequest(requestBody);
        this.showConfirmation();
    }

    private async handleError(error: any) {
        stopLoader();
            if (error?.message === 'Full address is needed to narrow down results') {
                const zipCode: any = this.financeZip?.value;
                const address: any = this.financeAddress?.value;
                const completeAddress = `${address}, ${zipCode}`;
                this.franchiseLookupAddressApi(completeAddress);
        } else {
            this.showFailure();
        }
    }
    public async getBrandData() {
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        return await getBrandDetailsFromBrandJson(conceptCode?.value);
    }

    public createRequestBody(brandData: any, getFranchiseWebLocationIdDetails: any) {
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const campaignName = getCampaignName();

        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;
        const url = window.location.origin;
        let vendorId: any;
        let vendorName: any;
        if (brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }
        const lastName = this.financeLastName?.value ?? '';

        const host = window.location.hostname.toLowerCase();
        let IsTest = false
        if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) {
            IsTest = true;
        }
        let item = allAddressList?.filter((item: any) => item?.title?.trim() == this.financeAddress?.value?.trim())[0];

        const requestBody: BookingPayloadModel = {
            firstName: this.financeFirstName?.value.split(' ')[0] ? this.financeFirstName?.value.split(' ')[0] : '',
            lastName: this.financeLastName?.value.split(' ')[1] ? this.financeLastName?.value.split(' ')[1] : ' ',
            zipCode: this.financeZip?.value || '',
            postalCode: this.financeZip?.value || '',
            email: this.financeEmail?.value || '',
            state: this.financeState?.value || '',
            city: this.financeCity?.value || '',
            country: item?.address?.countryCode || "Not Specified",
            phone: this.financePhone?.value || '',
            address: this.financeAddress?.value || '',
            address2: this.financeAddress2?.value || '',
            comments: '',
            note: 'Finance From',
            signUpForUpdates: false,
            conceptId: Number(conceptId),
            conceptCode: conceptCode,
            leadSource: "WEB",
            leadOrigin: "WEB",
            phoneType: 'true',
            isLeadOnly: true,
            isTest: IsTest,
            scheduleID: "",
            campaignName: campaignName,
            callType: "WEB",
            conceptCalledId: Number(conceptId),
            isEstimate: false,
            emailOptOut: emailOptOut,
            vendorId: Number(vendorId),
            vendorName: vendorName,
            currentPage: url,
            customerType: 'Residential',
            preferredCommunicationType: 'Email,Phone',
            leadSourceId: defaultLeadSourceId,
            webLocationId: 0
        };

        if (lastName) {
            requestBody.lastName = lastName;
            requestBody.firstName = this.financeFirstName?.value ?? '';
        }

        if (getFranchiseWebLocationIdDetails) {
            requestBody.webLocationId = getFranchiseWebLocationIdDetails?.franchiseWebLocationId;
            requestBody.franchiseId = getFranchiseWebLocationIdDetails?.franchiseId;
        }

        return requestBody;
    }

    public async sendRequest(requestBody: BookingPayloadModel) {
            const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
            requestBody.city = out?.city || requestBody.city;
            requestBody.state = out?.state || requestBody.state;
            await bookingUsPostCallWrapper(apiConfig.BOOKING_API_URL, requestBody);
    }

    public showConfirmation() {
        const confimationRedirect = (document.getElementById('finance-redirect') as HTMLInputElement)?.value;
        if (confimationRedirect) {
            window.location.href = confimationRedirect;
        }
    }

    public hideForm() {
        const forms = document.getElementById('financing-form');
        if (forms) {
            forms.parentElement?.classList.add('hidden');
        }
    }

    public showFailure() {
        const cont = document.getElementById('failure');
        if (cont) cont.classList.remove('hidden');
    }

    public showNoService() {
        const cont = document.getElementById('noservice');
        if (cont) cont.classList.remove('hidden');
    }

    public validateFields() {
        document.querySelectorAll('.invalid-field').forEach((e: any) => e.classList.remove('invalid-field'));
        const errors: any = {};

        const validateField = (field: HTMLInputElement, regex: RegExp, errorMessage: string) => {
            if (field?.value === '') {
                errors[field?.id] = '';
            } else if (field?.value !== '' && !regex.test(field?.value)) {
                errors[field?.id] = errorMessage;
            } else if (field?.value && field?.id == 'finance-phone' && field?.value.length < 14) {
                errors[field?.id] = errorMessage;
            }
        };
        if (this.financeFirstName) {
            validateField(this.financeFirstName, REGEX.sendName, errorMessage.fname);
        }
        if (this.financeLastName) {
            validateField(this.financeLastName, REGEX.sendName, errorMessage.lname);
        }
        if (this.financePhone) {
            validateField(this.financePhone, REGEX.mobileNumberRegex, errorMessage.phone);
        }
        if (this.financeAddress) {
            validateField(this.financeAddress, REGEX.sendAddress1, errorMessage.address);
        }
        if (this.financeEmail) {
            validateField(this.financeEmail, REGEX.sendEmail, errorMessage.email);
        }
        if (this.financeZip) {
            validateField(this.financeZip, REGEX.sendZip, errorMessage.zip);
        }
        if (this.financeState) {
            validateField(this.financeState, REGEX.sendName, errorMessage.state);
        }
        if (this.financeCity) {
            validateField(this.financeCity, REGEX.sendCity, errorMessage.city);
        }
        const errorIdChekboxSpan = this.handleCheckboxChange();
        if (errorIdChekboxSpan) {
            errors[errorIdChekboxSpan] = '';
        }
        // validateField(this.financeAddress2, REGEX.sendAddress1, errorMessage.address);

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(`${fieldId}`);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field?.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                if (fieldError?.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;
    }

    async fetchAllState() {
        try {
            const state_us: any[] = await commonStates('US');
            const state_ca: any[] = await commonStates('CA');
            let finalResult = [...state_us, ...state_ca];
            finalResult.sort((a, b) => {
                if (a.stateName !== b.stateName) {
                    return a.stateName < b.stateName ? -1 : 1;
                } else {
                    return 0;
                }
            });
            this.createCommonState(finalResult);
            return finalResult;
        }
        catch (error) {
            console.error('Error in fetching state API data:', error);
        }
    }

    public createCommonState(finalResult: any[]) {
        const selectElement = document.getElementById('finance-state');
        if (selectElement) {
            selectElement.innerHTML = '<option selected="" value="">Select</option>';
        }

        finalResult.forEach(state => {
            const option = document.createElement('option');
            option.value = state.stateCode;
            option.textContent = state.stateName;
            if (selectElement) {
                selectElement.appendChild(option);
            }
        });
    }

    public confirmBookingData() {
        const financeConfirmationBookingData = sessionStorage.getItem('financeConfirmationBookingData');
        const financeConfirmation = document.getElementById('finance-confirmation');
        if (financeConfirmationBookingData && financeConfirmation) {
            const data = JSON.parse(financeConfirmationBookingData);
            this.updateConfirmationName(data);
            this.updateConfirmationEmail(data);
            this.updateConfirmationPhone(data);
            this.updateConfirmationAddress(data);
        }
    }

    private updateConfirmationName(data: any) {
        const nameDom = document.getElementById('finance_confirmation_name') as HTMLElement;
        if (data?.FirstName && nameDom) {
            nameDom.innerHTML = data?.FirstName + ' ' + data?.LastName || '';
        } else {
            nameDom?.closest('.appointment-item')?.remove();
        }
    }

    private updateConfirmationEmail(data: any) {
        const emailDom = document.getElementById('finance_confirmation_email') as HTMLElement;
        if (data?.Email && emailDom) {
            emailDom.innerHTML = data?.Email || '';
        } else {
            emailDom?.closest('.appointment-item')?.remove();
        }
    }

    private updateConfirmationPhone(data: any) {
        const phoneDom = document.getElementById('finance_confirmation_phone') as HTMLElement;
        if (data?.Phone && phoneDom) {
            phoneDom.innerHTML = data?.Phone || '';
        } else {
            phoneDom?.closest('.appointment-item')?.remove();
        }
    }
    private updateConfirmationAddress(data: any) {
        const addressDom = document.getElementById('finance_confirmation_address') as HTMLElement;
        if (data && addressDom) {
            let addressParts = '';
            if (data.Address) {
                addressParts += data.Address;
            }
            if (data.address2) {
                addressParts += (addressParts ? ', ' : '') + data.address2;
            }
            if (data.City) {
                addressParts += (addressParts ? ', ' : '') + data.City;
            }
            if (data.State || data.ZipCode) {
                addressParts += (addressParts ? ', ' : '') + `${data.State} ${data.ZipCode}`.trim();
            }
            if (!addressParts) {
                addressDom?.closest('.appointment-item')?.remove();
            }
            addressDom.innerHTML = addressParts;
        }
    }
}



document.addEventListener('DOMContentLoaded', () => {
    const financeForms = document.getElementById('financing-form') || document.getElementById('finance-confirmation');
    if(financeForms){
        const financeService = new FinancesService();
    }

    const doingBusinessAs = localStorage.getItem('doingBusinessAs');
    if (doingBusinessAs) {
        const doingBusinessAsElement = document.querySelector('.finance-sub-heading');
        if (doingBusinessAsElement) {
            doingBusinessAsElement.classList.remove('hidden');
            doingBusinessAsElement.innerHTML = doingBusinessAs;
        }
    }
});