import { userBrowserDetails } from "./checkDeviceAndBrowserType";
import { getCacheSettings } from "./purgeCache";

const displayCacheLog: boolean = (process.env.SHOW_CACHE_LOG?.toLowerCase() === 'true') || false;
const enableServiceWorker: boolean = (process.env.ENABLE_SERVICE_CACHE?.toLowerCase() === 'true') || false;
let enablePurgeCache = false;

try {
  enablePurgeCache = localStorage.getItem("cachingL2") === 'true';
} catch (error) {
    logMessage('User is in Cognito - localStorage not accessible', 'red');
}
const enviromentConsole = process.env.mod?.toLowerCase() === 'development';

/**
 * Represents user browser details.
 */
interface UserBrowserDetails {
  inCognito: boolean;
}

/**
 * Logs messages in a consistent format.
 */
function logMessage(message: string, bgColor: string, additionalData: any = ''): void {
    if(displayCacheLog)
        console.log(`%c[SW]%c ${message}`, `background:${bgColor}; color:#000; font-weight: bold; padding: 0 8px`, '', additionalData);
}

/**
 * Attempts to register the service worker.
 */
async function registerServiceWorker(): Promise<void> {
  if (!('serviceWorker' in navigator)) return;

  try {
    const registration = await navigator.serviceWorker.getRegistration('/');
    if (registration) {
        logMessage('already registered:', 'yellow', registration.scope);
    } else {
        const newRegistration = await navigator.serviceWorker.register('/brand/_assets/js/sw1.js', { scope: '/' });
        logMessage('registered with scope:', 'green', newRegistration.scope);
    }
  } catch (error) {
        console.error('%c[SW]%c registration failed:', 'background:red; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', error);
  }
}

/**
 * Fetches user browser details.
 */
async function getUserBrowserDetails(): Promise<UserBrowserDetails> {
  try {
    return await userBrowserDetails();
  } catch (error) {
    console.error('%c[SW]%c Error fetching user details:', 'background:red; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', error);
    return { inCognito: false }; // Fallback default
  }
}

/**
 * Handles user details and cache settings.
 */
export async function conditionalServiceWorkerInstall(): Promise<void> {
    const data = await getUserBrowserDetails();
    if(enviromentConsole) {
        console.log('%cNon - Prod Build has been loaded', 'background: #f3f3f3; color: red; font-size: 32px; padding: 8px 16px; margin: 8px 0; font-weight: bold;');
        logMessage('User Details', 'grey', data);
    }

    if (data.inCognito) {
        logMessage('User is in Cognito', 'red');
        return;
    }

    if (!enablePurgeCache) {
        getCacheSettings();
        return;
    }

    if (enableServiceWorker) {
        await registerServiceWorker();
        getCacheSettings();
    }
}


