import { toggleHoursOfOperation } from "../util/hours_operation";

export class GetHoursOfOperation {
    constructor(){
       this.init();
    }

    async init() {
        await toggleHoursOfOperation('.contact-info .contact-block .get_in_touch_hrs_operation', '.get_in_touch_hrs_operation .card-item', '.card-item li');
    }
}
new GetHoursOfOperation();