import { printOffer } from "./prints";

interface OfferSelectors {
    titleSelector: string;
    descriptionSelector: string;
    expirySelector: string;
    disclaimerSelector: string;
    couponCodeInputSelector: string;
}
export function attachEventListenerForClaim(sectionSelector: any, buttonSelector: any, liItem: any, commonSelectors: OfferSelectors ) {
    const claimOfferButtons = document.querySelectorAll(`${sectionSelector} ${buttonSelector}`);
    if (claimOfferButtons?.length === 0) return;

    claimOfferButtons.forEach((button,index) => {
        // Check if the href attribute is not empty
        if (button?.getAttribute('href') !== '' && button?.getAttribute('href') !== '#') {
            // Attach the event listener
            button?.addEventListener('click', (event) => {
                event.preventDefault(); // Prevent the default action 
                const target = event.target as HTMLElement;
                // Check if the target is the claim button
                if (target?.classList?.contains('secondary-btn')) {
                    const href = (target as HTMLAnchorElement).href;
                    let parentDom;
                    if (sectionSelector === '.sp-offers-section.coupons-eight' || sectionSelector === '.offers-block-section' || sectionSelector === '.offers-block-section.dynamic-offer-teaser-component') {
                        parentDom = target?.closest(liItem) as HTMLElement;
                        if (parentDom) {
                            setOffersInSessionCommon(sectionSelector,
                                parentDom,
                                href,
                                commonSelectors 
                            )
                        }
                    }
                      
                }
            });
        }
        else if (sectionSelector === '.offers-block-section' && !(button?.getAttribute('href') !== '' && button?.getAttribute('href') !== '#')) {
            button.addEventListener('click', (e) => {
                e.preventDefault();  // Prevent the default click behavior
                printOffer(e,index);  // Assuming printOffer is the function that handles printing
            });
        }
        // Attach the print functionality if the anchor is inside a `.print-offer-link`
        const printOfferLink = button.closest('.offers-block-item')?.querySelector('.print-offer-link a');
        if (printOfferLink) {
            printOfferLink.addEventListener('click', (e) => {
                e.preventDefault(); // Prevent default anchor action
                printOffer(e, index); // Assuming printOffer is the function that handles printing
            });
        }
    });
}


function setOffersInSessionCommon(
    sectionSelector: any,
    targetElement: HTMLElement,
    href: string | null,
    commonSelectors: OfferSelectors 
) {
    const obj = {
        'title': '',
        'description': '',
        'couponexpirydate': '',
        'longdescription': '',
        'couponcode': ''
    };

    // Info from offer
    obj.title = targetElement.querySelector(commonSelectors.titleSelector)?.textContent ?? '';
    obj.description = targetElement.querySelector(commonSelectors.descriptionSelector)?.textContent ?? '';
    obj.couponexpirydate = targetElement.querySelector(commonSelectors.expirySelector)?.textContent?.replace('Expires: ', '') ?? '';

    //Long description from the disclaimer text
    const firstTxt = targetElement.querySelector(`${commonSelectors.disclaimerSelector} p`)?.textContent ?? '';
    const secondTxt = targetElement.querySelector(`${commonSelectors.disclaimerSelector} p a`)?.textContent ?? '';  // Assuming you want to append the link text too
    obj.longdescription = `${firstTxt} ${secondTxt}`;

    //if its coupon11
    if (sectionSelector === '.offers-block-section' || sectionSelector === '.offers-block-section.dynamic-offer-teaser-component') {
        const firstTxt = targetElement.querySelector(`${commonSelectors.disclaimerSelector}`)?.textContent ?? '';
        obj.longdescription = `${firstTxt}`;
    }
    // coupon code from the hidden field
    const couponCodeInput = targetElement.querySelector(commonSelectors.couponCodeInputSelector) as HTMLInputElement;

    if (sectionSelector === '.offers-block-section.dynamic-offer-teaser-component') {
        const index = Array.from(targetElement.parentElement?.children ?? []).indexOf(targetElement); // Get the index of the current li item

        // Only try to fetch coupon code if the couponCodeInputSelector exists
        if (commonSelectors.couponCodeInputSelector) {
            const couponCodeSpan = targetElement.querySelector(`${commonSelectors.couponCodeInputSelector}${index}`);
            if (couponCodeSpan) {
                obj.couponcode = couponCodeSpan.textContent ?? ''; // Set coupon code if found
            }
            else {
                console.log("No Coupon code found");
            }
        }
    } else if (couponCodeInput) {
        obj.couponcode = couponCodeInput.value;
    }
    else {
        console.log("No Coupon code found");
    }
    // sessionStorage
    sessionStorage.setItem('offerDetails', JSON.stringify(obj));

    // redirect the user to the offer URL
    if (href) {
        window.location.href = href;
    }
}
