
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { toggleHoursOfOperation } from "../util/hours_operation";
const wktParser = require('terraformer-wkt-parser');
export class AreaWeServe{
    public franchiseWebLocationId: any;
    private mapContainer: HTMLElement;
    private areaContainer: HTMLElement | null;
    public wkts: string[] = [];
    public mapType: string = "";
    private centroid: string = "";
    private correlationId: string = "";
    public franchiseId: any;
    private zoom_Level:any;

    constructor() {
        const transactionID = sessionStorage.getItem('corelationID');
        const sessionID = sessionStorage.getItem('sessionID');
        this.correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;
        const localWebID = localStorage.getItem("franchiseWebLocationId");
        const webID = document.getElementById('weblocationId') as HTMLInputElement;
        this.franchiseWebLocationId = webID?.value ? webID?.value : localWebID;
        this.zoom_Level = Number((document.getElementById('map-zoom-level') as HTMLInputElement)?.value);
        this.areaContainer = document.querySelector(".area-serve-container");
        this.mapContainer = document.querySelector(".map-img-box") as HTMLElement;
        if (this.areaContainer) {
            this.loadCities();
            this.getWellKnownText();
       
            const serveHoursDiv = this.areaContainer.querySelector('.serve-hours');
            if (serveHoursDiv) {
               this.init();
            }
        }
        
        if (this.franchiseWebLocationId) {
            this.getCityData();
        }
        // this.initMap();
    }

    init() {
        toggleHoursOfOperation('.area-serve-text-section .serve-hours', '.area-serve-text-section .serve-hours-list', '.serve-hours-list li');
    }

    public loadCities(){
        const listElement = document.querySelector(".city-list")as HTMLUListElement;
        const readMoreButton = document.getElementById("readMoreListButton") as HTMLButtonElement;
        if (window.innerWidth < 1024) {
            if (listElement) {
                const listItems = Array.from(listElement.children) as HTMLLIElement[];
                const maxItems = 20;
                if (listItems.length > maxItems) {
                    // Hide excess list items
                    for (let i = maxItems; i < listItems.length; i++) {
                        listItems[i].style.display = "none";
                    }
                    readMoreButton.classList.remove("hidden");
                    readMoreButton.style.display = '';
                    // Event listener for read more/less button
                    readMoreButton.addEventListener("click", function() {
                        if (readMoreButton.textContent === "Read more...") {
                            for (let i = maxItems; i < listItems.length; i++) {
                                listItems[i].style.display = "list-item";
                            }
                            readMoreButton.textContent = "Read less...";
                        } else {
                            for (let i = maxItems; i < listItems.length; i++) {
                                listItems[i].style.display = "none";
                            }
                            readMoreButton.textContent = "Read more...";
                        }
                    });
                }
                else{
                    readMoreButton.classList.add("hidden");
                    readMoreButton.style.display = 'none';
                }
            }
        }
    }
    public multipleWkts() {
        this.wkts.forEach((wkt) => {
            if (this.mapType === "polygon") {
                this.initGMapPolygon();
            }else{
                this.initGMap(wkt);
            }
        });
    }

    private initGMapPolygon() {
        let map: google.maps.Map;
        
        let minX: number, minY: number, maxX: number, maxY: number;

        const addPoints = (ptsArray: google.maps.LatLng[], data: string) => {
            const pointsData = data.split(",");
            let str = "";
            for (let i = 0; i < pointsData.length; i++) {
                const xy = pointsData[i].trim().split(" ");
                const pt = new google.maps.LatLng(parseFloat(xy[1]), parseFloat(xy[0]));
                str = str + ", " + xy[1] + " " + xy[0];
                ptsArray.push(pt);

                if (i === 0) {
                    minX = maxX = parseFloat(xy[1]);
                    minY = maxY = parseFloat(xy[0]);
                } else {
                    minX = Math.min(parseFloat(xy[1]), minX);
                    minY = Math.min(parseFloat(xy[0]), minY);
                    maxX = Math.max(parseFloat(xy[1]), maxX);
                    maxY = Math.max(parseFloat(xy[0]), maxY);
                }
            }
        };

        const createPoly = (wkt: string) => {
            const regex = /\(([^()]+)\)/g;
            const Rings: string[] = [];
            let results;
            while ((results = regex.exec(wkt))) {
                Rings.push(results[1]);
            }

            const ptsArray: google.maps.LatLng[] = [];
            let maxLen = 0;
            let j = 0;

            for (let i = 0; i < Rings.length; i++) {
                if (maxLen <= Rings[i].length) {
                    maxLen = Rings[i].length;
                    j = i;
                }
            }

            addPoints(ptsArray, Rings[j]);

            const poly = new google.maps.Polygon({
                paths: ptsArray,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#1E90FF",
                fillOpacity: 0.35,
            });

            return poly;
        };

        this.wkts.forEach((wkt) => {
            const poly = createPoly(wkt);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Use the user's location as the center
                    const zoomLevel = this.zoom_Level ? this.zoom_Level : 8.8;
                    const myOptions: google.maps.MapOptions = {
                        zoom: zoomLevel,
                        center: new google.maps.LatLng(37.7749, -122.4194),
                        zoomControl: true,
                        scaleControl: true,
                    };

                    map = new google.maps.Map(this.mapContainer, myOptions);
                    const bounds = new google.maps.LatLngBounds();
                    poly.getPath().forEach((path) => {
                        bounds.extend(path);
                    });
                    map.fitBounds(bounds);
                    let listener = google.maps.event.addListener(map, "idle", function() { 
                        map.setZoom(zoomLevel); 
                        google.maps.event.removeListener(listener); 
                   });
                    poly.setMap(map);
                },
                (error) => {
                    const centerLatitude = (maxX + minX) / 2;
                    const centerLongitude = (maxY + minY) / 2;
                    const zoomLevel = this.zoom_Level ? this.zoom_Level : 8.8;

                    const myOptions: google.maps.MapOptions = {
                        zoom: zoomLevel,
                        center: new google.maps.LatLng(centerLatitude, centerLongitude),
                        zoomControl: true,
                        scaleControl: true,
                    };
                    map = new google.maps.Map(this.mapContainer, myOptions);
                    const bounds = new google.maps.LatLngBounds();
                    poly.getPath().forEach((path) => {
                        bounds.extend(path);
                    });
                    map.fitBounds(bounds);
                    let listener = google.maps.event.addListener(map, "idle", function() { 
                        map.setZoom(zoomLevel); 
                        google.maps.event.removeListener(listener); 
                   });
                    poly.setMap(map);
                }
            );
        });
    }

    private getCityData(): void {
        try {
            const request = apiConfig.fullAttribute + this.franchiseWebLocationId;

            const updateContent = (element: HTMLElement | null, content: string) => {
                if(element) {
                    element.textContent = content;
                }
            }

            //This function handles the updation of the address
            const updateAddressContent = (resp: any, filterFlag: any) => {
                const { address, address2, state, country, city, zip, displayAddressSwitch } = resp;
                const checkForDOMElement = document.getElementById("show-address");
                if(!filterFlag.disable_location_address && checkForDOMElement) {
                    const areaWeServe = document.querySelector('.area-serve-title') as HTMLElement;
                    if(areaWeServe){
                        areaWeServe.innerHTML+= `<p class="title-sub-text"></p>`;
                    }
                    const addressContainer = document.querySelector('.area-serve-title .title-sub-text') as HTMLElement;
                    if(displayAddressSwitch) {
                        updateContent(addressContainer, `${address}${address2 ? ', ' + address2 : ''} ${city}, ${state} ${zip}, ${country}`);
                    } else {
                        updateContent(addressContainer, `${city}, ${state} ${zip}, ${country}`);
                    }
                }
            }
            
            //This updates the h2 title
            const updateTitleContent = (resp: any) => {
                const { locationDoingBusinessAs } = resp;
                const addressTitle = document.querySelector('.area-serve-title .title-heading') as HTMLElement;
                if(!addressTitle?.textContent && locationDoingBusinessAs) {
                    updateContent(addressTitle, locationDoingBusinessAs);
                }
            }
            
            cachedAPIWrapper(request)
                .then((resp: any) => {
                    const brandSpecificData = localStorage.getItem("brandDetails");
                    if(brandSpecificData){
                        const filterFlag = JSON.parse(brandSpecificData);
                        updateAddressContent(resp, filterFlag);
                    }
                    updateTitleContent(resp);
                })
                .catch((err) => {
                    console.error(err)
                });
            
            
        } catch (error) {
            console.log(error);
        }
    }

    private getWellKnownText() {
        cachedAPIWrapper(apiConfig.WebLocationsAPI)
        .then(data=> {
            if(data){
                // Find the franchiseId associated with the given webLocationId
            this.franchiseId = this.findFranchiseIdByWebLocationId(data, this.franchiseWebLocationId);
            if(!this.franchiseId || this.franchiseId === null){
                console.log("Franchise ID invalid");
                return;
            }
            const apiUrl = `${apiConfig.FranchiseTerritoriesAPI}&franchiseId=${this.franchiseId}`;
            getCachedAPIResponse(apiUrl)
                .then((result: any) => {
                    let territoryMapData:any;
                    result.franchiseTerritories.forEach((value: any) => {
                        if(value.franchiseWebLocationId === Number(this.franchiseWebLocationId) && value.comment.includes("website")){
                            territoryMapData =  value;
                        }
                    });
                    if (territoryMapData?.territoryBoundary?.wellKnownText) {
                        const wktData = territoryMapData.territoryBoundary.wellKnownText;
                        this.wkts.push(wktData);
                        this.mapType = wktData.split(" ")[0].toLowerCase();
                        this.centroid = territoryMapData.territoryBoundary.centroid;
                        this.multipleWkts();
                    }
                    else{
                        console.log("No map data available");
                    }
                })
                .catch((error) => {
                    console.log("Error:", error);
                });
            }
            else{
                console.log("No locations available");
            }
            
        })
        .catch((err)=>{
            console.error("Fetch error:", err);
        });

    }
    findFranchiseIdByWebLocationId(data: any, webLocationId: any) {
        if(data?.length>0){
            for (const item of data) {
                const franchiseDetails = item.franchiseDetails;
                for (const detail of franchiseDetails) {
                    if (detail.franchiseWebLocationId == webLocationId) {
                        return item.franchiseId;
                    }
                }
            }
        }
        return null; // Return null if not found
    }
    private initGMap(wkt:any){
        let geometry = wktParser.parse(wkt);
        let center = this.centroid.replace("POINT (", "").replace(')', "").split(" ");
        let data = {
            "type": "FeatureCollection",
            "features": [ {
                "type": "Feature",
                "properties": {
                    "fillColor": "#1E90FF"
                    },
                "geometry": geometry
            }]
        };
        let mapProp = {
            center: {lat: parseFloat(center[1]), lng: parseFloat(center[0])},
            zoom: this.zoom_Level ? this.zoom_Level : 8.8,
            zoomControl: true,
            scaleControl: true,
        };
        
        let map = new google.maps.Map(this.mapContainer, mapProp);
        map.data.addGeoJson(data);
        map.data.setStyle(function (feature:any) {

            return {
                fillColor: '#1E90FF',
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillOpacity: 0.35
            };
        });
        }
}



new AreaWeServe();