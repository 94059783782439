import { ContactUsPayloadModel } from "./interface/contact/contactInterface";
import apiRequest from "../../api/apiRequest";
import { startLoader, stopLoader } from "../../util/loader";
import { enviromentCheck, isTestEnvironment, isValidZipCode, normalizeKeys, removeNullAndEmpty, storeFailedResponse } from "./helperModules/helperFunctions";
import { contactUsPayloadModelKeys  } from "./keys/contactKeys";
import { handleFakeSubmit } from "../../util/share";
import { handleMissingData } from "../../util/discovery";


//Check if the payload is valid
function isValidContactPayload(payload: any): payload is ContactUsPayloadModel {
    let payloadLower = JSON.parse(JSON.stringify(normalizeKeys(payload)));
        payloadLower = JSON.parse(JSON.stringify(removeNullAndEmpty(payloadLower)));

    //checking if all mandatory fields are present
    const mandatoryFields = ['isLocalized', 'zipCode', 'email'];
    const hasAllMandatoryFields = mandatoryFields.every(field => payloadLower?.hasOwnProperty(normalizeKeys(field)));
    if (!hasAllMandatoryFields) return false;

    //checking if any invalid keys are present
    const hasInvalidKeys = Object.keys(payloadLower).some((key) => {
      if(!contactUsPayloadModelKeys.hasOwnProperty(key)){
        console.error('Invalid key in Contact Payload:', key);
        return true;
      }
    });
    if (hasInvalidKeys) return false;

    const hasInvalidTypes = Object.entries(payloadLower).some(([key, value]) => {
        if (value === undefined) {
          console.error('Undefined value in Contact Payload:', key);
          return false;
        }
        console.log(typeof value, contactUsPayloadModelKeys[key],"Value:",value,"Key:",key);
        return typeof value !== contactUsPayloadModelKeys[key];
    });

    if (hasInvalidTypes) return false;

    //check for valid Zipcode and updated trimmed zipcode
    if (!isValidZipCode(payloadLower.zipCode)) return false;

    payload.ZipCode = isValidZipCode(payloadLower.zipCode);

    //check for the enviroment and send in the payload
    payload.IsTest = isTestEnvironment();
    return payload;
}

//prepoupulate the payload with the required fields
async function preparePayload(payload: any): Promise<ContactUsPayloadModel> {
  payload.isLocalized = !!payload.franchiseWebLocationId;

  if (!payload.conceptId && !payload.ConceptId) {
    console.error('Concept Id is missing in the payload');
  }

  payload.address = payload.address ?? "No address collected";
  payload.address = payload.address.replace(/^,/, '').trim();

  payload.comments = payload.comments?.replace(/^[ \t]*,+/, '').trim();
  
  const city = payload.city ?? payload.City;
  const state = payload.state ?? payload.State;
  const zipCode = payload.zipCode ?? payload.ZipCode;

  try {
    const { city: updatedCity, state: updatedState } = await handleMissingData(city, state, zipCode);
    payload.city = updatedCity ?? city;
    payload.state = updatedState ?? state;
  } catch (error) {
    console.error('Error in preparing payload for Contact us endpoint', error);
  }

  return payload;
}

//Calling the ContactUS end point
export async function contactUsPostCallWrapper(url: string, payload: any) {
  
  //returns true if pass and false if fail
  if(!enviromentCheck(url)){
    stopLoader();
    return false;
  }

  const enableStrictValidation = process.env.ENABLE_STRICT_PAYLOAD_VALIDATION?.toLowerCase() === 'true';
  payload = await preparePayload(payload);
  if(enableStrictValidation){
    if (!isValidContactPayload(payload)) {
      console.error('Invalid payload for Contact us Endpoint:', payload);
      throw new Error(`Invalid payload for Contact us Endpoint: ${JSON.stringify(payload)}`);
    }
  }
  else{
    console.log("Strict Payload validation disabled") 
  }
  const request = {
    method: 'POST',
    url,
    data: JSON.parse(JSON.stringify(normalizeKeys(payload))),
  };

    //Code to handle Fake Submit
    if (request?.data?.firstName === "isfakesubmit") {
      handleFakeSubmit(request);
      const formdata = JSON.stringify(payload);
      sessionStorage.setItem('fakeSubmitData',formdata);
      return true;
  }

  try {
    startLoader();
    const response = await apiRequest(request);
    return response;
  } catch (err) {
    console.error('Error:', err);
    storeFailedResponse(request, err);
    throw(err);
  } finally {
    stopLoader();
  }
}