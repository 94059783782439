import { getAddressConfirmation } from "../util/discovery";

export class InsuranceConfirmation {
    private static instance: InsuranceConfirmation;

    static getInstance(): InsuranceConfirmation {
        if (!InsuranceConfirmation.instance) {
            InsuranceConfirmation.instance = new InsuranceConfirmation();
        }
        return InsuranceConfirmation.instance;
    }
    constructor() {
        this.displayConfirmationDetails();
    }

    private displayConfirmationDetails(): void {
        const insuranceConfirmationId = document.querySelector('#insurance-confirmation');
        const insuranceConfirmation = sessionStorage.getItem('insuranceConfirmation');
        if (insuranceConfirmation && insuranceConfirmationId) {
            const parsedInsuranceConfirmation:any = JSON.parse(insuranceConfirmation);

            this.customerInfo(parsedInsuranceConfirmation);
            this.insuranceInfo(parsedInsuranceConfirmation);
            this.vehicleInfo(parsedInsuranceConfirmation);
            this.agencyInfo(parsedInsuranceConfirmation);
            this.addtionaInfo(parsedInsuranceConfirmation);
        }
    }

    private customerInfo(parsedInsuranceConfirmation:any): void {
        const customerInfoDiv = document.querySelector('.customer-info');
        const customerName  = parsedInsuranceConfirmation?.firstName + ' ' + parsedInsuranceConfirmation?.lastName;
        const finalAddress = this.getFinalAddress(parsedInsuranceConfirmation);
        
        if (customerInfoDiv) {
            const iconBlockHeading = customerInfoDiv.querySelector('.icon-block-heading');
            if (iconBlockHeading) {
                const customerInfoHtml = this.addCustomerInfo(customerName, finalAddress, parsedInsuranceConfirmation);
                if(customerInfoHtml) {
                    iconBlockHeading.insertAdjacentHTML('afterend', customerInfoHtml);
                }else{
                    customerInfoDiv.classList.add('hidden');
                }

            }
        } 
    }

    private getFinalAddress(parsedInsuranceConfirmation: any): string {
        let obj = getAddressConfirmation(parsedInsuranceConfirmation);
        if (obj.line1 && obj.line2) {
            return `${obj.line1} ${obj.line2}`;
        } else if (obj.line1) {
            return `${obj.line1}`;
        } else if (obj.line2) {
            return `${obj.line2}`;
        } else {
            return ``;
        }
    }

    private addCustomerInfo( customerName: string, finalAddress: string, parsedInsuranceConfirmation: any) {

        let customerInfoHtml = "";
        if(customerName){
            customerInfoHtml +=`<p class="icon-block-text">Customer Name: <span>${customerName}</span></p>`;
        }

        if(finalAddress){
            customerInfoHtml +=`<p class="icon-block-text">Address: <span>${finalAddress}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.email){
            customerInfoHtml +=`<p class="icon-block-text">Email Address: <span>${parsedInsuranceConfirmation?.email}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.phone){
            customerInfoHtml +=`<p class="icon-block-text">Phone Number: <span>${parsedInsuranceConfirmation?.phone}</span></p>`;
        }
        return customerInfoHtml;
    }

    private insuranceInfo(parsedInsuranceConfirmation: any): void {
        const insuranceInfoDiv = document.querySelector('.insurance-info');
        if (insuranceInfoDiv) {
            const iconBlockHeading = insuranceInfoDiv.querySelector('.icon-block-heading');
            if (iconBlockHeading) {
                this.addInsuranceInfo(parsedInsuranceConfirmation);
                const insuranceInfoHtml = this.addInsuranceInfo(parsedInsuranceConfirmation);
                if(insuranceInfoHtml){
                    iconBlockHeading.insertAdjacentHTML('afterend', insuranceInfoHtml);
                }else{
                    insuranceInfoDiv.classList.add('hidden');
                }
            }
        }
    }

    private addInsuranceInfo(parsedInsuranceConfirmation: any) {
        // Adding Insurance Info
        let insuranceInfoHtml = "";
        if(parsedInsuranceConfirmation?.insuranceCompany){
            insuranceInfoHtml +=`<p class="icon-block-text">Insurance Company: <span>${parsedInsuranceConfirmation?.insuranceCompany}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.insuranceCompanyPhoneNumber){
            insuranceInfoHtml +=`<p class="icon-block-text">Phone Number: <span>${parsedInsuranceConfirmation?.insuranceCompanyPhoneNumber}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.policyNumber){
            insuranceInfoHtml +=`<p class="icon-block-text">Policy Number: <span>${parsedInsuranceConfirmation?.policyNumber}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.deductibleAmount){
            insuranceInfoHtml +=`<p class="icon-block-text">Deductible: <span>${parsedInsuranceConfirmation?.deductibleAmount}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.insuranceDateOfLoss){
            insuranceInfoHtml +=`<p class="icon-block-text">Date of Loss: <span>${parsedInsuranceConfirmation?.insuranceDateOfLoss}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.insuranceCauseOfLoss){
            insuranceInfoHtml +=`<p class="icon-block-text">Cause of Loss: <span>${parsedInsuranceConfirmation?.insuranceCauseOfLoss}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.claimNetworkReferralNumber){
            insuranceInfoHtml +=`<p class="icon-block-text">Claim Number: <span>${parsedInsuranceConfirmation?.claimNetworkReferralNumber}</span></p>`;
        }

        return insuranceInfoHtml

    }

    private vehicleInfo(parsedInsuranceConfirmation: any): void {
        const vehicleInfoDiv = document.querySelector('.vehicle-information');
        if (vehicleInfoDiv) {
            const iconBlockHeading = vehicleInfoDiv.querySelector('.icon-block-heading');
            if (iconBlockHeading) {
                const vehicleInfoHtml = this.addVehicleInfo(parsedInsuranceConfirmation);
                if(vehicleInfoHtml){
                    iconBlockHeading.insertAdjacentHTML('afterend', vehicleInfoHtml);
                }else{
                    vehicleInfoDiv.classList.add('hidden');
                }

            }
        }
    }

    private addVehicleInfo(parsedInsuranceConfirmation: any) {
        // Adding Vehicle Info
        let vehicleInfoHtml = "";
        if(parsedInsuranceConfirmation?.vin){
            vehicleInfoHtml +=`<p class="icon-block-text">VIN: <span>${parsedInsuranceConfirmation?.vin}</span></p>`;
        }
        if(parsedInsuranceConfirmation?.year){
            vehicleInfoHtml +=`<p class="icon-block-text">Year: <span>${parsedInsuranceConfirmation?.year}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.make){
            vehicleInfoHtml +=`<p class="icon-block-text">Make: <span>${parsedInsuranceConfirmation?.make}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.model){
            vehicleInfoHtml +=`<p class="icon-block-text">Model: <span>${parsedInsuranceConfirmation?.model}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.bodyStyle){
            vehicleInfoHtml +=`<p class="icon-block-text">Style: <span>${parsedInsuranceConfirmation?.bodyStyle}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.serviceRequested.length > 0) {
            vehicleInfoHtml +=`<p class="icon-block-text">Glass to be replaced/repaired: <span>${parsedInsuranceConfirmation?.serviceRequested?.join(",")}</span></p>`;
        }

        return vehicleInfoHtml;
    }

    private agencyInfo(parsedInsuranceConfirmation: any): void {
        const agencyInfoDiv = document.querySelector('.agency-info');
        if (agencyInfoDiv) {
            const iconBlockHeading = agencyInfoDiv.querySelector('.icon-block-heading');
            if (iconBlockHeading) {
                const agencyInfoHtml = this.addAgencyInfo(parsedInsuranceConfirmation);
                if(agencyInfoHtml){
                    iconBlockHeading.insertAdjacentHTML('afterend', agencyInfoHtml);
                }else {
                    agencyInfoDiv.classList.add('hidden');
                }
            }
        }
    }

    
    private addAgencyInfo(parsedInsuranceConfirmation: any) {
        // Adding Agency Info
        let agencyInfoHtml = "";
        if(parsedInsuranceConfirmation?.agencyAgentName){
            agencyInfoHtml +=`<p class="icon-block-text">Agent’s Name: <span>${parsedInsuranceConfirmation?.agencyAgentName}</span></p>`;
        }
        if(parsedInsuranceConfirmation?.agencyName){
            agencyInfoHtml +=`<p class="icon-block-text">Agency Name: <span>${parsedInsuranceConfirmation?.agencyName}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.agencyAddress){
            agencyInfoHtml +=`<p class="icon-block-text">Address: <span>${parsedInsuranceConfirmation?.agencyAddress}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.agencyEmailAddress){
            agencyInfoHtml +=`<p class="icon-block-text">Email Address: <span>${parsedInsuranceConfirmation?.agencyEmailAddress}</span></p>`;
        }

        if(parsedInsuranceConfirmation?.agencyDirectPhoneNumber){
            agencyInfoHtml +=`<p class="icon-block-text">Phone Number: <span>${parsedInsuranceConfirmation?.agencyDirectPhoneNumber}</span></p>`;
        }
        return agencyInfoHtml;
       
    }

    private addtionaInfo(parsedInsuranceConfirmation: any): void {
        const additionalInfoDiv = document.querySelector('.additional-information');
        if (additionalInfoDiv) {
            const iconBlockHeading = additionalInfoDiv.querySelector('.icon-block-heading');
            if (iconBlockHeading) {
                const additionHtml = this.addAdditionalInfo(parsedInsuranceConfirmation);
                if(additionHtml){
                    iconBlockHeading.insertAdjacentHTML('afterend', additionHtml);
                }else {
                    additionalInfoDiv.classList.add('hidden');
                }

            }
        }
    }

    private addAdditionalInfo(parsedInsuranceConfirmation: any) {
        // Adding Additional Info
        let additionalInfoHtml = "";
        if(parsedInsuranceConfirmation?.serviceRequested.length > 0) {
            additionalInfoHtml +=`<p class="icon-block-text"><span>${parsedInsuranceConfirmation?.serviceRequested?.join(",")}</span></p>`;
        }

        return additionalInfoHtml; 
    }


}

document.addEventListener('DOMContentLoaded', () => {
    InsuranceConfirmation.getInstance();
});