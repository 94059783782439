export function printOffer(e: Event, index: any) {

    const formDiv = (document.querySelectorAll('.sp-offers-item')[index] || document.querySelectorAll('.offers-block-item')[index]) as HTMLDivElement;
    // Create an iframe element
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentWindow?.document;
    if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write('<html><head>');
        iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/service.css" media="all">');
        iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/main.css" media="all">');
        iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/brand-config.css" media="all">');
        iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/header.css" media="all">')
        iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/footer.css" media="all">');
        iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/glossary.css" media="all">');
        iframeDoc.write(`<style>
        @page {
            margin:0mm;
            
          }
          * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
        }
        .claim-offer{
            display:none
        }
        @media print { 
            @-moz-document url-prefix() {
        
             .btn-group li {
                display: none !important;
             }
             .btn-group li:first-child {
                display: block !important;
             }
                }
        }
        
        </style>
        `);
        iframeDoc.write('</head>');
        iframeDoc.write('<body>');
        iframeDoc.write('<section class="sp-offers-section">');
        iframeDoc.write('<div class="container">');
        iframeDoc.write('<ul class="sp-offers-list">');
        if (formDiv?.classList?.contains("offers-block-item")) {
            const secondHtmlElement: HTMLElement = formDiv;
            const convertedElement = convertToFirstReference(secondHtmlElement);
            iframeDoc.write(convertedElement.innerHTML);
        } else {
            iframeDoc.write(formDiv?.innerHTML);
        }
        iframeDoc.write('</div>');
        iframeDoc.write('</ul>');
        iframeDoc.write('</section>');
        iframeDoc.write('</body></html>');
        iframeDoc.close();
        
        // Wait for the iframe content to load before printing
        iframe.onload = function () {
            try {
                const contentWindow = iframe.contentWindow;
                if (contentWindow) {
                    iframe.contentWindow?.focus();
                    iframe.contentWindow?.print();
                } else {
                    console.log("Content window is not available.");
                }
            } catch (error) {
                console.log("err", error)
            }
        };

            // Additional error handling for onload event
            iframe.onerror = function (errorEvent) {
                console.log(errorEvent);
            };

    } else {
        alert('Printing is blocked by the browser. Please allow pop-ups for this site to print the offer.');
    }
}

function convertToFirstReference(htmlElement: HTMLElement): HTMLElement {
    const liElement = document.createElement('li');
    liElement.classList.add('sp-offers-item');
    const mainDiv = document.createElement('div');
    mainDiv.classList.add('sp-offers-main');
    const dataDiv = document.createElement('div');
    dataDiv.classList.add('sp-offers-data');

    const detailsDiv = document.createElement('div');
    detailsDiv.classList.add('sp-offers-details');

    const brandLogoImg = htmlElement.querySelector('.brand-logo')?.cloneNode(true) as HTMLImageElement;

    const blockOfferParagraph = htmlElement.querySelector('.block-offer')?.cloneNode(true) as HTMLParagraphElement;

    const offersBlockDescParagraph = htmlElement.querySelector('.offers-block-desc')?.cloneNode(true) as HTMLParagraphElement;

    detailsDiv.appendChild(brandLogoImg);
    detailsDiv.appendChild(blockOfferParagraph);
    detailsDiv.appendChild(offersBlockDescParagraph);
    dataDiv.appendChild(detailsDiv);

    const disclaimerDiv = htmlElement.querySelector('.offers-block-disclaimer')?.cloneNode(true) as HTMLDivElement;
    const expireBlockSpan = htmlElement.querySelector('.offers-block-expire')?.cloneNode(true) as HTMLSpanElement;
    const watermarkBlockImg = htmlElement.querySelector('.offers-watermark')?.cloneNode(true) as HTMLImageElement;

    mainDiv.appendChild(dataDiv);

    if(disclaimerDiv){
        mainDiv.appendChild(disclaimerDiv);
    }

    if(expireBlockSpan){
        mainDiv.appendChild(expireBlockSpan);
    }

    if(watermarkBlockImg){
        mainDiv.appendChild(watermarkBlockImg);
    }
    liElement.appendChild(mainDiv);

    return liElement;
}