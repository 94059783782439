import { detectIncognito } from "detectincognitojs";

export interface BrowserDetails {
    userAgent: string;
    userBrowser: string;
    userBrowserVersion: string;
    majorVersion: string | number;
    deviceType: string;
    operatingSystem: string;
    screenResolution: string;
    inCognito: boolean;
  }
  
  /**
   * Fetches detailed information about the user's browser, device, and OS.
   */
  export async function userBrowserDetails(): Promise<BrowserDetails> {
    const getUSDetails = localStorage.getItem('encypUsrDtl');
    if (getUSDetails) {
      try {
        const decodedData = atob(getUSDetails);
        return JSON.parse(decodedData);
      } catch (error) {
        console.warn("Error in encypUsrDtl:", error);
      }
    }
    try {
      const userAgent = navigator.userAgent ?? "Unknown";
      const userBrowser = getBrowserName(userAgent) ?? "Unknown";
      const userBrowserVersion = getBrowserVersion(userAgent) ?? "Unknown";
      const majorVersion: number | string = parseInt(userBrowserVersion, 10) || "Unknown";
      const deviceType = getDeviceType(userAgent) ?? "Unknown";
      const operatingSystem = getOS(userAgent) ?? "Unknown";
      const screenResolution = `${window.screen?.width ?? 0}x${window.screen?.height ?? 0}`;
      const inCognito = await isPrivateMode() ?? false;
      
      const userData = {
        userAgent,
        userBrowser,
        userBrowserVersion,
        majorVersion,
        deviceType,
        operatingSystem,
        screenResolution,
        inCognito,
      };
      
      const encodedUserData = btoa(JSON.stringify(userData));
      localStorage.setItem('encypUsrDtl', encodedUserData);
      return userData;
    } catch (error) {
      console.error("Error fetching user browser details:", error);
      return {
        userAgent: "Unknown",
        userBrowser: "Unknown",
        userBrowserVersion: "Unknown",
        majorVersion: "Unknown",
        deviceType: "Unknown",
        operatingSystem: "Unknown",
        screenResolution: "Unknown",
        inCognito: false, // Default fallback
      };
    }
  }
  
  /**
   * Determines the user's browser name.
   */
  function getBrowserName(userAgent: string): string {
    if (/Firefox/i.test(userAgent)) return "Firefox";
    if (/Edg/i.test(userAgent)) return "Edge"; // Chromium-based Edge
    if (/Chrome/i.test(userAgent) && !/Chromium/i.test(userAgent)) return "Chrome";
    if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) return "Safari";
    if (/OPR|Opera/i.test(userAgent)) return "Opera";
    return "Unknown";
  }
  
  /**
   * Extracts the browser version from the user agent string.
   */
  function getBrowserVersion(userAgent: string): string {
    const match = /(Firefox|Chrome|Safari|Edg|Opera|OPR)\/(\d+\.\d+)/.exec(userAgent);
    return match ? match[2] : "Unknown";
  }
  
  /**
   * Identifies the user's device type.
   */
  function getDeviceType(userAgent: string): string {
    if (/Mobi|Android/i.test(userAgent)) return "Mobile";
    if (/Tablet|iPad/i.test(userAgent)) return "Tablet";
    return "Desktop";
  }
  
  /**
   * Detects the user's operating system.
   */
  function getOS(userAgent: string): string {
    if (/Windows NT 10/i.test(userAgent)) return "Windows 10/11";
    if (/Windows NT 6.3/i.test(userAgent)) return "Windows 8.1";
    if (/Windows NT 6.2/i.test(userAgent)) return "Windows 8";
    if (/Windows NT 6.1/i.test(userAgent)) return "Windows 7";
    if (/Mac OS X/i.test(userAgent)) return "MacOS";
    if (/Linux/i.test(userAgent)) return "Linux";
    if (/Android/i.test(userAgent)) return "Android";
    if (/iPhone|iPad|iPod/i.test(userAgent)) return "iOS";
    return "Unknown";
  }
  
  /**
   * Detects if the browser is in private/incognito mode.
   * @returns {Promise<boolean>} True if the browser is in private/incognito mode, false otherwise.
   */
  async function isPrivateMode(): Promise<boolean> {
    try {
      const result = await detectIncognito();
      if (result === null || result === undefined) {
        return false;
      }
      if (typeof result.isPrivate !== "boolean") {
        return false;
      }
      return result.isPrivate;
    } catch (error) {
      return false;
    }
  }
  
  
  
  
  