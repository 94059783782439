import { REGEX } from "../constants/regex";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { DomainIdentifier } from "./domainService";
export function hideTermsPolicy(formSelector: string, termsPolicyClass: string) {
    const form = document.querySelector(formSelector) as HTMLElement;
    if (form) {
        // Get all children of the form
        const children = Array.from(form.children);          
        // To find the direct terms-policy-text child
        const termsPolicy = children.find(child => child.classList?.contains(termsPolicyClass)) as HTMLElement;        
        if (termsPolicy) {
            // Hide the terms-policy-text
            termsPolicy.style.display = 'none';
        }
    }
}

export function toggleTermsPolicyBasedOnCountry(formSelector: string, zipCode: string) {
    const form = document.querySelector(formSelector) as HTMLElement;
    if (form) {
        const countryZip = !REGEX.sendCaPostalCode.test(zipCode || '') ? 'us' : 'ca';
        const children = Array.from(form.children);
        const localisation = DomainIdentifier();
        const countryCode = getCountryCode();
        const localisedcountryCode = sessionStorage.getItem('countryCode');
        // Find the .terms-policy-text child
        const termsPolicy = children.find(child => child.classList?.contains('terms-policy-text')) as HTMLElement;
        if (termsPolicy) {
            if ((countryZip === 'ca') && (localisation === 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') || (countryZip === 'ca') || ((zipCode === '') && (localisation == 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca'))) {
                termsPolicy.style.display = 'none'; // Hide for Canada ZIP codes & Canada local
            } 
            else if((countryZip === 'us' && zipCode !== '') && (localisation === 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') ) {
                termsPolicy.style.display = 'block';
            }
            else {
                termsPolicy.style.display = 'block'; // Show for US ZIP codes
            }
        }
    }
}


