import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { CleaningType } from "../mly";
import { getBrandDetailsFromBrandJson } from "./share";

(Number.prototype as any).degreeToRadius = function (this: any): number {
    return this * (Math.PI / 180);
};

(Number.prototype as any).radiusToDegree = function (this: any): any {
    return (180 * this) / Math.PI;
};
export class NearbyLocations {
    private units: string;
    private franchiseLimit: number;
    private countryCode: string;
    private singleCallBackFcn: Function;
    private multiCallBackFcn: Function;
    private errorCallBackFcn: Function;
    public cleaningType: CleaningType;
    public bookingPayload: any;
    public zipCode : string | number | undefined;
    public isLeadContactApi : boolean = false;
    constructor(units: string='miles', franchiseLimit: number = 50, singleCallBackFcn = () => { }, multiCallBackFcn = () => { }, errorCallBackFcn = () => { }, bookingPayload :any={},zipCode :number | string | undefined=undefined) {
        // Setting default value, with room for updation in future
        this.units = units;
        this.franchiseLimit = franchiseLimit;
        this.countryCode = (document.getElementById("countryCode") as HTMLInputElement)?.value.toLowerCase();
        this.loadHereMapScripts();
        this.singleCallBackFcn = singleCallBackFcn;
        this.multiCallBackFcn = multiCallBackFcn;
        this.errorCallBackFcn = errorCallBackFcn;
        this.cleaningType = new CleaningType();
        this.bookingPayload = bookingPayload;
        this.zipCode = zipCode;
        this.loadBrandDetails();
    }

    loadHereMapScripts() {
        const isScriptLoaded = document.getElementById('mapsjs-core-js');
        if (!isScriptLoaded) {
            const fcn = (url: any, id: any) => {
                const elem = document.createElement("script");
                elem.async = false;
                elem.src = url;
                elem.id = id;
                document.head.appendChild(elem);
            }

            fcn('https://js.api.here.com/v3/3.1/mapsjs-core.js', 'mapsjs-core-js');
            fcn('https://js.api.here.com/v3/3.1/mapsjs-ui.js', 'mapsjs-ui-js');
        }
    }
    convertRange() {
        const factor = 0.621371;
        if (this.units === "km") {
            this.franchiseLimit = Math.round(this.franchiseLimit * factor);
        }
    }
    getAllLocations() {
        const url = apiConfig.WebLocationsAPI;
        return cachedAPIWrapper(url);
    }

    filterData(data: any) {
        let details = data.flatMap((f: any) => f.franchiseDetails);
        if (this.countryCode === 'us') {
            details = details.filter((c: any) => c.country === 'USA' || c.country === 'United States');
        } else {
            details = details.filter((c: any) => c.country === 'CAN' || c.country === 'Canada');
        }
        // Removing duplicates
        let result = [];
        const set = new Set();
        for (let detail of details) {
            if (!set.has(detail.franchiseWebLocationId)) {
                result.push(detail);
                set.add(detail.franchiseWebLocationId);
            }
        }
        return result;
    }
    async searchNearbyLocations(searchQuery: string) {
        const geoCountry = this.countryCode === "us" ? "USA,PRI" : "CAN";
        const apiUrl = `${apiConfig.geoCodeSearchApiUrl}?apiKey=${process.env.JS_Heremap_API_KEY}&q=${searchQuery.trim()}&in=countryCode:${geoCountry}`;
        this.convertRange();

        // Promises to handle asynchronous operations
        return getCachedAPIResponse(apiUrl, apiCaching.HereMapConfig, false)
            .then(data => {
                if (data?.items && data.items.length !== 0) {
                    const position = data.items[0].position;
                    const rangeConvert = this.franchiseLimit;
                    sessionStorage.setItem('distanceTo', JSON.stringify(position));

                    const bbox = this.getBoundingBox(position.lat, position.lng, rangeConvert);
                    const hBBox = new H.geo.Rect(bbox[0], bbox[1], bbox[2], bbox[3]);

                    return this.getAllLocations()
                        .then(response => {
                            const result = this.filterData(response);
                            const filteredData = this.filterResults(hBBox, result, -1);
                            return filteredData;
                        })
                        .catch(err => {
                            console.error("Facing this error when calling Web Locations API", err);
                            return null;
                        });
                } else {
                    console.log("Error: No items found in data.");
                    return null;
                }
            })
            .catch(err => {
                console.error("Facing this error when calling Geo Code API", err);
                return null;
            });
    }


    filterResults(bbox: any, franchiseDetails: any[], pinnedWeblocationId: any) {
        const distanceTo: any = JSON.parse(sessionStorage.getItem('distanceTo') as string);
        let currFranchiseDetails: any = [];
        franchiseDetails.forEach((fr) => {
            if (bbox.containsLatLng(fr.latitude, fr.longitude) || pinnedWeblocationId === fr.franchiseWebLocationId) {
                fr.distance = this.getDistanceFromLatLon(distanceTo.lat, distanceTo.lng, fr.latitude, fr.longitude);
                currFranchiseDetails.push(fr);
            }
        });
        if (currFranchiseDetails.length === 1) {
            console.log("Only one location found within range");
            this.singleCallBackFcn();
            return currFranchiseDetails;
        } else if (currFranchiseDetails.length > 1) {
            console.log("Multiple locations found within range");
            this.multiCallBackFcn();
            return currFranchiseDetails;
        } else {
            console.error(`Could not find a location within ${this.franchiseLimit} ${this.units} range`);
            this.errorCallBackFcn();
            return currFranchiseDetails;
        }

    }

    getDistanceFromLatLon(lat1: any, lon1: any, lat2: any, lon2: any) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI * 60 * 1.1515;
        if (this.units === "km") {
            dist = dist * 1.609344
        }
        return dist;
    }

    getBoundingBox(fsLatitude: any, fsLongitude: any, fiDistanceInKM: any): any[] {

        if (fiDistanceInKM == null || fiDistanceInKM == undefined || fiDistanceInKM == 0)
            fiDistanceInKM = 1;

        let MIN_LAT: any, MAX_LAT: any, MIN_LON: any, MAX_LON: any, ldEarthRadius: any, ldDistanceInRadius: any, lsLatitudeInDegree: any, lsLongitudeInDegree: any,
            lsLatitudeInRadius: any, lsLongitudeInRadius: any, lsMinLatitude: any, lsMaxLatitude: any, lsMinLongitude: any, lsMaxLongitude: any, deltaLon: any;

        // coordinate limits
        MIN_LAT = (-90 as any).degreeToRadius();
        MAX_LAT = (90 as any).degreeToRadius();
        MIN_LON = (-180 as any).degreeToRadius();
        MAX_LON = (180 as any).degreeToRadius();

        // Earth's radius (km)
        ldEarthRadius = 6378.1;

        // angular distance in radians on a great circle
        ldDistanceInRadius = fiDistanceInKM / ldEarthRadius;

        // center point coordinates (deg)
        lsLatitudeInDegree = fsLatitude;
        lsLongitudeInDegree = fsLongitude;

        // center point coordinates (rad)
        lsLatitudeInRadius = lsLatitudeInDegree.degreeToRadius();
        lsLongitudeInRadius = lsLongitudeInDegree.degreeToRadius();

        // minimum and maximum latitudes for given distance
        lsMinLatitude = lsLatitudeInRadius - ldDistanceInRadius;
        lsMaxLatitude = lsLatitudeInRadius + ldDistanceInRadius;

        // minimum and maximum longitudes for given distance
        lsMinLongitude = undefined;
        lsMaxLongitude = undefined;

        // define deltaLon to help determine min and max longitudes
        deltaLon = Math.asin(Math.sin(ldDistanceInRadius) / Math.cos(lsLatitudeInRadius));

        if (lsMinLatitude > MIN_LAT && lsMaxLatitude < MAX_LAT) {
            lsMinLongitude = lsLongitudeInRadius - deltaLon;
            lsMaxLongitude = lsLongitudeInRadius + deltaLon;
            if (lsMinLongitude < MIN_LON) {
                lsMinLongitude = lsMinLongitude + 2 * Math.PI;
            }
            if (lsMaxLongitude > MAX_LON) {
                lsMaxLongitude = lsMaxLongitude - 2 * Math.PI;
            }
        }

        // a pole is within the given distance
        else {
            lsMinLatitude = Math.max(lsMinLatitude, MIN_LAT);
            lsMaxLatitude = Math.min(lsMaxLatitude, MAX_LAT);
            lsMinLongitude = MIN_LON;
            lsMaxLongitude = MAX_LON;
        }

        return [
            lsMinLatitude.radiusToDegree(),
            lsMinLongitude.radiusToDegree(),
            lsMaxLatitude.radiusToDegree(),
            lsMaxLongitude.radiusToDegree()
        ];
    }

    findUnits(area_measure_values: string): string {
        if (area_measure_values.includes('measure_km')) {
            return "km";
        }
        return "miles";
    }

    loadBrandDetails() {
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        getBrandDetailsFromBrandJson(conceptCode)
            .then((brandDetail) => {
                this.franchiseLimit = Number(brandDetail.nearest_franchiselimit_OSflow);
                this.units = this.findUnits(brandDetail.area_measure_values_OSflow);
                this.isLeadContactApi = brandDetail.lead_contactapi;
            })
            .catch((err: any) => {
                console.log("Unable to read brands.json. Using default range 50 miles radius...",err);
            });
    }
}

