export function DomainIdentifier(): string {
    const url = window.location.origin;

    if (isLocalEnvironment(url)) {
        return getLocalDomain();
    } else if (isLowerEnvironment(url)) {
        return getLowerEnvironmentDomain(url);
    } else {
        return getProductionDomain(url);
    }
}

function isLocalEnvironment(url: string): boolean {
    return url.includes('localhost');
}

function getLocalDomain(): string {
    const assetPathElement = document.getElementById("assetPath") as HTMLInputElement;
    if (assetPathElement) {
        const assetPathValue = assetPathElement.value;
        if (assetPathValue.includes("/US/")) {
            return 'us';
        } else if (assetPathValue.includes("/CA/")) {
            return 'ca';
        }
    }
    return "Error validating Location";
}

function isLowerEnvironment(url: string): boolean {
    return url.includes('nblysbx') || url.includes('nblydev') || url.includes('nblytest') || url.includes('nblyprod');
}

function getLowerEnvironmentDomain(url: string): string {
    try {
        if (url.includes('-ca-') || (url.includes('-ca') &&  url.includes('nblyprod'))) {
            return 'ca';
        } else {
            return 'us';
        }
    } catch {
        return "Error validating Location";
    }
}

function getProductionDomain(url: string): string {
    try {
        const lastDotIndex = url.lastIndexOf('.');
        const domainExtension = url.substring(lastDotIndex + 1);
        if (domainExtension === "ca") {
            return 'ca';
        } else {
            return 'us';
        }
    } catch {
        return "Error validating Location";
    }
}