import { getAddressConfirmation } from "../util/discovery";

export class RewardConfirmation {
    private static instance: RewardConfirmation;

    static getInstance(): RewardConfirmation {
        if (!RewardConfirmation.instance) {
            RewardConfirmation.instance = new RewardConfirmation();
        }
        return RewardConfirmation.instance;
    }
    constructor() {
        this.displayConfirmationDetails();
    }

    private displayConfirmationDetails(): void {
        const rewardConfirmationId = document.querySelector('#reward-confirmation');
        const rewardConfirmation = sessionStorage.getItem('rewardConfirmation');
        if (rewardConfirmationId && rewardConfirmation) {
            const parsedRewardConfirmation = JSON.parse(rewardConfirmation);
            const { contactInfo ,specialOffers,comment } = parsedRewardConfirmation;

            this.updateServiceAddress(parsedRewardConfirmation);
            this.updateContactInfo(contactInfo);
            this.updateSpecialOffers(specialOffers);
            this.updateComment(comment);
        }
    }

    private updateServiceAddress(parsedRewardConfirmation: string): void {
        const rewardAddress = document.querySelector('#reward_confirmation_address');
        let finalAddress: string;
        let obj = getAddressConfirmation(parsedRewardConfirmation);
        if (obj.line1 && obj.line2) {
            finalAddress = `${obj.line1} ${obj.line2}`;
        }
        else if (obj.line1) {
            finalAddress = `${obj.line1}`;
        }
        else if (obj.line2) {
            finalAddress = `${obj.line2}`;
        }
        else {
            finalAddress = ``;
        }
        if (finalAddress && rewardAddress) {
            rewardAddress.innerHTML = finalAddress;
        } else {
            rewardAddress?.closest('.appointment-item')?.classList.add('hidden');
        }
    }

    private updateContactInfo(contactInfo: any): void {
        const rewardContact = document.querySelector('#reward_confirmation_contact');
        if(contactInfo && rewardContact){
            rewardContact.innerHTML = contactInfo?.name;

            const rewardContactEmailClone = rewardContact.cloneNode(true) as Element;
            rewardContactEmailClone.innerHTML = contactInfo?.email;

            const rewardContactPhonClone = rewardContact.cloneNode(true) as Element;
            rewardContactPhonClone.innerHTML = contactInfo?.phone;

            if (rewardContact.parentNode) {
                rewardContact.parentNode.appendChild(rewardContactEmailClone);
                rewardContact.parentNode.appendChild(rewardContactPhonClone);
            }

        }else {
            rewardContact?.closest('.appointment-item')?.classList.add('hidden');
        }
    }

    private updateSpecialOffers(specialOffers: string[]): void {
        const rewardSpecialOffers = document.querySelector('#reward_confirmation_offers');
        if(specialOffers?.length > 0 && rewardSpecialOffers){
            const rewardSepcialUlEle = document.createElement('ul');
            let htmlContent = '';

            specialOffers.forEach((offer: string) => {
                htmlContent += `<li class="icon-block-text">${offer}</li>`;
            });
    
            rewardSepcialUlEle.innerHTML = htmlContent;
            rewardSpecialOffers.after?.(rewardSepcialUlEle);
            
        }else{
            rewardSpecialOffers?.closest('.appointment-item')?.classList.add('hidden');
        }
    }

    private updateComment(comment: string): void {
        const rewardComment = document.querySelector('#reward_confirmation_description');
        if(comment && rewardComment){
            rewardComment.innerHTML = comment;
        }else{
            rewardComment?.closest('.appointment-item')?.classList.add('hidden');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    RewardConfirmation.getInstance();
});