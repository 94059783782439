export function localizationTagLine(){
    
    const tagLine = document.querySelector('.header-wrapper .description')as HTMLSpanElement;
    const rawString = document.getElementById('page-settings') as HTMLInputElement;

    if( !rawString) return;
    
    //check if the page is a local page, if yes then replace with the local storage tagline
    const pageScope = rawString.outerHTML.split('"pagescope":"')[1]?.split('"')[0]?.trim();
    
    if(pageScope !== 'national'){
        localizationTagUpadation();
        let replaceText = (tagLine?.textContent === "")? "Locally Owned and Operated" : tagLine?.textContent;
        localStorage.setItem('localTagLine', replaceText ?? "Locally Owned and Operated");
        
    }
    const dbaName = localStorage.getItem('dbaName');
    const existingTagLine = localStorage.getItem('localTagLine');
    //replace the tage line even if the page is national provided the user is localized
    if(dbaName){
        tagLine.textContent = existingTagLine;
    }

}

export function localizationTagUpadation(){
    const tagLine = document.querySelector('.header-wrapper .description')as HTMLSpanElement;
    let replaceText = (tagLine?.textContent === "")? "Locally Owned and Operated" : tagLine?.textContent;
    localStorage.setItem('localTagLine', replaceText ?? "Locally Owned and Operated");
}