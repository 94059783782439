export class BannerManager {
  private readonly banner: HTMLDivElement | null;
  private static instance: BannerManager;
  static getInstance(): BannerManager {
    if (!BannerManager.instance) {
      BannerManager.instance = new BannerManager();
    }
    return BannerManager.instance;
  }
  constructor() {
    this.banner = document.getElementById("header-alert") as HTMLDivElement;
    if(this.banner){
      this.initialize();
    }
  }

  private initialize(): void {
    const element = document.querySelector('.alert-close') as HTMLButtonElement;
    if (element) {
      element.addEventListener('click', this.onClickHandler.bind(this));
    }

    const infoBanner = localStorage.getItem("informationBanner");
    if(infoBanner==="false"){
      this.hideBanner();
    }
    
  }

  private onClickHandler(): void {
    localStorage.setItem("informationBanner", "false");
    this.hideBanner();
  }

  private hideBanner(): void {
    if (this.banner) {
      this.banner.classList.add("hidden");
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  BannerManager.getInstance();
});

