import { PriceQuotePayloadModel } from "./interface/priceQuote/priceQuoteInterface";
import { agnosticCall } from "../../api/apiRequest";
import { startLoader, stopLoader } from "../../util/loader";
import { normalizeKeys, removeNullAndEmpty, storeFailedResponse } from "./helperModules/helperFunctions";
import { priceQuoteDataKeys  } from "./keys/priceQuoteKeys";

//Check if the payload is valid
function isValidPriceQuotePayload(payload: any): payload is PriceQuotePayloadModel {
    let payloadLower = JSON.parse(JSON.stringify(normalizeKeys(payload)));
        payloadLower = JSON.parse(JSON.stringify(removeNullAndEmpty(payloadLower)));

    //checking if all mandatory fields are present
    const mandatoryFields = ['franchiseWebLocationId', 'conceptId'];
    const hasAllMandatoryFields = mandatoryFields.every(field => payloadLower?.hasOwnProperty(normalizeKeys(field)));
    if (!hasAllMandatoryFields) return false;

    //checking if any invalid keys are present
    const hasInvalidKeys = Object.keys(payloadLower).some((key) => {
      if(!priceQuoteDataKeys.hasOwnProperty(key)){
        console.error('Invalid key in price quote Payload:', key);
        return true;
      }
    });
    if (hasInvalidKeys) return false;

    const hasInvalidTypes = Object.entries(payloadLower).some(([key, value]) => {
        if (value === undefined) {
          console.error('Undefined value in price quote Payload:', key);
          return false;
        }
        console.log(typeof value, priceQuoteDataKeys[key],"Value:",value,"Key:",key);
        return typeof value !== priceQuoteDataKeys[key];
    });

    if (hasInvalidTypes) return false;
    return payload;
}

//Calling the priceQuote end point
export async function priceQuoteWrapper(url: string, payload: any, apiKey: string) {
  if (typeof apiKey !== 'string' || !apiKey.trim()) {
    console.error("Invalid API key provided");
    throw new Error("Invalid API key provided");
  }

  const enableStrictValidation = process.env.ENABLE_STRICT_PAYLOAD_VALIDATION?.toLowerCase() === 'true';
  if (enableStrictValidation && !isValidPriceQuotePayload(payload)) {
    console.error("Invalid payload for price quote Endpoint:", payload);
    throw new Error(`Invalid payload: ${JSON.stringify(payload)}`);
  }
  const normalizedPayload = normalizeKeys(payload);
  const request = {
    method: 'POST',
    url,
    data: normalizedPayload,
  };

  try {
    startLoader();
    const response = await agnosticCall(request, apiKey);
    return response;
  } catch (err: any) {
    console.error('Error in priceQuoteWrapper:', err);
    storeFailedResponse(request, err);
    throw new Error(`priceQuoteWrapper failed: ${err.message || err}`);
  } finally {
    stopLoader();
  }
}