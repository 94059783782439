import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { useMemo } from "../util/memoization";
import { fetchCrownpeakUrlDetails } from "../util/share";

interface FreshDataFromPagination {
    LoadMoreOffersJson: string;
    EnableLoadMore: boolean;
    BottomDrawerJson: string;
}

export class OffersLanding {
    private static instance: OffersLanding;
    maxOffersToDisplay: number = 8; // This is the initial max items to display
    paginationOffset = 4; // This is to check how many items to display on click of load more
    featuredItems: any[] = [];
    otherItems: any[] = [];
    skipItems: number = 8;

    freshDataFromPagination: FreshDataFromPagination = {
        LoadMoreOffersJson: "",
        EnableLoadMore: false,
        BottomDrawerJson: ''
    };

    urlForPagination: string = '';
    urlForLocalization: string = '';

    static getInstance(): OffersLanding {
        if (!OffersLanding.instance) {
            OffersLanding.instance = new OffersLanding();
        }
        return OffersLanding.instance;
    }

   async init() {
        let offerJSONURL = '';
        // const fullUrl = window.location.href.split('?')[0];
        const fullUrl = `${window.location.origin}/special-offers`;
        if (!this.checkIfUserIsLocalized()) {
            offerJSONURL = `${fullUrl}.json`;
        } else {
                let localPath = await this.getLocalOffersPath()
                offerJSONURL = localPath ?? `${fullUrl}.json`;
                this.constructURLs();

                const isLocalUserInNational = this.checkIfLocalUserInNational();
                if (isLocalUserInNational)
                    this.reconstructHTML(offerJSONURL);
                else {
                    this.getRawJSONObject(offerJSONURL)
                }
                this.attachEventListnerForSeeDetails();
                this.attachPrintEvntListner();
                this.attachCloseDrawerListener();
            
        }
        this.constructURLs();

        const isLocalUserInNational = this.checkIfLocalUserInNational();
        if (isLocalUserInNational)
            this.reconstructHTML(offerJSONURL);
        else {
            this.getRawJSONObject(offerJSONURL)
        }
        this.attachEventListnerForSeeDetails();
        this.attachPrintEvntListner();
        this.attachCloseDrawerListener();
        document.body.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;

            // Check if the target is a "Claim Offer" button (secondary-btn)
            if (target?.classList?.contains('secondary-btn')) {
                // Ensure the "Claim Offer" button is inside the correct .offers-drawer-cta container
                const offersDrawerCTA = target.closest('.offers-drawer-cta');
                const offersItem = target.closest('.sp-offers-item');
                if (offersDrawerCTA) {
                    this.triggerClaimOfferClick(event);
                }
                else if(offersItem) {
                    const url = target.getAttribute('href');
                    const targetAttr = (target as HTMLAnchorElement).target;
                    if (targetAttr === '_blank' && url) {
                        //open the link in a new tab
                        window.open(url, '_blank');
                    } else if (url) {
                        //open in the same tab
                        window.location.href = url;
                    }
                }
            }
        });
      
        document.body.addEventListener('click', function(event) {
            const link = (event.target as Element)?.closest('.sp-offers-disclaimer a');
            const moblink = (event.target as Element)?.closest('.offer-drawer-data a');     
    
            if (link|| moblink ) {
                event.preventDefault();
                const anchor = link || moblink;
                const target = anchor?.getAttribute('target');
                const url = anchor?.getAttribute('href');
                if (url) {
                    if (target === '_blank') {
                        window.open(url, target); // Open in new tab
                    } else {
                        window.location.href = url; // Open in the same tab
                    }
                }
            }
        });
    

    }
    triggerClaimOfferClick(event: Event) {
        // Ensure the target is a valid 'Claim Offer' link
        const claimOfferLink = (event.target as HTMLElement).closest('.secondary-btn') as HTMLAnchorElement;
        if (!claimOfferLink?.href) return;  // If the href is empty, exit
        if (!claimOfferLink?.href?.trim()) {
            return;  // Do nothing if the href is empty or just whitespace
        }
        // Get the closest modal
        const modal = (event.target as HTMLElement).closest('.sp-offers-drawer') as HTMLElement;
        if (!modal) return;  // Ensure we have the modal
        // Close the modal by setting display to none
        modal.style.display = 'none';

        // Remove the backdrop if it's present
        const backdrops = document.querySelectorAll('[drawer-backdrop]');
        const body = document.querySelector('body');
        if (body?.classList?.contains('overflow-hidden')) {
            body.classList.remove('overflow-hidden');
        }
        backdrops.forEach(backdrop => backdrop.remove());
        setTimeout(() => {
            window.location.href = claimOfferLink.href;
        }, 0);

    }
    // Event delegation for dynamically added "See Details" buttons
    attachEventListnerForSeeDetails() {
        const offersSection = document.querySelector('.sp-offers-section');
        if (!offersSection) return;

        offersSection.addEventListener('click', (e: Event) => {
            const target = e.target as HTMLElement;

            // Check if the clicked element is the 'See Details' button
            if (target && target.classList.contains('primary-btn') && target.getAttribute('data-drawer-target')) {
                this.openDrawer(target);
            }
        });
    }

    // Function to open the drawer
    openDrawer(button: HTMLElement) {
        // Get the drawer ID from the button's data-drawer-target attribute
        const drawerId = button.getAttribute('data-drawer-target');
        if (drawerId) { // Check if drawerId is not null
            const drawer = document.getElementById(drawerId ?? '');

            if (drawer) {
                // Add a class to trigger the transition and show the drawer
                drawer.classList.remove('translate-y-full');  // Assuming this class hides the drawer initially
                drawer.classList.add('translate-y-0');
                drawer.style.display = 'block'; // Make the drawer visible
                this.showDrawerWithBackdrop(drawerId); // Assuming this class makes the drawer visible
            }
        }
    }

    async getRawJSONObject(offerJSONURL: string) {
        try {
            // Helper function to fetch and process response
            const fetchAndProcessResponse = async (url: string) => {
                const response = await getCachedAPIResponse(this.transformURL(url), apiCaching.OffersLanding);
                if (!response || typeof response !== 'object') throw new Error("Invalid response data");

                const filteredArray = useMemo(() => this.splitOffersIntoFeaturedAndOther(response?.coupondetails));
                if (!filteredArray) return;

                this.featuredItems = filteredArray.featuredItems;
                this.otherItems = filteredArray.otherItems;

                if (this.otherItems.length > this.maxOffersToDisplay) {
                    this.showLoadMoreButton(true);
                    this.handleBackgroundCalls();
                }
                else {
                    this.showLoadMoreButton(false);
                }
                this.attachEventListnerForClaim();
                this.readmoreFunctionality();
                this.attachPrintEvntListner();
            };

            // Try the main URL first, fall back to secondary URL if needed
            try {
                await fetchAndProcessResponse(offerJSONURL);
            } catch (error) {
                if (!this.checkIfLocalUserInNational()) throw error;

                // Fallback to secondary URL if local user is national
                const finalPathName = ((/^\/?(.*?)(\/?)$/.exec(window.location.pathname) ?? [])[1]?.split('/').join('^')) ?? '';
                const fallbackURL = `${window.location.origin}/special-offers.json`;
                this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/none`;
                await fetchAndProcessResponse(fallbackURL);
            }
        } catch (error) {
            console.error(error + " Failed to load the offers JSON from CP");
        }
    }


    reconstructHTML(offerJSONURL: string) {
        const DOMToReplace = document.querySelector(".sp-offers-section") as HTMLElement;
        if (!DOMToReplace)
            return
        DOMToReplace.innerHTML = "";
        getCachedAPIResponse(this.transformURL(this.urlForLocalization), apiCaching.OffersLanding).then((response) => {
            if (!response?.SpecialOfferMarkup || typeof response !== 'object') {
                throw new Error("Invalid response data");
            }
            DOMToReplace.innerHTML = response.SpecialOfferMarkup;
            this.getRawJSONObject(offerJSONURL);
            const loadMoreButton = this.getLoadMoreButton();
            if (!loadMoreButton) return;
            loadMoreButton.addEventListener("click", this.handleLoadMoreClick);
            this.attachEventListnerForClaim();
            this.readmoreFunctionality();
            this.attachPrintEvntListner();
        }).catch((error) => {
            console.error(error + " Failed to load localized HTML from URL Transformer");
        });
    }

    transformURL(URL: string) {
        if (URL.includes("localhost")) {
            URL = URL.replace("localhost:7032", "dig-www-nei-asv2-test.nblydev.com")
        }
        URL = URL.replace("#", "");
        URL = URL.replace("/.json", ".json");
        return URL;
    }

    checkIfLocalUserInNational() {
        const isUserLocallized = this.checkIfUserIsLocalized();
        if (!isUserLocallized)
            return false
        const currentUrl = window.location.href;
        const dbaName = localStorage.getItem('dbaName')
        if (dbaName) {
            if (!currentUrl.includes(dbaName))
                return true
        }
        return false;
    }


    constructURLs() {
        const dbaName = localStorage.getItem('dbaName');
        const finalPathName = ((/^\/?(.*?)(\/?)$/.exec(window.location.pathname) ?? [])[1]?.split('/').join('^')) ?? '';
        if (dbaName) {
            this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/${dbaName}`;
            this.urlForLocalization = `${window.location.origin}/specialofferdata/${finalPathName}/${dbaName}`;
            
            let weblocationInfo = window.crownpeakWeblocationsByIdDetail;
            if (!weblocationInfo) {
                console.error("Error while getting weblocationInfo");
                fetchCrownpeakUrlDetails().then(() => {
                    weblocationInfo = window.crownpeakWeblocationsByIdDetail;
                    if(weblocationInfo){
                        this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/${dbaName}/${weblocationInfo?.stateName}`;
                        this.urlForLocalization = `${window.location.origin}/specialofferdata/${finalPathName}/${dbaName}/${weblocationInfo?.stateName}`;
                    }
                }).catch(() => {
                    console.error("Error while getting weblocationInfo");
                });
            }else{
                this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/${dbaName}/${weblocationInfo?.stateName}`;
                this.urlForLocalization = `${window.location.origin}/specialofferdata/${finalPathName}/${dbaName}/${weblocationInfo?.stateName}`;
            }

        } else {
            this.urlForPagination = `${window.location.origin}/specialofferloadmore/${this.skipItems}/${finalPathName}/none`;
            this.urlForLocalization = `${window.location.origin}/specialofferdata/${finalPathName}/none`;
        }
    }

    async getLocalOffersPath() {
        const dbaName = localStorage.getItem('dbaName');
        if (dbaName) {
            const brandData = localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null;
            const { weblocation_path_group: hostMapWebLocation , include_statename } = brandData ?? {};
            const finalPathName = 'special-offers';
            if (hostMapWebLocation && include_statename) {
                let weblocationInfo = window.crownpeakWeblocationsByIdDetail;
                if (!weblocationInfo) {
                    console.error("Error while getting weblocationInfo");
                    await fetchCrownpeakUrlDetails();
                    weblocationInfo = window.crownpeakWeblocationsByIdDetail;
                }
                return `${window.location.origin}/${hostMapWebLocation}/${weblocationInfo?.stateName}/${dbaName}/${finalPathName}.json`;
            } else if (!hostMapWebLocation && include_statename) {
                let weblocationInfo = window.crownpeakWeblocationsByIdDetail;
                if (!weblocationInfo) {
                    console.error("Error while getting weblocationInfo");
                    await fetchCrownpeakUrlDetails();
                    weblocationInfo = window.crownpeakWeblocationsByIdDetail;
                }
                return `${window.location.origin}/${weblocationInfo?.stateName}/${dbaName}/${finalPathName}.json`;
            } 
            else if (hostMapWebLocation) {
                return `${window.location.origin}/${hostMapWebLocation}/${dbaName}/${finalPathName}.json`;
            }
            return `${window.location.origin}/${dbaName}/${finalPathName}.json`;
        }
        return null;
    }

    splitOffersIntoFeaturedAndOther(res: any) {
        if (!res) return;
        this.featuredItems = [];
        this.otherItems = [];
        res.forEach((item: any) => {
            if (item.enableFeaturedOffers) {
                // If enableFeaturedOffers is true, push to featuredItems
                this.featuredItems.push(item);
            } else {
                // If enableFeaturedOffers is false, push to otherItems
                this.otherItems.push(item);
            }
        });
        return {
            featuredItems: this.featuredItems,
            otherItems: this.otherItems
        };
    }

    private showLoadMoreButton(display: boolean) {
        const loadMoreButton = this.getLoadMoreButton();
        if (!loadMoreButton) return;

        if (display) {
            loadMoreButton.classList.remove("hidden");
            loadMoreButton.addEventListener("click", this.handleLoadMoreClick);
        } else {
            loadMoreButton.classList.add("hidden");
            loadMoreButton.removeEventListener("click", this.handleLoadMoreClick);
        }
    }

    private getLoadMoreButton(): HTMLElement | null {
        const moreOffers = document.querySelectorAll(".sp-offers-section .sp-offers-wrap")[1] as HTMLElement;
        return moreOffers?.querySelector(".cta-wrapper");
    }

    checkIfUserIsLocalized(): boolean {
        return !!localStorage.getItem('dbaName');
    }

    handleLoadMoreClick = (e: Event) => {
        e.preventDefault();
        this.displayMoreItems();
    };

    handleBackgroundCalls() {
        getCachedAPIResponse(this.urlForPagination, apiCaching.OffersLanding).then((response) => {
            if (!response || typeof response !== 'object') {
                throw new Error("Invalid response data / Failed to load new offers from Backend");
            }
            this.skipItems += this.paginationOffset;
            this.freshDataFromPagination = response;
            this.constructURLs();
        }).catch((error) => {
            console.error(error + " Failed to load new offers from Backend");
        });
    }

    private displayMoreItems() {
        const moreOffersElement = document.querySelectorAll(".sp-offers-section .sp-offers-wrap")[1] as HTMLElement;
        if (!moreOffersElement) return;

        const moreOffersListElement = moreOffersElement.querySelector(".sp-offers-list.card-tile");
        if (!moreOffersListElement) return;

        if (this.freshDataFromPagination) {
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = this.freshDataFromPagination.LoadMoreOffersJson;

            const fragment = document.createDocumentFragment();
            const liItems = tempContainer.querySelectorAll('li');
            liItems.forEach(li => fragment.appendChild(li));

            moreOffersListElement.appendChild(fragment);
        }
        if (this.freshDataFromPagination.EnableLoadMore) {
            this.showLoadMoreButton(true);
            this.handleBackgroundCalls();
        } else {
            this.showLoadMoreButton(false);
        }
        let bottomDrawerHtml = this.freshDataFromPagination.BottomDrawerJson;

        // Get the target div where you want to append the content
        const container = document.querySelector('.sp-offers-section .container');
        // Append the content to the target div
        container?.insertAdjacentHTML('beforeend', bottomDrawerHtml);
        // Attach event listener for the close button after appending the drawer HTML
        this.attachCloseDrawerListener();
        this.attachPrintEvntListner();
    }

    // Function to attach the close drawer event listener
    attachCloseDrawerListener() {
        // Select the container that contains the drawer
        const container = document.querySelector('.sp-offers-section .container');

        if (container) {
            // Listen for click events on the entire container
            container.addEventListener('click', (e: Event) => {
                const target = e.target as HTMLElement;

                // Check if the clicked element or any of its parents is a close button
                let closeButton = target.closest('.sp-offers-drawer-close');

                if (closeButton) {
                    const drawerId = closeButton.getAttribute('data-drawer-hide');
                    const drawer = document.getElementById(drawerId ?? '');

                    if (drawer) {
                        // Remove the drawer's translate effect to hide it
                        drawer.classList.remove('translate-y-0');
                        drawer.classList.add('translate-y-full'); // Assuming this hides the drawer
                        drawer.style.display = 'none';
                        // Remove the backdrop if it's present
                        const backdrop = document.querySelector('.bg-gray-900');
                        if (backdrop) {
                            backdrop.remove();
                        }
                    }
                }

                // Optional: Close drawer when clicking on the backdrop
                const backdrop = target.closest('[drawer-backdrop]');
                if (backdrop) {
                    // Close the drawer and remove the backdrop
                    const drawer = document.querySelector('.sp-offers-drawer.translate-y-0');
                    if (drawer) {
                        drawer.classList.remove('translate-y-0');
                        drawer.classList.add('translate-y-full');
                        backdrop.remove(); // Remove the backdrop
                    }
                }
            });
        }
    }

    // Show the backdrop when the drawer is opened
    showDrawerWithBackdrop(drawerId: string) {
        const drawer = document.getElementById(drawerId);
        if (drawer) {
            // Show the drawer with translate effect
            drawer.classList.remove('translate-y-full');
            drawer.classList.add('translate-y-0');

            // Create and append the backdrop
            const backdrop = document.createElement('div');
            backdrop.setAttribute('drawer-backdrop', '');
            backdrop.classList.add('bg-gray-900', 'bg-opacity-50', 'dark:bg-opacity-80', 'fixed', 'inset-0', 'z-30');

            // Append the backdrop to the body or a specific container
            document.body.appendChild(backdrop);
        }
    }


    attachEventListnerForClaim() {
        const mainContainer = document.querySelector(".sp-offers-section .container") as HTMLElement;
        if (!mainContainer)
            return;
        mainContainer.addEventListener("click", this.handleClaimClick);
    }

    isAnchorWithClass = (target: HTMLElement, className: string): boolean => {
        return target.tagName.toLowerCase() === 'a' && target.classList.contains(className);
    };

    getLastDigitFromId = (id: string): { digits: string | null, baseId: string | null } => {
        const regex = /(tns1-item|moreoffer-item|featured-offer-item)(\d+)$/;
        const match = regex.exec(id);
        if (match) {
            return {
                digits: match[2],
                baseId: match[1]
            };
        }
        return {
            digits: null,
            baseId: null
        };
    };

    getSiblingSpanValue = (targetVal: any): string | null => {
        let baseId = ""
        switch (targetVal?.baseId) {
            case 'moreoffer-item':
                baseId = 'nblyMoreOfferCpnCode';
                break;
            case 'featured-offer-item':
                baseId = 'nblyFeaturedOfferCpnCode';
                break;
            case 'tns1-item':
                baseId = 'nblyCpnCode';
                break;
            default:
                baseId = 'nblyCpnCode';
                break;
        }
        const newId = `${baseId}${targetVal?.digits}`;
        const siblingSpan = document.getElementById(newId) as HTMLSpanElement;
        return siblingSpan?.textContent ?? null;
    };

    handleClaimClick = (e: Event) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        const parentDrawerPopUp = target.closest('.offers-drawer-cta');
        if (this.isAnchorWithClass(target, 'claim-offer') || (target.classList.contains('secondary-btn')&& parentDrawerPopUp)) {
            const href = (target as HTMLAnchorElement).href;
            const parentDom = target.closest('li') as HTMLElement;
            if (parentDom) {
                this.setOffersInSession(parentDom, href);
            }else if(parentDrawerPopUp) {
                this.handleOffersDrawer(target, href);
            }
        }
    };

    handleOffersDrawer = (target: HTMLElement, href: string) => {
        const offersDiv = document.querySelector("div.sp-offers-drawer[style*='display: block']");
        if (!offersDiv) return;
    
        const offersdivId = offersDiv.id;
        const parts = offersdivId?.split('-');
        if (parts && parts.length > 5) {
            const keyword = parts[4];
            const number = parts.pop();
            const liElement = document.querySelector(`li[id*='${keyword}'][id*='${number}']`) as HTMLElement;
            if (liElement) {
                this.setOffersInSession(liElement, href);
            } else {
                console.log("No matching li");
            }
        }
    };
    setOffersInSession(targetElement: HTMLElement, href: any) {
        const obj = {
            'title': '',
            'description': '',
            'couponexpirydate': '',
            'longdescription': '',
            'couponcode': ''
        }
        const parentId = targetElement.id;
        if (parentId) {
            const lastDigit = this.getLastDigitFromId(parentId);
            const spanValue = this.getSiblingSpanValue(lastDigit ?? '0');
            if (spanValue) {
                obj.couponcode = spanValue;
            } else {
                console.log("No Coupon code found");
            }
        } else {
            console.log("Parent <li> id not found");
        }
        obj.title = targetElement.querySelector('.sp-offers-details .sp-offer')?.textContent ?? '';
        obj.description = targetElement.querySelector('.sp-offers-details .sp-offer-desc')?.textContent ?? '';
        obj.couponexpirydate = targetElement.querySelector('.expire-mobile')?.textContent ?? '';
        let firstTxt;
        const longDesrptn = targetElement.querySelector('.sp-offers-disclaimer');
        if (longDesrptn) {
            const clonedElement = longDesrptn.cloneNode(true) as HTMLElement;
            // remove read-btn and dots
            const readBtn = clonedElement.querySelector('.read-btn');
            const dots = clonedElement.querySelector('.dots');

            if (readBtn) {
                readBtn.remove();
            }
            if (dots) {
                dots.remove();
            }
            // text content 
            firstTxt = clonedElement.textContent?.trim();
        }
        const formatedText = firstTxt?.replace(/[\n\t\r]/g, ' ').replace(/\s{2,}/g, ' ');
        obj.longdescription = `${formatedText}`;
        sessionStorage.setItem('offerDetails', JSON.stringify(obj));
    }

    readmoreFunctionality() {
        const paragraphs = document.querySelectorAll(".text-more");
        if (!paragraphs)
            return;
        paragraphs.forEach(function (paragraph) {
            const button = paragraph.querySelector("button");
            if (!button)
                return;
            if (button.dataset.listenerAdded) {
                return; //dont add if already added
            }
            button.dataset.listenerAdded = 'true';
            button.addEventListener("click", function () {
                const dots = paragraph.querySelector(".dots") as HTMLElement;
                const moreContent = paragraph.querySelector(".more-content") as HTMLElement;
                if (!dots || !moreContent)
                    return;
                if (moreContent.style.display === "none") {
                    moreContent.style.display = "inline";
                    dots.style.display = "none";
                    button.innerHTML = "Read Less";
                } else {
                    moreContent.style.display = "none";
                    dots.style.display = "inline";
                    button.innerHTML = "Read More";
                }
            });
        });
    }

    attachPrintEvntListner() {
        const printOfferLinks = document.querySelectorAll('.print-offer-link .print-offer-link-wrap');
        printOfferLinks?.forEach(link => {
            link.addEventListener('click', this.handlePrintClick.bind(this));
            const element = link as HTMLElement;
            if (element.dataset.listenerAdded) return;
            element.dataset.listenerAdded = 'true';
        });

        const offersDrawer = document.querySelector('.sp-offers-section');

        offersDrawer?.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;

            // Check if the clicked element is a print button inside the offers drawer
            if (target?.classList?.contains('white-outline-btn')) {
                this.handlePrintClick(e);
            }
        });
    }
    handlePrintClick = (e: Event): void => {
        e.preventDefault();
        console.log("clicked");

        const target = e.target as HTMLElement;
        const drawer = target.closest('[id^="sp-offers-bottom-drawer-feature"], [id^="sp-offers-bottom-drawer-more"]') as HTMLElement;
        const li = target.closest('li') as HTMLElement;

        if (drawer) {
            // Handle print for the drawer modal
            this.printContent(drawer, 'drawer');
        } else if (li) {
            // Handle print for the list item
            this.printContent(li, 'listItem');
        }
    };

    printContent(element: HTMLElement, type: 'drawer' | 'listItem'): void {
        // Clone the content to avoid altering the original page
        const clonedElement = element.cloneNode(true) as HTMLElement;

        // Remove unnecessary parts based on the type (drawer or listItem)
        if (type === 'drawer') {
            const printButton = clonedElement.querySelector('.white-outline-btn') as HTMLElement;
            printButton?.remove();
        } else if (type === 'listItem') {
            const disclaimer = clonedElement.querySelector('.sp-offers-disclaimer') as HTMLElement;
            disclaimer?.querySelectorAll('.dots, .more-content, .read-btn').forEach(el => el.remove());
        }
        // Apply iframe-based printing only for 'drawer' type
        if (type === 'drawer') {
            // Create an iframe element
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            const iframeDoc = iframe.contentWindow?.document;

            if (iframeDoc) {
                iframeDoc.open();
                iframeDoc.write('<html><head>');
                iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/service.css" media="all">');
                iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/main.css" media="all">');
                iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/brand-config.css" media="all">');
                iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/header.css" media="all">');
                iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/footer.css" media="all">');
                iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/glossary.css" media="all">');
                iframeDoc.write(`<style>
            @page {
                margin:0mm;
            }
            * {
                -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
                color-adjust: exact !important;                 /* Firefox */
            }
            .claim-offer {
                display: none;
            }
            .sp-offers-drawer {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
            }
            .sp-offers-section .sp-offers-drawer .offers-drawer-main {
                    background-color: var(--primary-50);
                    border-top-left-radius: .5rem;
                    border-top-right-radius: .5rem;
                    padding-bottom: 1rem;
                    padding-top: 1rem;
                    position: relative;
            }
            .brand-logo {
                display:none;
            }
            @media print { 
                @-moz-document url-prefix() {
                    .btn-group li {
                        display: none !important;
                    }
                    .btn-group li:first-child {
                        display: block !important;
                    }
                }
            }
        </style>`);
                iframeDoc.write('</head>');
                iframeDoc.write('<body>');

                // Write the content into the iframe for 'drawer' type
                iframeDoc.write(`
            <section class="sp-offers-section">
                <div class="sp-offers-drawer">
                <div class="offers-drawer-main">
                    <div class="offers-drawer-data">
                        <div class="offers-drawer-details">${clonedElement.querySelector('.offers-drawer-details')?.innerHTML || ''}</div>
                    </div>
                    </div>
                </div>
            </section>
        `);
                iframeDoc.write('</body></html>');
                iframeDoc.close();

                // Wait for the iframe content to load before printing
                iframe.onload = function () {
                    try {
                        iframe.contentWindow?.focus();
                        iframe.contentWindow?.print();
                    } catch (error) {
                        console.log("Error printing content", error);
                    }
                };

                // Additional error handling for iframe loading issues
                iframe.onerror = function (errorEvent) {
                    console.log("Error opening iframe:", errorEvent);
                };
            } else {
                alert('Printing is blocked by the browser. Please allow pop-ups for this site to print the offer.');
            }
        } else {
            // Open the print window
            const printWindow = window.open('', '', `width=${screen.width},height=${screen.height}`);
            if (printWindow) {
                // Common custom styles for both types
                const customStyles = `
                .print-offer-link, .sp-offers-section .sp-offers-wrap .claim-offer, .read-btn {
                    visibility: hidden !important;
                }
                .more-content {
                    display: block !important;
                }
                .offers-drawer-actions, .offers-drawer-header, .drawer-bar, .sp-offers-drawer-close {
                    display: none !important;
                }
                .offers-drawer-data, .offers-drawer-heading, .offer-drawer-desc, .offer-drawer-expire {
                    font-weight: bold;
                    font-size: 16px;
                }
                .offers-drawer-data {
                    margin-top: 20px;
                    font-size: 14px;
                } 
                section {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
            }
            .offers-drawer-data {
                box-sizing: border-box;
            }
            .brand-logo {
                display:none;
            }
            `;

                // Write the content to the print window
                printWindow.document.write(`
                <html>
                    <head>
                        <title>Print</title>
                        <style>${customStyles}</style>
                        <link rel="stylesheet" href="/brand/_assets/css/service.css">
                        <link rel="stylesheet" href="/brand/_assets/css/main.css">
                        <link rel="stylesheet" href="/brand/_assets/css/brand-config.css">
                        <link rel="stylesheet" href="/brand/_assets/css/header.css">
                        <link rel="stylesheet" href="/brand/_assets/css/footer.css">
                        <link rel="stylesheet" href="/brand/_assets/css/glossary.css">
                    </head>
                    <body>
                       
                        <section class="sp-offers-section">
                            <div class="sp-offers-wrap">
                                <ul class="${(element.closest('ul') as HTMLElement)?.className || ''}">
                                    <li>${clonedElement.innerHTML}</li>
                                </ul>
                            </div>
                        </section>
                        
                    </body>
                </html>
            `);

                printWindow.document.close();
                printWindow.addEventListener('load', () => {
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                });
            } else {
                console.error("Failed to open print window.");
            }
        }
    }

}



document.addEventListener("DOMContentLoaded", () => {
    const specialOffers = document.querySelector(".sp-offers-section");
    if (specialOffers) {
        const instance = OffersLanding.getInstance();
        instance?.init();
    }
});
