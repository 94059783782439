export const insuranceErrorMessage = {
    "firstNameErrorMsg":"Please enter a valid first name.",
    "lastNameErrorMsg":"Please enter a valid last name.",
    "zipCodeErrorMsg": "Please enter a valid zip code.",
    "emailErrorMsg": "Please enter a valid email.",
    "serviceAddress": "Please enter a valid address.",
    "insuranceDateOfLossErrorMsg":"Please enter a valid date.",
    "insuranceDeductibleErrorMsg":"Please enter a valid deductible amount.",
    "insuranceClaimNumberErrorMsg":"Please enter a valid claim number.",
    "insuranceCauseLossErrorMsg":"Please enter a valid cause of loss.",
    "insurancePolicyNumberErrorMsg":"Please enter a valid policy number.",
    "insuranceCompanyNameErrorMsg":"Please enter a valid company name.",
    "insuranceCarMakenErrorMsg":"Please enter a valid car make.",
    "insuranceCarModelErrorMsg":"Please enter a valid car model.",
    "insuranceCarYearErrorMsg":"Please enter a valid car year.",
    "insuranceCarSyleErrorMsg":"Please enter a valid car style.",
    "insuranceAgentNameErrorMsg":"Please enter a valid agent name.",
    "insuranceAgentPhoneErrorMsg":"Please enter a valid phone number.",
    "insuranceAgentEmailErrorMsg":"Please enter a valid email.",
    "insuranceAgencyNameErrorMsg":"Please enter a valid agency name.",
    "insuranceCommentsErrorMsg":"Please enter a valid comment.",
    

}