import { apiConfig } from "../config/apiConfig";
import { getBrandDetailsFromBrandJson, getFooterLink, getWeblocationPathGroupWithSiteConfig, modifyApplyLocalHref } from "../util/share";
import { apiCaching } from "../config/cachingConfig";
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { showAddressForGoogle } from "../util/addressOnLeadFlow";
import { handleSocialIcon } from "../util/getDynamicMenu";

type services = {
    link: string,
    name: string
}

type address = {
    locationDoingBusinessAs: string,
    address: string,
    address2: string,
    state: string,
    country: string,
    city: string,
    zip: string,
    displayAddressSwitch: boolean,
    stateLicenseNumbers: any

}
export class Footer {
    constructor() {
        if (localStorage.getItem('localPhoneNumber') || localStorage.getItem("franchiseWebLocationId")) {
            this.getFooterData();
        } else {
            const phoneNumb = document.querySelector('.fb-des-para > .fb-tel') as HTMLAnchorElement;
            this.setMobileNumber(phoneNumb?.innerText);
            handleSocialIcon();
        }
        const headerLinks: any = document.querySelectorAll('.dynamic-service-menu')
        if (headerLinks.length > 0) {
            let data: any = []
            headerLinks.forEach((item: any) => {
                data.push({
                    name: item?.querySelector('a span').textContent.trim(),
                    link: item?.querySelector('a').href

                })
            })
            this.setServices(data)
        }else {
            let serviceData:any = getFooterLink();
            this.setServices(serviceData);
        }

        this.setYear()
        const conceptCode:string = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        if(conceptCode === 'MRE'){
        setTimeout(() => {
            modifyApplyLocalHref('.brand-footer .footer-block .fb-wrapper-link .fb-link-block ul.footer-submenu li.submenu-li a[title="Apply Locally - opens in a new tab"]')
        }, 0);
       }
    }

    async setYear() {
        const weblocation_path_group_url: string = await getWeblocationPathGroupWithSiteConfig();
        try{
            if(weblocation_path_group_url){
                const resp = await getCachedAPIResponse(weblocation_path_group_url, apiCaching.SiteConfig, false);
                const { token_details } = resp;
                try {
                    let elementsWithPlaceholder = document.body.querySelectorAll("*");
                    token_details?.forEach((token: any) => {
                        elementsWithPlaceholder.forEach(function (element: any) {
                            let key = token.key;
                            // Use a regular expression to replace placeholders
                            if (element.innerHTML && element.innerHTML.includes(`{${key}}`)) {
                                const regex = new RegExp(`\\{${key}\\}`, 'g');
                                element.innerHTML = element.innerHTML.replace(regex, token.value);
                            }
                        });
                    });
                } catch (error) {
                    throw new Error (error as string);
                }
            }
            
        }
        catch (err){
            console.error("Error in fetching site config and setting footer Year", err);
        }
    }
    
    public async getLocalSocialIcon() {
        const urlSiteConfig = await getWeblocationPathGroupWithSiteConfig();
        try{
            if(urlSiteConfig){
                const cachedResponse = await getCachedAPIResponse(urlSiteConfig, apiCaching.SiteConfig, false);
                if (cachedResponse) {
                    return cachedResponse.social_links
                }
            }
            
        }
        catch{
            console.error("Error in fetching site config");
            return {}
        }
    }

   
    public getFooterData(): void {
        try {
            const request = `${apiConfig.FOOTER_BRAND_LINK}${localStorage.getItem("franchiseWebLocationId")}`;
            cachedAPIWrapper(request)
                .then((resp: any) => {
                    this.appendSocialLinks(resp?.franchiseWebLocationLinks);
                    this.changeFooterAddress(resp);
                    this.fetchCesUrl();
                    let callTrack:any
                    resp?.callTrackings.forEach((type:any)=>{
                        if(type.callTrackingTypeDescription === "WebsiteTracking"){
                            callTrack = type.callTrackingNumber;
                        }
                    });
                    if(!callTrack){
                        callTrack = resp?.mainPhoneNumber;
                    }
                    this.setMobileNumber(callTrack);
                })
                .catch((error) => {
                    console.error('Error fetching FOOTER_BRAND_LINK:', error);
                });
        } catch (error) {
            console.error('Error fetching FOOTER_BRAND_LINK:', error);
        }
    }
    public formSocialLink(filteredNationalSocialicon: any[]){
        const tempArr: any[] = []
         filteredNationalSocialicon?.forEach(item=>{
           let obj :any = {};
           obj.linkTypeDescription = item.label;
           obj.description = item.hreflink;
           obj.svg = item.iconlink;
           tempArr.push(obj)
         })
         return tempArr
    }
    
    public async appendSocialLinks(links:  { linkTypeDescription: string; }[]): Promise<void> {
        const socialIcons = document.querySelector('.fb-social-block')
        const replaceLocal = await this.getLocalSocialIcon() ;
       
        const filteredNationalSocialicon = replaceLocal?.filter((obj1 : any) => !links?.some(obj2 => obj1.label.toLowerCase() === obj2.linkTypeDescription?.toLowerCase()));
        const filterdArray = this.formSocialLink(filteredNationalSocialicon);

        if(filterdArray && filterdArray.length > 0) {
            links.push(...filterdArray);
        }

        while (socialIcons?.firstChild) {
            socialIcons.removeChild(socialIcons.firstChild);
        }
        const iconList:any[] = [];
        links?.filter((item: any) => {
            const yelpRemove = item?.linkTypeDescription?.toLowerCase();
            const description = item?.description ;
            const label = item?.linkTypeDescription;
            const svg = (item?.svg)??`<use xlink:href="/_assets-global/images/social-icon-sprite.svg#${label.toLowerCase()}" />`;
            if(yelpRemove != 'yelp'){
               iconList.push(
                `<li class="fb-social-li">
                    <a href="${description}" target="_blank" rel="noopener" class="fb-social-link" aria-label="${label}">
                    <svg class="social-icon">
                    ${svg}
                    </svg>
                    </a>
                    </li>`
               )   
            }
               
        });

        socialIcons?.insertAdjacentHTML('beforeend', iconList.join(''));
        handleSocialIcon();

    }

    public changeFooterAddress(data: address) {
        const { locationDoingBusinessAs, address, address2, state, country, city, zip, displayAddressSwitch, stateLicenseNumbers } = data;
        let licenseNumber:any;
        if(stateLicenseNumbers?.length > 1){
            licenseNumber = this.getLicenseNumber(stateLicenseNumbers)
        }else {
            licenseNumber = stateLicenseNumbers ? stateLicenseNumbers[0] ? stateLicenseNumbers[0] : '' : ''
        }
        const addressContainer: any = document.querySelector('.fb-des-block-para');
        const ft = document.querySelectorAll('.local-address-footer')
        if (ft) {
            ft.forEach((x: any) => x.remove())
        }
        if(licenseNumber != ''){
            licenseNumber = licenseNumber[0] === '#' ? licenseNumber : '#'+licenseNumber;
        }
        let fullAddress = ''
        if (city && state && zip) {
            if(!displayAddressSwitch)
                fullAddress = `${city}, ${state}, ${zip}`;
            else
                fullAddress = `${address}${address2 ? ', '+ address2 : ''}, ${city}, ${state}, ${zip}`;
            showAddressForGoogle(fullAddress);
        }
        const addresData = `<div  class="local-address-footer"> 
                                    <p>${locationDoingBusinessAs}</p>
                                    ${displayAddressSwitch ? `
                                        <address class="address-line">
                                        <span>${address}${address2 ? ', ' + address2 : ''} </span>
                                        <span>${city}, ${state}, ${zip}</span>
                                       ${licenseNumber ? `<span>License: ${licenseNumber}</span>` : ''}
                                    </address>`: `
                                    <address class="address-line">
                                    <span>${city}, ${state}, ${zip}</span>
                                    ${licenseNumber ? `<span>License: ${licenseNumber}</span>` : ''}
                                </address>
                                    ` }
                                   
                                </div>`;
        if(addressContainer){
            addressContainer.insertAdjacentHTML('beforeend', addresData);
        }
        
    }


    public setMobileNumber(phoneNumber: string): any {
        const reg =  /(\d{0,3})(\d{0,3})(\d{0,4})/;
        const phoneFormat = String(phoneNumber).replace(/\D/g, '').match(reg);
        const mobNumber = document.querySelector('.fb-des-para > .fb-tel') as HTMLAnchorElement;

        if (phoneFormat && mobNumber) {
            mobNumber.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
            mobNumber.href = `tel:${phoneFormat[1]}${phoneFormat[2]}${phoneFormat[3]}`;
        }
        return mobNumber;
    }

    private setServices(footerList: any) {



        const serviceSection = document.querySelector('#fblinkblock-services > .footer-submenu')

        while (serviceSection?.firstChild) {
            serviceSection.removeChild(serviceSection.firstChild);
        }

        if (footerList.length == 0 && document.getElementById('fblinkblock-services')) {
            document.getElementById('fblinkblock-services')!.style.display = 'none'

        }

        const listItem = footerList.map((item: any) => {
            return `<li class="submenu-li">
            <a href=${item.link} class="submenu-a">${item.name}</a>
             </li>`;
        });

        serviceSection?.insertAdjacentHTML('beforeend', listItem.join(''));
    }
    public getLicenseNumber(stateLicenseNumbers:any) {
        if(Array.isArray(stateLicenseNumbers)){
            return stateLicenseNumbers.map((item)=> {
               return item.includes('#') ? item : `#${item}`;
            }).join(', ' );
        }
    }
    async fetchCesUrl() {
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        if (brandData?.displayCESurl) {
            const franchiseWeblocationId = localStorage.getItem("franchiseWebLocationId")
            const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${franchiseWeblocationId}`;
            try {
                const resp: any = await cachedAPIWrapper(url, apiCaching.FullAttributeResponse);
                const footerLink = document.querySelector(".footer-block .footer-url-link") as HTMLAnchorElement;
                if (footerLink && resp.locationWebsiteUrl) {
                    footerLink.href = resp.locationWebsiteUrl;
                    // Updating the text content
                    footerLink.textContent = resp.locationWebsiteUrl.replace(/^https?:\/\/(www\.)?/, '');
                    const footerBlock = document.querySelector(".footer-block .fb-des-block-para");
                    const localAddressFooter = document.querySelector(".footer-block .local-address-footer");
                     if (footerBlock && localAddressFooter) {
                        footerBlock.insertBefore(footerLink, localAddressFooter.nextSibling);  // Insert after local-address-footer
                    } 
                }

            } catch (error) {
                console.error("Error in fecthing Full Attribute Details:", error);
            }
        }
    }
}


new Footer()


