import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { getBrandJSON } from "../util/locationBasedBrandJson";
import { getFranchiseDetails, storeBookingApiPayload } from "../util/share";
import { handleMissingData } from "../util/discovery";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { DomainIdentifier } from "../util/domainService";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { hideTermsPolicy, toggleTermsPolicyBasedOnCountry } from "../util/hideTermsPolicy";
import { disclaimerHandleCheck, DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { LocalizationIdentifier } from "../util/localizationService";

export class FormHandlerAskQuestionWhitePaper {
    private form: HTMLFormElement | null = null;
    private mobileNumberInput: HTMLInputElement | null = null;

    private nameInput: HTMLInputElement;
    private nameInput2: HTMLInputElement;
    private phoneNumberInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private zipCodeInput: HTMLInputElement;
    private privateSuggestedArray = [];
    private readonly downloadFormService: NodeListOf<HTMLElement>;


    constructor() {
        this.downloadFormService = document.querySelectorAll('[id*="papers-download-service-reminder"]');

        this.init();
        this.clearFields();        
        
        this.nameInput2 = document.getElementById('lname') as HTMLInputElement;
        this.nameInput = document.getElementById('fname') as HTMLInputElement;
        this.phoneNumberInput = document.getElementById('phone-number') as HTMLInputElement;
        this.emailInput = document.getElementById('email') as HTMLInputElement;
        this.zipCodeInput = document.getElementById('zipcode') as HTMLInputElement;

        
        this.nameInput?.addEventListener('input', this.errorOnName.bind(this))
        this.nameInput2?.addEventListener('input', this.errorOnName2.bind(this))
        this.emailInput?.addEventListener('input', this.emailError.bind(this))
        this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this));
        this.zipCodeInput?.addEventListener('focusout', this.handleFocusOut.bind(this));

        const backButton = document.querySelector('.thank-you-download .cta-wrapper button');
        if (backButton) {
            backButton.addEventListener('click', this.handleBackToFormClick);
        }
        this.downloadFormService?.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
        });
        
    }

    handleBackToFormClick(){
        const forms = document.getElementById('papers-download-form');
        const cont = document.getElementById('confirmation');
        if (forms) {
          forms.style.display = 'block';
          if (cont) cont.style.display = 'none';
          (document.querySelector('.thank-you-body') as HTMLElement).classList.remove('hidden');
          (document.querySelector('.error-msg-body') as HTMLElement).classList.remove('hidden');
        }
      };

    errorOnName2(){
        if (this.nameInput2.value !== '' && !REGEX.sendName.test(this.nameInput2.value)) {
            
            const fieldError: any = document.getElementById(`lname-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid name format.'  
               
            }
            else{
                const field: any = this.nameInput2;
                if (field && field.parentNode) {
                    const errorMessage = 'Invalid name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else{
            this.hideError('lname')
        }

    }

    errorOnName(){
        if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {
            
            const fieldError: any = document.getElementById(`fname-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid name format.'  
               
            }
            else{
                const field: any = this.nameInput;
                if (field && field.parentNode) {
                    const errorMessage = 'Invalid name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else{
            this.hideError('fname')
        }

    }
    emailError(){
        if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            
            const fieldError: any = document.getElementById(`email-error-msg`);
           

            if(fieldError){
                fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid email address, please try again.'
               
            }
            else{
                const field: any = this.emailInput;
                if (field && field.parentNode) {
                    const errorMessage = 'Invalid email address, please try again.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
    
           
        }
        else{
          
            this.hideError('email')
        }

    }

    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.phoneNumberInput?.value;
        const phoneMatch = val?.replace(/\D/g, '').match(reg);
        let formattedVal = '';
 
        if (phoneMatch) {
          const areaCode = phoneMatch[1];
          const mainNumber = phoneMatch[2];
          const optionalDashWithPhoneNumbers = phoneMatch[3] ? '-' + phoneMatch[3] : '';
       
          formattedVal = `(${areaCode}) ${mainNumber}${optionalDashWithPhoneNumbers}`;
        }
        if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14 && formattedVal.length < 14  ) {
       
            
            const fieldError: any = document.getElementById(`phone-number-error-msg`);
            

            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = 'Invalid phone format.'
               
            }
            else{
                const field: any = this.phoneNumberInput;
                if (field && field.parentNode) {
                    const errorMessage = 'Invalid phone format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }

           
        }
        else{
         
            this.hideError.call(this, 'phone-number')
        }

        if (phoneMatch) {
            let formattedPhoneNumber;
            
            if (!phoneMatch[2]) {
                formattedPhoneNumber = phoneMatch[1];
            } else {
                formattedPhoneNumber = `(${phoneMatch[1]}) ${phoneMatch[2]}${phoneMatch[3] ? '-' + phoneMatch[3] : ''}`;
            }
        
            this.phoneNumberInput!.value = formattedPhoneNumber;
        }
       
    }

    zipCodeError(){
        if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {
        
            const fieldError: any = document.getElementById(`zipcode-error-msg`);
         

            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = 'Invalid zip code format.'
            }
            else{
                const field: any = this.zipCodeInput;
                if (field && field.parentNode) {
                    const errorMessage = 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
          
           
        }
        else{
          
            this.hideError('zipcode')
        }

    }


    hideError(id:any){
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field')

      

    }

    clearFields = () => {
        const inputIds = [
            'send-state',
        ];
        //'send-note' field is removed from inputIds array as part of bug 242636
        inputIds.forEach((id) => {
            const input = document.getElementById(id) as HTMLInputElement;
            if (input) {
                input.addEventListener('input', () => {
                    input.classList.remove('invalid-field');
                    const errmsg = document.getElementById(`${id}-error-msg`);
                    if (errmsg) errmsg.remove();
                });
            }
        });
    };

    private init() {
        this.form = document.querySelector('#papers-download-form button') as HTMLFormElement;
        this.mobileNumberInput = document.getElementById('phone-number') as HTMLInputElement;
        const radioElement = document.getElementById('inline-radio-6') as HTMLInputElement;
        const localisation = DomainIdentifier();
        const countryCode = getCountryCode();
        const localisedcountryCode = sessionStorage.getItem('countryCode');
        if (localisation === 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') {
            hideTermsPolicy('#papers-download-form', 'terms-policy-text');
        }
        if (this.mobileNumberInput) {
            this.mobileNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        if (this.form) {
            this.form.addEventListener('click', this.setAutoSuggested.bind(this));
            if (radioElement) radioElement.checked = true;
        }
    }
    //replacing contact US api with booking api code start from here
    private setAutoSuggested = (e:any) => {
        e.preventDefault()
        if (this.validateForm()) {
            const request = {
                method: 'GET',
                url: ` https://discover.search.hereapi.com/v1/discover?q=${(document.getElementById('zipcode') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
            }
            apiRequest(request)
                .then((result: any) => {
                    this.privateSuggestedArray = result?.items

                    const zipcodeData = result?.items[0]
                    const updatedData = this.getAddress(zipcodeData);
                    this.getFranchiseWebLocationId(e, updatedData);
                    // const arr = result?.items.map((item: any) => item.title)
                    // this.setDatat(result?.items.map((item: any) => item.title))
                })
                .catch((err) => {
                });
        }
    }
    handleFocusOut() {
        const zipCodeInput = document.getElementById('zipcode') as HTMLInputElement;
        const zipCode = zipCodeInput?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
        toggleTermsPolicyBasedOnCountry('#papers-download-form', zipCode);
    }

    handleCheckboxChange() {
        const forms = document.querySelector('#papers-download-form');
        if (!forms) {
            console.log('Form doesnt exist.');
            return;
        }
        const serviceReminderDivs = forms.querySelectorAll('.service-reminder-main');
        if (serviceReminderDivs.length === 0) {
            console.log('Service Disclaimer not authored in CP');
            return;
        }
        let errorID = null;

        serviceReminderDivs.forEach(div => {
            if (div && !div.classList.contains('hidden')) {
                const checkbox = div.querySelector('input[type="checkbox"]');
                const errorMsg = div.querySelector('.error-msg');
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    checkbox.addEventListener('change', () => {
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    if (checkbox.required && !checkbox.checked) {
                        errorID = errorMsg?.id;
                        errorMsg.classList.remove('hidden');
                    }
                }
            }
        });
        return errorID;
    }
    private getAddress(data: any){
        let newData = data;
        newData.title = "No address collected";
        return newData;
    }
    private getFranchiseWebLocationId(event: Event, zipcodeInput:any) {
        event.preventDefault();
        const zipCode: any = zipcodeInput;
        if (this.validateForm()) {
            startLoader();
            getFranchiseDetails(zipCode?.address?.postalCode,false) // Is RoundRobin is False
            .then((response: any) => {
                const result = response;
                if(result.length == 0) {
                    //write the logic for shwoing error msg
                    stopLoader()
                    const forms = document.getElementById('papers-download-form');
                    const cont = document.getElementById('confirmation');
                    if (forms) {
                        forms.style.display = 'none';
                        (document.querySelector('.thank-you-body') as HTMLElement).classList.add('hidden');
                        (document.querySelector('.error-msg-body') as HTMLElement).classList.remove('hidden');
                        if (cont) cont.style.display = "block";
                    }
                }
                if (result.length > 0) {

                    const obj = {
                        'franchiseId': result[0].franchiseId,
                        'franchiseWebLocationId': result[0].franchiseWebLocationId

                    }

                    this.handleSubmit(event, obj, zipcodeInput);
                }
            }) 
            .catch((error:any) => {
                stopLoader();
                const forms = document.getElementById('papers-download-form');
                    const cont = document.getElementById('confirmation');
                    if (forms) {
                        forms.style.display = 'none';
                        (document.querySelector('.thank-you-body') as HTMLElement).classList.add('hidden');
                        (document.querySelector('.error-msg-body') as HTMLElement).classList.remove('hidden');
                        if (cont) cont.style.display = "block";
                    }
            })
        }
    }
    private async handleSubmit(event: any, data: { franchiseId: any; franchiseWebLocationId: any; }, zipcodeInput:any): Promise<void> {
        event.preventDefault();
        const formFields = this.getFormFields();
        const addressZip: any = zipcodeInput
        const id: any = data.franchiseWebLocationId ?? undefined;
        const franchiseId: any = data.franchiseId ?? undefined;
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value || '11';
        const url = window.location.origin;
        const zipCode = addressZip?.address?.postalCode;
        let vendorId;
        let tempCcode;
        let tempCid;
        try{
            const brandJson = getBrandJSON();
            if(brandJson === 'Error validating Location'){
                console.error("Error Determining BrandJSON path for given location")
            }
            else{
                const apiResponse = await fetch(`${url}/${brandJson}`);
                const apiData = await apiResponse.json();
        
                const matchingVendor = apiData.find((item: any) => item.id === conceptId);
                if (matchingVendor) {
                    vendorId = matchingVendor.vendorId;
                    tempCcode = matchingVendor.code;
                    tempCid = matchingVendor.id;
                }
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        let requestBody: BookingPayloadModel = {
            isLocalized: true,
            firstName: formFields.firstName.split(' ')[0],
            lastName: formFields.lastName.split(' ')[0],
            zipCode: zipCode.trim(),
            postalCode: zipCode.trim(),
            email: formFields.email,
            state: addressZip?.address?.state,
            city: addressZip?.address?.city,
            phone: formFields.phoneNumber,
            address: addressZip?.title,
            address2: '',
            comments: '',
            note: '', //formFields.isResidential ? 'Residential' : 'Commercial',
            signUpForUpdates: false,
            conceptId: Number(tempCid),
            conceptCode: tempCcode,
            leadSource: "WEB",
            leadOrigin: "WEB",
            phoneType: 'true',
            isLeadOnly: true,
            scheduleID: "",
            campaignName: "N/A",
            callType: "WEB",
            conceptCalledId: Number(tempCid),
            isEstimate: false,
            emailOptOut: false,
            vendorId: Number(vendorId),
            fileUrls: [{}],
            webLocationId: 0
        };

        if (id) {
            requestBody.webLocationId = id;
            requestBody.franchiseId = franchiseId;
        }
        const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
        requestBody.city = out?.city || requestBody.city;
        requestBody.state = out?.state || requestBody.state;
        requestBody = this.optinEmailCheck(requestBody);

        await bookingUsPostCallWrapper(apiConfig.BOOKING_API_URL, requestBody)
            .then((response: any) => {
                stopLoader();
                storeBookingApiPayload(requestBody,response);
                    const forms = document.getElementById('papers-download-form');
                    const cont = document.getElementById('confirmation');
                    if (forms) {
                        forms.style.display = 'none';
                        (document.querySelector('.error-msg-body') as HTMLElement).classList.add('hidden');
                        if (cont) cont.style.display = "block";
                    }
                    
                    if (document.getElementById('whitepaperdoc')) {
                        const url:any = document.getElementById('whitepaperdoc');
                    
                        // Create a download link.
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url?.value;
                        a.download = 'downloaded.pdf'; // You can set the desired filename here.
                        a.target="_blank"
                    
                        // Append the download link to the document.
                        document.body.appendChild(a);
                    
                        // Trigger a click event on the download link to initiate the download.
                        a.click();
                    
                        // Clean up by revoking the blob URL.
                        // (This part is missing in your code, but it's not necessary in this case)
                    }
                
            })
            .catch((err) => {
                stopLoader();
                    const forms = document.getElementById('papers-download-form');
                    if (forms) {
                        //forms?.classList.add('hidden');
                        forms.style.display = 'none';
                        (document.querySelector('.thank-you-body') as HTMLElement).classList.add('hidden');
                        (document.querySelector('.error-msg-body') as HTMLElement).classList.remove('hidden');
                    }

                    const cont = document.getElementById('failure');
                    if (cont) cont.classList.remove('hidden');

        
                    const contCnf = document.getElementById('confirmation');
                    if (contCnf) contCnf.style.display = "block";
            });

    }
    public optinEmailCheck(requestBody: any): any {
        try {
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();

            let checkboxId = '';

            const countryZip = !REGEX.sendCaPostalCode.test(requestBody.zipCode) ? 'us' : 'ca';
            if (((domain === "us" && !localisation) || (domain === "us" && localisation == "us")) && countryZip === 'us') {
                checkboxId = 'papers-download-service-reminder-us';
            } else {
                checkboxId = 'papers-download-service-reminder-ca';
            }
            const contactForm: any = document.querySelector('#papers-download-form');
            const noteWithRequestBody = disclaimerHandleCheck(false, contactForm, requestBody, requestBody.zipCode, checkboxId);
            return noteWithRequestBody;

        } catch (error) {
            console.error('An error occurred in disclaimerHandleCheck in download form:', error);
            return requestBody;
        }
    }
   /* commenting this function since form submission will be done through Booking api above.
    private handleSubmit(event: Event): void {
        event.preventDefault();
        const formFields = this.getFormFields();
        var host = window.location.hostname.toLowerCase();
        let IsTest;
        if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
          IsTest = true;
        }else {
          IsTest = false;
        }
        if (this.validateForm()) {
            const requestBody = {
                Comments: formFields.note,
                ConceptId: "9",
                Email: formFields.email,
                FirstName: formFields.firstName.split(' ')[0],
                IsLocalized: false,
                IsNewCustomer: formFields.isSignup,
                IsTest: IsTest,
                LastName: formFields.firstName.split(' ')[1],
                Phone: formFields.phoneNumber,
                SignUpForUpdates: true,
                ZipCode: formFields.zipCode,
            };

            const request = {
                method: 'POST',
                url: apiConfig.CONTACT_US_SEND,
                data: requestBody
            };

            const messageDiv = document.querySelector('.contact-info .container .contact-block') as HTMLFormElement;
            const formDiv = document.querySelector('.contact-info .container .contact-block .width-60') as HTMLFormElement;

            const responseDiv = document.getElementById("confirmation");
            const responseHtml = responseDiv?.innerHTML;
            const html = `<div class="thank-you-download">
            <div class="thank-you-body">
                <p class="thank-you-text">Thank you, your submission has been received</p>
            </div>
            <div class="cta-wrapper">
                <button type="button" class="primary-btn btn"> Go Back to the Form </button>
            </div>
        </div>`;
            startLoader();            
            apiRequest(request)
                .then((response: any) => {
                    stopLoader();
                    const forms = document.getElementById('papers-download-form');
                    const cont = document.getElementById('confirmation');
                    if (forms) {
                        forms.style.display = 'none';
                        if (cont) cont.style.display = "block";
                    }
                    
                    if (document.getElementById('whitepaperdoc')) {
                        const url:any = document.getElementById('whitepaperdoc');
                    
                        // Create a download link.
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url?.value;
                        a.download = 'downloaded.pdf'; // You can set the desired filename here.
                        a.target="_blank"
                    
                        // Append the download link to the document.
                        document.body.appendChild(a);
                    
                        // Trigger a click event on the download link to initiate the download.
                        a.click();
                    
                        // Clean up by revoking the blob URL.
                        // (This part is missing in your code, but it's not necessary in this case)
                    }
                })
                .catch((err) => {
                    stopLoader();
                    const forms = document.getElementById('papers-download-form');
                    if (forms) {
                        forms?.classList.add('hidden');
                    }

                    const cont = document.getElementById('failure');
                    if (cont) cont.classList.remove('hidden');
                });
        } else {
            // stopLoader()
        }
    }
    */
    private getFormFields(): any {
        const fields = {
            firstName: (document.getElementById('fname') as HTMLInputElement).value,
            lastName: (document.getElementById('lname') as HTMLInputElement).value,
            zipCode: (document.getElementById('zipcode') as HTMLInputElement).value,
            phoneNumber: (document.getElementById('phone-number') as HTMLInputElement).value,
            email: (document.getElementById('email') as HTMLInputElement).value,
            // customerType: this.getSelectedRatio(document.getElementsByName('inline-radio-cust')),
            // contactMethod: this.getSelectedRatio(document.getElementsByName('inline-radio-method')),
            // note: (document.getElementById('send-note') as HTMLTextAreaElement).value,
            // isSignup: (document.querySelector('input[name="inline-radio-tips"]') as HTMLInputElement)?.checked,
        };
        return fields;
    }

    private getSelectedRatio(node: any): string | null {
        const radioButtons = node;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return null;
    }

   
    private validateForm(): boolean {
        const formFields = this.getFormFields();
   //     document.querySelectorAll('.error-msg').forEach((e) => e.remove());
        document.querySelectorAll('.invalid-field').forEach((e) => e.classList.remove('invalid-field'));
        const errors: any = {};

        if (formFields.firstName === '') {
            errors['fname'] = '';
        } else if (!REGEX.sendName.test(formFields.firstName)) {
            errors['fname'] = 'Invalid name format.';
        }

        if (formFields.lastName === '') {
            errors['lname'] = '';
        } else if (!REGEX.sendName.test(formFields.lastName)) {
            errors['lname'] = 'Invalid name format.';
        }

        if (formFields.zipCode === '') {
            errors['zipcode'] = '';
        } else if (!REGEX.sendZip.test(formFields.zipCode.trim()) || formFields.zipCode === '00000') {
            errors['zipcode'] = 'Invalid zip code format.';
        }

        if (formFields.phoneNumber === '') {
            errors['phone-number'] = '';
        } else if (formFields.phoneNumber.length < 14) {
            errors['phone-number'] = 'Invalid phone format.';
        }

        if (formFields.note === '') {
            errors['send-note'] = '';
        }

        if (formFields.email === '') {
            errors['email'] = '';
        } else if (!REGEX.sendEmail.test(formFields.email)) {
            errors['email'] = 'Invalid email format.';
        }

        if (!REGEX.sendCity.test(formFields.city)) {
            errors['send-city'] = 'Invalid city format.';
        }

        const errorIdChekboxSpan = this.handleCheckboxChange();

        if(errorIdChekboxSpan){
            errors[errorIdChekboxSpan] = '';
        }

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
               // field.parentNode.appendChild(errorElement);
               if (fieldError && fieldError.classList.contains('hidden'))
               fieldError.classList.remove('hidden')
            }
            if (index === 0 && field) {
                field.focus();
            }
        });

        return Object.keys(errors).length === 0;
    }
}

document.addEventListener('DOMContentLoaded', () => {
const formHandler = new FormHandlerAskQuestionWhitePaper();
});