function generateUUID() {
    // Get the current epoch timestamp (in milliseconds)
    const timestamp = Date.now();
    // Generate random values to complete the UUID structure
    const random1 = Math.floor(Math.random() * 0x100000000);  // 8 hexadecimal digits
    const random2 = Math.floor(Math.random() * 0x100000000);  // 8 hexadecimal digits
    // Format the UUID
    const uuid = `${timestamp.toString(16)}-${random1.toString(16)}-${random2.toString(16)}`;
    return uuid;
}
 function addCalendarButton(): void {
    // Select the .appointment-detail-wrap element
    const appointmentDetailWrap = document.querySelector(".appointment-detail-wrap");
    if (!appointmentDetailWrap) {
        console.error(".appointment-detail-wrap element not found");
        return;
    }
    // Create the new button element
    const buttonHTML = `
        <div class="icon-block-text mt-2">
            <button id="dropdown-user-calendar" data-dropdown-toggle="dropdown-calendar" data-dropdown-placement="bottom" class="primary-outline-btn" type="button">
                <img src="/brand/_assets/images/brand-img/lead-flow/calendar-add-svgrepo-com.svg" alt="calendar" width="24" height="24" style="">
                Add to Calendar
            </button>
        </div>
    `;
    // Convert HTML string into a DOM element
    const template = document.createElement("template");
    template.innerHTML = buttonHTML.trim();
    const buttonElement = template.content.firstElementChild;
    if (buttonElement) {
        // Append the new button inside .appointment-detail-wrap
        appointmentDetailWrap.appendChild(buttonElement);
    }
}

export function loadAddToCalendarDropDown(): void {
    addCalendarButton()
    // Select the .appointment-list element
    const appointmentList = document.querySelector(".appointment-list");
    if (!appointmentList) {
        console.error(".appointment-list element not found");
        return;
    }
    // Create the dropdown div
    const dropdownDiv = document.createElement('div');
    dropdownDiv.id = 'dropdown-calendar';
    dropdownDiv.className = 'calendar-listing hidden';
    // Create the unordered list
    const ul = document.createElement('ul');
    ul.className = 'listing-cal';
    ul.setAttribute('aria-labelledby', 'dropdown-user-calendar');
    // Define calendar options
    const calendarOptions = [
        { src: '/brand/_assets/images/brand-img/lead-flow/apple.svg', alt: 'apple calendar', text: 'Apple' },
        { src: '/brand/_assets/images/brand-img/lead-flow/google-calendar.svg', alt: 'g-calendar calendar', text: 'Google' },
        { src: '/brand/_assets/images/brand-img/lead-flow/outlook.svg', alt: 'outlook calendar', text: 'Outlook' },
        { src: '/brand/_assets/images/brand-img/lead-flow/ical.svg', alt: 'icalender calendar', text: 'iCalendar' },
        { src: '/brand/_assets/images/brand-img/lead-flow/yahoo.svg', alt: 'yahoo calendar', text: 'Yahoo' }
    ];
    // Create list items dynamically
    calendarOptions.forEach(option => {
        const li = document.createElement('li');
        const a = document.createElement('a');
        a.className = 'calendar-link';
        const img = document.createElement('img');
        img.className = 'w-6 h-6 me-2';
        img.src = option.src;
        img.alt = option.alt;
        a.appendChild(img);
        a.appendChild(document.createTextNode(option.text));
        li.appendChild(a);
        ul.appendChild(li);
    });
    // Append list to dropdown div
    dropdownDiv.appendChild(ul);
    // Append dropdown to the first list item
    appointmentList.insertAdjacentElement("afterend", dropdownDiv);
}

function getReminderData() {
    const appointmentDate = document.querySelector(".booking-date-time .icon-block-text")?.textContent?.trim() ?? "";
    const timeElements = document.querySelectorAll(".booking-date-time .icon-block-text");
    let appointmentTime = timeElements.length > 1 ? timeElements[1].textContent?.trim() ?? "" : "";
    let location = "";
    const locationElement = document.querySelectorAll('.service-address .icon-block-text');
    locationElement?.forEach((element) => {
        if(location){
            location +=", "
        }
            location += element.textContent?.trim();
    });
    const localPhoneNumber = localStorage.getItem('localPhoneNumber')??'';
    const brandDBAName = localStorage.getItem('doingBusinessAs')??'';
    if (!appointmentDate || !appointmentTime) {
        console.error("Appointment date or time is missing.");
        return;
    }
    let startTime, endTime;
    // When slots are morning/afternoon/evening get time range
    if(!timeRange(appointmentTime)){
        appointmentTime = getTimeRange(appointmentTime.toLowerCase())
    }
    [startTime, endTime] = appointmentTime.split(" - ");
    const startDateTime = formatAppointmentDate(appointmentDate,startTime);
    const endDateTime = formatAppointmentDate(appointmentDate,endTime);
    if(!startDateTime || !endDateTime){
        return;
    }
    const comments:string = document.querySelector('.email-description')?.textContent?.trim()??'';
    const outputObj:ReminderDetails = {
        uid: "",
        title: "",
        description: "",
        startTime: startDateTime,
        endTime: endDateTime,
        location: location,
        brandDBAName:brandDBAName,
        phone:localPhoneNumber,
        comments:comments
        }
    return outputObj;
}
interface CalendarInvite {
    uid: string;
    calendarType: string;
    created: boolean;
  }
interface ReminderDetails {
    uid?: string; // Unique ID for the reminder
    title?: string | null;
    description?: string | null;
    startTime: Date 
    endTime: Date
    location: string;
    brandDBAName?:string;
    comments?:string;
    phone?:string;
}

function generateDescription(comments:string, brandDBAName: string, phonenumber: string): string {
    if (!comments){
        return "";
    }
    // Replace placeholders if they exist in the text content
    comments = comments.replace("[brandname]", brandDBAName).replace("[phonenumber]", phonenumber);
    return comments.trim();
}

export function calendarInvite(reminderDetails:ReminderDetails, calendarType:string){
    if(!reminderDetails?.startTime ||!reminderDetails?.endTime ||!reminderDetails?.location ||!reminderDetails?.brandDBAName || !reminderDetails?.phone){
        console.log("Mandatory fields are missing");
        return;
    }
    if(!reminderDetails.uid){
        reminderDetails.uid = generateUUID()
    }
    if(!reminderDetails.title){
        reminderDetails.title = `Your Appointment with ${reminderDetails.brandDBAName}`;
    }
    if(!reminderDetails.description){
        reminderDetails.description = generateDescription(reminderDetails.comments??'',reminderDetails.brandDBAName,formatPhoneNumber(reminderDetails.phone));
    }
    switch (calendarType.toLowerCase()) {
        case 'apple':
            createICalendarInvite(reminderDetails);
            break;
        case 'google':
            createWebCalendarInvite(reminderDetails,calendarType);
            break;
        case 'outlook':
            createWebCalendarInvite(reminderDetails,calendarType);
            break;
        case 'icalendar':
            createICalendarInvite(reminderDetails);
            break;
        case 'yahoo':
            createWebCalendarInvite(reminderDetails,calendarType);
            break;
        default:
            generateIcs(reminderDetails);
            return;
    }
}

export function setupCalendarInvites() {
    const reminderDetails: ReminderDetails | undefined = getReminderData();
    if (!reminderDetails) {
        console.log('could not get the required details');
        return;
    }
    const calendarContainer = document.getElementById('dropdown-calendar'); 
    if (!calendarContainer) {
        console.warn('Calendar container not found');
        return;
    }
    calendarContainer.addEventListener('click', (event) => {
        const target = event.target as HTMLElement;
        const calendarType = target.textContent?.trim() ?? "";
        if (!calendarType) return;
        calendarInvite(reminderDetails,calendarType);
    });
}

function createICalendarInvite(reminderDetails: any) {
    console.log("iCalendar invite created", reminderDetails);
    const icsContent = generateIcs(reminderDetails);
    const blob = new Blob([icsContent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'invite.ics'; // Set a default filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}
function generateIcs(reminderDetails: ReminderDetails): string {
    const { uid, title, description, startTime, endTime, location } = reminderDetails;
    const startDate = formatIcsDate(startTime);
    const endDate = formatIcsDate(endTime);
    const now = formatIcsDate(new Date());

    return `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//NBLY//EN\nCALSCALE:GREGORIAN\nMETHOD:REQUEST\nBEGIN:VEVENT\nUID:${uid}@${window.origin}\nDTSTAMP:${now}\nDTSTART:${startDate}\nDTEND:${endDate}\nSUMMARY:${title}\nDESCRIPTION:${description}\nLOCATION:${location}\nSTATUS:CONFIRMED\nSEQUENCE:0\nBEGIN:VALARM\nDESCRIPTION:Reminder\nACTION:DISPLAY\nEND:VALARM\nEND:VEVENT\nEND:VCALENDAR`;
}

function formatIcsDate(date: Date): string {
    const year = date.getUTCFullYear();
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + date.getUTCDate()).slice(-2);
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);
    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
}

function createWebCalendarInvite(reminderDetails: any, calendarType:string) {
    console.log(`${calendarType} calendar invite created`, reminderDetails);
    const calendarReminderUrl = generateCalendarUrl(reminderDetails,calendarType);
    window.open(calendarReminderUrl, '_blank');
}

function generateCalendarUrl(reminderDetails: ReminderDetails,calendarType:string): string {
    const { uid, title, description, startTime, endTime, location } = reminderDetails;
    const start = formatDate(startTime);
    const end = formatDate(endTime);
    const encodedTitle = title?encodeURIComponent(title):'';
    const encodedDescription = description?encodeURIComponent(description):'';
    const encodedLocation = location ? encodeURIComponent(location) : '';
    if(calendarType === 'Google'){
        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodedTitle}&details=${encodedDescription}&location=${encodedLocation}&dates=${start}/${end}&id=${uid}`;
    }
    if(calendarType === 'Outlook'){ 
        const startCalendarTime = convertLocalToISO(startTime);
        const endCalendarTime = convertLocalToISO(endTime);
        return `https://outlook.office365.com/calendar/action/compose?subject=${encodedTitle}&startdt=${startCalendarTime}&enddt=${endCalendarTime}&body=${encodedDescription}&location=${encodedLocation}&id=${uid}`;
    }
    //When calendar type is yahoo
    return `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodedTitle}&st=${start}&et=${end}&desc=${encodedDescription}&in_loc=${encodedLocation}&id=${uid}`;
}

function timeRange(input: string): boolean {
    return /\d{1,2}:\d{2}\s?(?:am|pm)\s?-\s?\d{1,2}:\d{2}\s?(?:am|pm)/i.test(input);
}

function getTimeRange(input: string): string {
    const timeMappings: Record<string, string> = {
        "morning": "8:00 am - 12:00 pm",
        "afternoon": "12:00 pm - 5:00 pm",
        "evening": "5:00 pm - 9:00 pm",
        "after hours": "5:00 pm - 9:00 pm"
    };
    return timeMappings[input.toLowerCase()] || "";
}

function convertLocalToISO(dateString: Date): string {
    const date = new Date(dateString).toISOString();
    return date;
}

function formatDate(date: Date) {
    const formattedDate = date.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
    return formattedDate;
}

function formatAppointmentDate(appointmentDate: string, appointmentTime: string): Date | null {
    try {
        // Extract date parts
        const dateParts = appointmentDate.split(", ")[1] +", " + appointmentDate.split(", ")[2];

        // Combine and create a valid date string
        const formattedDateString = `${dateParts} ${appointmentTime}`;

        // Create Date object (handles cross-browser compatibility)
        const parsedDate = new Date(formattedDateString);
        if (isNaN(parsedDate.getTime())) {
            throw new Error("Invalid Date Format");
        }
        return parsedDate;
    } catch (error) {
        console.error("Error parsing date:", error);
        return null;
    }
}

function formatPhoneNumber(phoneNumber: number | string): string {
    let numStr = phoneNumber.toString();
    if (numStr.length > 10) {
        numStr = numStr.substring(1, numStr.length);
    }
    return `${numStr.slice(0, 3)}-${numStr.slice(3, 6)}-${numStr.slice(6)}`;
}
  
