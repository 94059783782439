
export class Print {


    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            const printBtns = document.querySelectorAll('.sp-offers-list .sp-offers-item .sp-offers-cta .secondary-btn');
            //const printBtns2 = document.querySelectorAll('.tns-item a.secondary-btn.claim-offer');
            if (printBtns)
                printBtns.forEach((printBtn: any, index) => {
                    printBtn.addEventListener('click', (e: any) => this.printOffer(e, index));
                });


            // const printBtnsCoupon = document.querySelectorAll('.offers-block-section .offers-block-wrapper .offers-block-item .secondary-btn');
            // if (printBtnsCoupon)
            //     printBtnsCoupon.forEach((btn, index) => {
            //         btn.addEventListener('click', (e) => this.printCoupon.call(this, e, index));
            //     });
        });
    }

    private printOffer(e: Event, index: any) {
        //  e.preventDefault();
        // e.stopPropagation();

        const formDiv = (document.querySelectorAll('.sp-offers-item')[index] || document.querySelectorAll('.offers-block-item')[index]) as HTMLDivElement;
        // Create an iframe element
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        const iframeDoc = iframe.contentWindow?.document;
        if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write('<html><head>');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/service.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/main.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/brand-config.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/header.css" media="all">')
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/footer.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/glossary.css" media="all">');
            iframeDoc.write(`<style>
            @page {
                margin:0mm;
                
              }
              * {
                -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
                color-adjust: exact !important;                 /*Firefox*/
            }
            .claim-offer{
                display:none
            }
            @media print { 
                @-moz-document url-prefix() {
            
                 .btn-group li {
                    display: none !important;
                 }
                 .btn-group li:first-child {
                    display: block !important;
                 }
                    }
            }
            
            </style>
            `);
            iframeDoc.write('</head>');
            iframeDoc.write('<body>');
            iframeDoc.write('<section class="sp-offers-section">');
            iframeDoc.write('<div class="container">');
            iframeDoc.write('<ul class="sp-offers-list">');
            if (formDiv && formDiv.classList.contains("offers-block-item")) {
                const secondHtmlElement: HTMLElement = formDiv;
                const convertedElement = this.convertToFirstReference(secondHtmlElement);
                iframeDoc.write(convertedElement.innerHTML);
            } else {
                iframeDoc.write(formDiv.innerHTML);
            }
            iframeDoc.write('</div">');
            iframeDoc.write('</ul">');
            iframeDoc.write('</section>');
            iframeDoc.write('</body></html>');
            iframeDoc.close();
            
            // Wait for the iframe content to load before printing
            iframe.onload = function () {
                    try {
                        iframe.contentWindow?.focus();
                        iframe.contentWindow?.print();
                    } catch (error) {
                        console.log("err", error)
                    }
                };
    
                // Additional error handling for onload event
                iframe.onerror = function (errorEvent) {
                    console.log(errorEvent);
                };

        } else {
            alert('Printing is blocked by the browser. Please allow pop-ups for this site to print the offer.');
        }
    }
    private convertToFirstReference(htmlElement: HTMLElement): HTMLElement {
        const liElement = document.createElement('li');
        liElement.classList.add('sp-offers-item');
        const mainDiv = document.createElement('div');
        mainDiv.classList.add('sp-offers-main');
        const dataDiv = document.createElement('div');
        dataDiv.classList.add('sp-offers-data');

        const detailsDiv = document.createElement('div');
        detailsDiv.classList.add('sp-offers-details');

        const brandLogoImg = htmlElement.querySelector('.brand-logo')?.cloneNode(true) as HTMLImageElement;

        const blockOfferParagraph = htmlElement.querySelector('.block-offer')?.cloneNode(true) as HTMLParagraphElement;

        const offersBlockDescParagraph = htmlElement.querySelector('.offers-block-desc')?.cloneNode(true) as HTMLParagraphElement;

        detailsDiv.appendChild(brandLogoImg);
        detailsDiv.appendChild(blockOfferParagraph);
        detailsDiv.appendChild(offersBlockDescParagraph);
        dataDiv.appendChild(detailsDiv);

        const disclaimerDiv = htmlElement.querySelector('.offers-block-disclaimer')?.cloneNode(true) as HTMLDivElement;
        const expireBlockSpan = htmlElement.querySelector('.offers-block-expire')?.cloneNode(true) as HTMLSpanElement;
        const watermarkBlockImg = htmlElement.querySelector('.offers-watermark')?.cloneNode(true) as HTMLImageElement;

        mainDiv.appendChild(dataDiv);

        if(disclaimerDiv){
            mainDiv.appendChild(disclaimerDiv);
        }

        if(expireBlockSpan){
            mainDiv.appendChild(expireBlockSpan);
        }

        if(watermarkBlockImg){
            mainDiv.appendChild(watermarkBlockImg);
        }

        const claimBlockOfferAnchor = htmlElement.querySelector('.claim-offer')?.cloneNode(true) as HTMLAnchorElement;

        liElement.appendChild(mainDiv);
        liElement.appendChild(claimBlockOfferAnchor);

        return liElement;
    }







    private printCoupon(e: Event, index: any) {
        e.preventDefault();
        e.stopPropagation();

        var urlLink = window.location.href.split('/');
        var isWebSite = urlLink[3] === "ca" ? "ca" : "us";
        var brandCss = isWebSite == 'ca' ? "/us/en-us/brand/_assets/css/" + urlLink[4] + ".css" : "/us/en-us/brand/_assets/css/" + urlLink[3] + ".css";

        const formDiv = document.getElementById(`tns1-item${index}`) as HTMLDivElement;
        // Create an iframe element
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write('<html><head>');
            iframeDoc.write('<link type="text/css" href="opus2.css" rel="stylesheet" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/service.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/main.css" media="all">');
            iframeDoc.write(`<link rel="preload" href="${brandCss}" as="style">`);
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/brand-config.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/header.css" media="all">')
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/footer.css" media="all">');
            iframeDoc.write('<link rel="stylesheet" type="text/css" href="/brand/_assets/css/glossary.css" media="all">');
            iframeDoc.write(`<link href="${brandCss}" rel="stylesheet" type="text/css" media="all" />`);
            iframeDoc.write(`<style>
            @media print{ 
                margin:0mm;
                body {
                    display: none; /* Hide the main page content */
                  }
                  
                  iframe {
                    display: block; /* Display the iframe content */
                  }
              }
              * {
                -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
                color-adjust: exact !important;                 /*Firefox*/
            }
            .claim-offer{
                display:none
            }
            @media print { 
                @-moz-document url-prefix() {
            
                 .btn-group li {
                    display: none !important;
                 }
                 .btn-group li:first-child {
                    display: block !important;
                 }
                    }
            }
            
            </style>`);
            iframeDoc.write('</head>');
            iframeDoc.write('<body>');
            iframeDoc.write('<section class="offers-block-section">');
            iframeDoc.write('<div class="container">');
            iframeDoc.write('<div class="offers-block-wrapper">');
            iframeDoc.write('<ul class="offers-block-list offers-slider">');
            iframeDoc.write(formDiv.innerHTML);
            iframeDoc.write('</div">');
            iframeDoc.write('</div">');
            iframeDoc.write('</ul">');
            iframeDoc.write('</section>');
            iframeDoc.write('</body></html>');
            iframeDoc.close();
            // Wait for the iframe content to load before printing
            try {
                setTimeout(function () {
                    iframeDoc.close();
                    iframe.contentWindow?.focus();
                    iframe.contentWindow?.print();
                }, 250);

            } catch (error) {

                

            }
        } else {
            alert('Printing is blocked by the browser. Please allow pop-ups for this site to print the offer.');
        }
    }
}

new Print();