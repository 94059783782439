import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";

export const bookingPayload :BookingPayloadModel = {
    "franchiseId": 0,
    "hasSameDayScheduling": true,
    "isBetaTester": false,
    "comments": "",
    "isLocalized": true,
    "conceptId": 1,
    "useFranchiseProfileOptInForPOSScheduling": true,
    "isFranchiseOptedIntoPOSScheduling": true,
    "c4ChannelTypeId": 0,
    "conceptCalledId": 1,
    "conceptName": "Molly Maid",
    "conceptCode": "MLY",
    "c4LeadsEnabled": true,
    "canceledJobInformation": "",
    "vendorId": 70,
    "nationalLeadId": "",
    "isTest": false,
    "vendorName": "Molly Maid Website",
    "webLocationId": 0,
    "c4CallCenterId": 0,
    "dayTimePhoneNumber": "",
    "zipCode": "",
    "note": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "phone": "",
    "phoneType": "",
    "postalCode": "",
    "city": "",
    "state": "",
    "country": "",
    "address": "",
    "address2": "",
    "leadSource": "Web",
    "licenseNumber": "",
    "webLocationAccountCode": "",
    "leadOrigin": "Web",
    "campaignName": "N/A",
    "referringURL": "",
    "currentPage": window.location.origin,
    "disposition": "",
    "callType": "In Bound",
    "preferredCommunicationType": "",
    "emailOptOut": false,
    "tagName": "",
    "latitude": 0,
    "longitude": 0,
    "locationType": "",
    "isGoogleAddress": true,
    "googleCalendarEventId": "",
    "googleCalendarEmail": "",
    "serviceText": "Lead Only",
    "ServiceID": 0,
    "warranty": false,
    "timeSlotTypeId": 0,
    "makeId": 0,
    "leadSourceId": 0,
    "secondaryLeadSourceId": 0,
    "serviceTypeID": 0,
    "businessName": "",
    "serviceTypeProblem": "",
    "overrideJobPromisedDate": "",
    "serviceSkuId": 0,
    "isEstimate": false,
    "estimateHouseStyle": "",
    "estimateSquareFeet": "",
    "estimateBedrooms": "",
    "estimateBathrooms": "",
    "estimatePets": "",
    "ccFirstName": "",
    "ccLastName": "",
    "ccType": "",
    "ccSuffix": "",
    "ccToken": "",
    "ccExpirationDate": "",
    "ccComment": "",
    "isLeadOnly": false,
    "overrideCSRName": "",
    "overrideComment": "",
    "syncTenantName": "",
    "isEmergencyCall": false,
    "callId": "",
    "isFranchiseInPOS": true,
    "customerType": "",
    "neighborlyAccountId": 0,
    "neighborlyLeadSourceId": 0,
    "fileUrls": [
       
        {
            "fileUrl": ""
        }
    ],
    "jobFrequency": "",
    "jobFrequencyDetail": "",
    "year": 0,
    "make": "",
    "model": "",
    "bodyStyle": "",
    "vin": "",
    "licensePlateNumber": "",
    "windshieldRepair": "",
    "headlightRestoration": "",
    "serviceRequested": [''],
    "leadAdditionalNotes": {
        "leadAdditionalNoteLabel": "Website",
        "leadAdditionalNoteText": ""
    },
    "formOfPayment": "",
    "insuranceCompany": "",
    "deductibleAmount": 0,
    "policyNumber": "",
    "scheduleID": "",
    "signUpForUpdates": false,
    "referralType": "",
    "referralTypeId": "",
};

export function isTestFcn(){
    const host = window.location.hostname.toLowerCase();
    let IsTest;
    if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) {
        IsTest = true;
    } else {
        IsTest = false;
    }
    return IsTest;
}


