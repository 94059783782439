import { getBrandDetailsFromBrandJson } from "./share";

export async function replaceLocalLogoLink(element: HTMLElement) {

    let trailingSlash = '';
    let hostMapWebLocation ='';

    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;

    if(conceptCode){
      const brandData = await getBrandDetailsFromBrandJson(conceptCode);
      if(brandData?.includeTrailingSlash){
        trailingSlash = brandData.includeTrailingSlash ? "/" : "";
      }
       if(brandData?.weblocation_path_group){
          hostMapWebLocation = brandData.weblocation_path_group;
      }
      let dbaName = hostMapWebLocation ? `${hostMapWebLocation}/${localStorage.getItem('dbaName')}`: localStorage.getItem('dbaName');
      const weblocationDetails = window.crownpeakWeblocationsByIdDetail ?? {};
      if((brandData?.include_crownpeakURL && weblocationDetails?.weblocation_url) || (brandData?.include_statename && weblocationDetails?.weblocation_url)){
        element?.setAttribute("href", `${weblocationDetails?.weblocation_url}`);
      }else{
        element?.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
      }
    }
}