import apiRequest from "../api/apiRequest";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { contactUsPostCallWrapper } from "../apiCaching/POST-Requests/apiContactCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { ContactUsPayloadModel } from "../apiCaching/POST-Requests/interface/contact/contactInterface";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { ScreenType } from "../location-search-map/models/ScreenType";
import { reportScreenSize } from "../location-search-map/reportScreenSize";
import { disclaimerHandleCheck, DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { handleMissingData } from "../util/discovery";
import { DomainIdentifier } from "../util/domainService";
import { hideTermsPolicy, toggleTermsPolicyBasedOnCountry } from "../util/hideTermsPolicy";
import { startLoader, stopLoader } from "../util/loader";
import { LocalizationIdentifier } from "../util/localizationService";
import { getBrandDetailsFromBrandJson, getFranchiseWebLocationId, removeAllEventListeners, storeBookingApiPayload } from "../util/share";

export class MobileContactUs {

    public isConatctUs: any;
    public form: any;
    public nameInput: HTMLInputElement;
    public lastNameInput: HTMLInputElement;
    public emailInput: HTMLInputElement;
    public phoneInput: HTMLInputElement;
    public addressInput: HTMLInputElement;
    public formDivHeader: HTMLFormElement;
    public thankYouHeader: any;
    public thankYouDesc: any;
    public errorMsg: any;
    public eroorDesc: any;
    public noServiceErrorMsg: any;
    public noServiceDescription: any;
    private readonly mobileContactFormService: NodeListOf<HTMLElement>;

    constructor() {
        this.form = document.querySelector('.mobile-contact-us');
        this.nameInput = this.form?.querySelector('#mobile-short-req-name');
        this.lastNameInput = this.form?.querySelector('#mobile-short-req-lname');
        this.emailInput = this.form?.querySelector('#mobile-short-req-email');
        this.phoneInput = this.form?.querySelector('#mobile-short-req-phone');
        this.addressInput = this.form?.querySelector('#mobile-short-req-zipcode');
        const closestAncestor = this.form?.closest('#form-sidebar'); 
        this.formDivHeader = closestAncestor?.querySelector('.form-heading') as HTMLFormElement;
        this.thankYouHeader = closestAncestor?.querySelector('#thank-you-header') as HTMLFormElement;
        this.thankYouDesc = closestAncestor?.querySelector('#thank-you-description') as HTMLFormElement;
        this.errorMsg = closestAncestor?.querySelector('#error--header') as HTMLFormElement;
        this.eroorDesc = closestAncestor?.querySelector('#error-description') as HTMLFormElement;
        this.noServiceErrorMsg = closestAncestor?.querySelector('#no-service-header') as HTMLFormElement;
        this.noServiceDescription = closestAncestor?.querySelector('#no-service-description') as HTMLFormElement;
        this.mobileContactFormService = document.querySelectorAll('[id*="contact-us-form-service-reminder"]');
        this.init();
        this.inputValidationInilization();
        this.closeBtnHandler();
    }

    public inputValidationInilization() {

        if (this.nameInput) {
            this.nameInput.addEventListener('input', () => this.handleCommonInputError(this.nameInput, `${this.nameInput.id}-error-msg`, REGEX.sendName, 'Please Enter a Valid Firt Name'));
        }

        if (this.lastNameInput) {
            this.lastNameInput.addEventListener('input', () => this.handleCommonInputError(this.lastNameInput, `${this.lastNameInput.id}-error-msg`, REGEX.sendName, 'Please Enter a Valid Last Name'));
        }

        if (this.emailInput) {
            this.emailInput.addEventListener('input', () => this.handleCommonInputError(this.emailInput, `${this.emailInput.id}-error-msg`, REGEX.sendEmail, 'Please Enter a Valid Email'));
        }

        if (this.phoneInput) {
            this.phoneInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        if (this.addressInput) {
            this.addressInput.addEventListener('input', () => this.handleCommonInputError(this.addressInput, `${this.addressInput.id}-error-msg`, REGEX.sendZip, 'Please Enter a Valid Address'));
            this.addressInput?.addEventListener('focusout', this.handleFocusOut.bind(this));
        }

        this.mobileContactFormService?.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
        });
    }

    public init() {
        this.isConatctUs = removeAllEventListeners('.mobile-contact-us .primary-btn');
        if (this.isConatctUs) {
            this.isConatctUs.addEventListener('click', this.handleContactUsSubmit.bind(this));
            const localisation = DomainIdentifier();
            const countryCode = getCountryCode();
            const localisedcountryCode = sessionStorage.getItem('countryCode');
            if (localisation === 'ca' || countryCode === 'ca' || localisedcountryCode === 'ca') {
                hideTermsPolicy('.residential-form.mobile-contact-us', 'terms-policy-text');
            }
        }
    }
    handleFocusOut() {
        const zipCodeInput = document.getElementById('mobile-short-req-zipcode') as HTMLInputElement;
        const zipCode = zipCodeInput?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
        toggleTermsPolicyBasedOnCountry('.residential-form.mobile-contact-us', zipCode);
    }
    handleCheckboxChange() {
        const forms = document.querySelector('.residential-form.mobile-contact-us');
        if (!forms) {
            console.log('Form doesnt exist.');
            return;
        }
        const serviceReminderDivs = forms.querySelectorAll('.service-reminder-main');
        if (serviceReminderDivs.length === 0) {
            console.log('Service Disclaimer not authored in CP');
            return;
        }
        let errorID = null;

        serviceReminderDivs.forEach(div => {
            if (div && !div.classList.contains('hidden')) {
                const checkbox = div.querySelector('input[type="checkbox"]');
                const errorMsg = div.querySelector('.error-msg');
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    checkbox.addEventListener('change', () => {
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    if (checkbox.required && !checkbox.checked) {
                        errorID = errorMsg?.id;
                        errorMsg.classList.remove('hidden');
                    }
                }
            }
        });
        return errorID;
    }
    public async handleContactUsSubmit() {
        if (this.validateInputs()) {
            const apiEndPoint = (document.getElementById('api_endpoint') as HTMLInputElement)?.value;
            const zipcodeInput = await this.discoverApiEndPoint();
            if(zipcodeInput){
                if (apiEndPoint?.toLowerCase() == 'booking_endpoint') {
                    this.bookingApiEndPoint(zipcodeInput);
                } else if (apiEndPoint?.toLowerCase() == 'contactus_endpoint') {
                    this.contactUsApiEndPoint(zipcodeInput);
                }
            }else {
                stopLoader();
                this.noServiceErrorMsg?.classList.remove("hidden");
                this.noServiceDescription?.classList.remove("hidden");
                this.formHide();
            }
        }

    }

    public async discoverApiEndPoint() {

        const formFields = this.getFormFields();
        const addressId = formFields?.addressInput?.id
        const requestDiscoverApi = {
            method: 'GET',
            url: `${apiConfig.DISCOVER_API}?q=${(this.form.querySelector(`#${addressId}`) as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN%2CPRI&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
        }
        const discoverResult: { items: any[] } = await apiRequest(requestDiscoverApi);
        const zipcodeData = discoverResult?.items[0]
        const zipcodeInput = zipcodeData ? this.getAddress(zipcodeData): null;
        return zipcodeInput;
    }

    public getAddress(data: any) {
        let newData = data;
        newData.title = "No address collected";
        return newData;
    }

    public async bookingApiEndPoint(zipcodeInput: any) {
        startLoader();
        const formFields = this.getFormFields();
        const franchiseIdDetails = await getFranchiseWebLocationId(zipcodeInput?.address?.postalCode) as any;
        
        if(franchiseIdDetails){
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const host = window.location.hostname.toLowerCase();
        let IsTest;
        if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) {
            IsTest = true;
        } else {
            IsTest = false;
        }
        let vendorId: any;
        let vendorName: any;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        if (brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;
        const url = window.location.origin;

        let requestBody: BookingPayloadModel = {
            isLocalized: true,
            firstName: formFields.nameInput?.value.split(' ')[0] ? formFields.nameInput?.value.split(' ')[0] : '',
            lastName: formFields.nameInput?.value.split(' ')[1] ? formFields.nameInput?.value.split(' ')[1] : ' ',
            zipCode: zipcodeInput?.address?.postalCode.trim(),
            postalCode: zipcodeInput?.address?.postalCode.trim(),
            email: formFields.emailInput?.value,
            state: zipcodeInput?.address?.state,
            city: zipcodeInput?.address?.city,
            phone: formFields.phoneInput?.value,
            address: "No address collected",
            address2: '',
            comments: '',
            note: formFields.isResidential ? 'Residential' : 'Commercial',
            signUpForUpdates: false,
            conceptId: Number(conceptId),
            conceptCode: conceptCode,
            leadSource: "WEB",
            leadOrigin: "WEB",
            phoneType: 'true',
            isLeadOnly: true,
            isTest: IsTest,
            scheduleID: "",
            campaignName: 'N/A',
            callType: "WEB",
            conceptCalledId: Number(conceptId),
            isEstimate: false,
            emailOptOut: emailOptOut,
            vendorId: Number(vendorId),
            vendorName: vendorName,
            currentPage: url,
            customerType: formFields.isResidential ? 'Residential' : 'Commercial',
            preferredCommunicationType: 'Email,Phone',
            leadSourceId: defaultLeadSourceId,
            webLocationId: 0
        };

        if (franchiseIdDetails) {
            let webLocationId = franchiseIdDetails?.franchiseWebLocationId;
            let franchiseId = franchiseIdDetails?.franchiseId;
            requestBody.webLocationId = webLocationId;
            requestBody.franchiseId = franchiseId;
        }
        const lastName = formFields.lastNameInput?.value ?? '';
        if (lastName) {
            requestBody.lastName = lastName;
            requestBody.firstName = formFields.nameInput?.value ?? '';
        }
        const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
        requestBody.city = out?.city || requestBody.city;
        requestBody.state = out?.state || requestBody.state;
        requestBody = this.optinEmailCheck(requestBody);
        await bookingUsPostCallWrapper(apiConfig.BOOKING_API_URL, requestBody).then((response: any) => {
                stopLoader()
                storeBookingApiPayload(requestBody, response);
                this.thankYouHeader?.classList.remove("hidden");
                this.thankYouDesc?.classList.remove("hidden");
                this.formHide();
            })
            .catch((err:any) => {
                stopLoader();
                this.errorMsg?.classList.remove("hidden");
                this.eroorDesc?.classList.remove("hidden");
                this.formHide();
            });
        }else {
            stopLoader();
            this.noServiceErrorMsg?.classList.remove("hidden");
            this.noServiceDescription?.classList.remove("hidden");
            this.formHide();
        }
    }
    public optinEmailCheck(requestBody: any): any {
        try {
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();

            let checkboxId = '';

            const countryZip = !REGEX.sendCaPostalCode.test(requestBody.zipCode) ? 'us' : 'ca';
            if (((domain === "us" && !localisation) || (domain === "us" && localisation == "us")) && countryZip === 'us') {
                checkboxId = 'contact-us-service-reminder-us-mobile';
            } else {
                checkboxId = 'contact-us-service-reminder-ca-mobile';
            }
            const contactForm: any = document.querySelector('.residential-form.mobile-contact-us');
            const noteWithRequestBody = disclaimerHandleCheck(false, contactForm, requestBody, requestBody.zipCode, checkboxId);
            return noteWithRequestBody;

        } catch (error) {
            console.error('An error occurred in disclaimerHandleCheck in contactus form:', error);
            return requestBody;
        }
    }
    public async contactUsApiEndPoint(zipcodeInput: any) {

        const formFields = this.getFormFields();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const lastName = formFields.lastNameInput?.value ?? '';
        const addressZip: any = zipcodeInput;
        let vendorId;

        try {
            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
            if (brandData) {
                vendorId = brandData.vendorId;
            }
            let noteText = '';
            if(brandData.note_text_contactus_api.length > 0) {
                noteText = brandData.note_text_contactus_api;
            }

            let requestBody: ContactUsPayloadModel = {
                "firstName": formFields.nameInput?.value.split(' ')[0] ? formFields.nameInput?.value.split(' ')[0] : '',
                "lastName": formFields.nameInput?.value.split(' ')[1] ? formFields.nameInput?.value.split(' ')[1] : ' ',
                "zipCode": addressZip?.address?.postalCode.trim(),
                "email": formFields.emailInput?.value,
                "state": addressZip?.address?.state,
                "city": addressZip?.address?.city,
                "phone": formFields.phoneInput?.value,
                "address": addressZip?.address?.label,
                "address2": '',
                "country": addressZip?.address?.country ?? '',
                "comments": noteText,
                "signUpForUpdates": false,
                "isLocalized": conceptId?.value ==="25" ? true : false,
                "isNewCustomer": String(null),
                "isTest": true,
                "conceptId": Number(conceptId?.value),
                "vendorId": Number(vendorId) || 0,
            };
            const host = window.location.hostname.toLowerCase();
            if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) {
                requestBody.isTest = true;
            } else {
                requestBody.isTest = false;
            }
            if (lastName) {
                requestBody.lastName = lastName;
                requestBody.firstName = formFields.nameInput?.value ?? '';
            }
            requestBody = this.optinEmailCheck(requestBody);
            await contactUsPostCallWrapper(apiConfig.CONTACT_US_SEND, requestBody);
            this.formHide();
            if (this.thankYouHeader) {
                this.thankYouHeader.classList.remove("hidden");
                this.thankYouDesc.classList.remove("hidden");
            }

        }
        catch (error) {
            console.error(`Error in ContactUSMobile.ts file in the contactUsApiEndPoint function. Error message: ${error}`);
            this.errorMsg?.classList.remove("hidden");
            this.eroorDesc?.classList.remove("hidden");
            this.formHide();
        }

    }

    public handleMobileInput(e: any): void {
        const phoneInput = e.target;
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = phoneInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
        if (phoneInput?.value != '' && phoneInput?.value.length < 14 && formattedVal.length < 14) {
            const getErrMsg = (this.form.querySelector(`#${phoneInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${phoneInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? 'Invalid phone format.'

            }
        }
        else {
            this.hideError(phoneInput?.id)
        }

        if (x) {
            phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }

    hideError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        const fieldError: any = this.form.querySelector(`#${id}-error-msg`);
        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }

    public validateInputs(): boolean {

        const formFields = this.getFormFields();
        this.form?.querySelectorAll('.invalid-field').forEach((e: any) => e.classList.remove('invalid-field'));
        const errors: any = {};

        if (formFields.nameInput?.value === '') {
            errors[formFields.nameInput?.id] = '';
        } else if (!REGEX.sendName.test(formFields.nameInput?.value)) {
            errors[formFields.nameInput?.id] = 'Invalid  name format.';
        }
        if (formFields.lastNameInput?.value === '') {
            errors[formFields.lastNameInput?.id] = '';
        } else if (!REGEX.sendName.test(formFields.lastNameInput?.value)) {
            errors[formFields.lastNameInput?.id] = 'Invalid last name format.';
        }
        if (formFields.phoneInput?.value === '') {
            errors[formFields.phoneInput.id] = '';
        }
        else if (formFields.phoneInput?.value?.length < 14) {
            errors[formFields.phoneInput.id] = 'Invalid phone format.';
        }
        if (formFields.addressInput?.value === '') {
            errors[formFields.addressInput?.id] = '';
        }
        else if (!REGEX.sendZip.test(formFields.addressInput?.value?.trim()) || this.addressInput?.value == '00000') {
            errors[formFields.addressInput?.id] = 'Invalid zip code format.';
        }
        if (formFields.emailInput?.value === '') {
            errors[formFields.emailInput?.id] = '';
        } else if (!REGEX.sendEmail.test(formFields.emailInput?.value)) {
            errors[formFields.emailInput?.id] = 'Invalid email address, please try again.';
        }

        const errorIdChekboxSpan = this.handleCheckboxChange();

        if(errorIdChekboxSpan){
            errors[errorIdChekboxSpan] = '';
        }
        let self = this;
        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = self.form.querySelector(`#${fieldId}`);
            const fieldError: any = self.form.querySelector(`#${fieldId}-error-msg`);
            if (field?.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                if (fieldError?.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;
    }

    public getFormFields(): any {

        let residentialDesk = (document.querySelector('input[name="mobile-request-call"]') as HTMLInputElement)?.checked;

        const fields = {
            nameInput: this.nameInput,
            lastNameInput: this.lastNameInput,
            emailInput: this.emailInput,
            phoneInput: this.phoneInput,
            addressInput: this.addressInput,
            isResidential: residentialDesk
        };

        return fields;
    }

    public handleCommonInputError(inputElement: any, errorId: any, validationRegex: any, errorMessage: any) {
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (inputElement.value !== '' && !validationRegex.test(inputElement.value)) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = getErrMsg ?? errorMessage;
            }
        } else {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    public closeBtnHandler(): void {
        // Get the form element
        const formElement = document?.querySelector('.mobile-contact-us') as HTMLInputElement;
        // Traverse up the DOM to find the 'form-sidebar' element
        const sidebarElement = formElement?.closest('#form-sidebar') as HTMLInputElement;
        // Get the close button within the 'drawer-header'
        const closeButtonElement = sidebarElement?.querySelector('.close-btn-drawer button');
        
        closeButtonElement?.addEventListener('click', () => {
            let formDiv = document.querySelector('.mobile-contact-us') as HTMLFormElement;            

            if (formDiv) {
                formDiv.style.display = 'flex';
            }
            if(this.formDivHeader) {
                this.formDivHeader.style.display = 'flex';
            }
            if(this.thankYouHeader) {
                this.thankYouHeader.classList.add('hidden');
            }
            if(this.thankYouDesc) {
                this.thankYouDesc.classList.add('hidden');
            }
            if(this.errorMsg) {
                this.errorMsg.classList.add('hidden');
            }
            if(this.eroorDesc) {
                this.eroorDesc.classList.add('hidden');
            }
            if(this.noServiceErrorMsg) {
                this.noServiceErrorMsg.classList.add('hidden');
            }
            if(this.noServiceDescription) {
                this.noServiceDescription.classList.add('hidden');
            }
            this.clearInputs();
        });
    }

    private clearInputs(): void {
        if (this.nameInput) this.nameInput.value = '';
        if (this.lastNameInput) this.lastNameInput.value = '';
        if (this.emailInput) this.emailInput.value = '';
        if (this.phoneInput) this.phoneInput.value = '';
        if (this.addressInput) this.addressInput.value = '';
    }


    public formHide(){
        let formDiv = document.querySelector('.mobile-contact-us') as HTMLFormElement;
        let formDivHeader = document.querySelector('.form-heading') as HTMLFormElement;
        if (formDiv) {
            formDiv.style.display = 'none';
        }
    
        if (formDivHeader) {
            formDivHeader.style.display = 'none';
        }
    }
}
const screenType = reportScreenSize();
if(screenType !== ScreenType.Desktop){
    new MobileContactUs();
}