import moment from "moment";
import { stopLoader } from "../util/loader";
import { calcEstFail, callBookingApi, checkTechAvailability, configureMollyEstimate, configureNotesComments, payloadHelper } from "./callBookingApi";
import { tns } from "tiny-slider";
import { validEstimate } from "./confirmation";
import { isTouchDevice } from "../util/device-detection";
import { closeFunctionality } from "../util/createModal";
import { getBrandDetailsFromBrandJson } from "../util/share";

let date: any, time: any, schaduleId: any;
function setCard(data: any) {
    let uniqueDates = new Set();
    return data.map((item: any) => {
        let currItemDate = item.startDate.split('T')[0];
        const filteredData: any = data?.some((x: any) => moment(x.startDate.split('T')[0]).format('DD MMM, YYYY') == moment(currItemDate).format('DD MMM, YYYY') && x.isAvailable);
        if (!uniqueDates.has(currItemDate)) {
            uniqueDates.add(currItemDate);
            return (`
                <li class="date-details-item">
                <button type="button" class="${!filteredData ? 'date-details-disable' : moment(currItemDate).format('ddd DD MMM, YYYY') === moment(date).format('ddd DD MMM, YYYY') ? 'date-details-inactive date-details-active dateapp' : 'date-details-inactive dateapp'}"
                value=${currItemDate}>
                    <span value=${currItemDate} class="date-text-small">${moment(currItemDate).format('MMM')}</span>
                    <strong value=${currItemDate} class="date-text-normal">${moment(currItemDate).format('DD')}</strong>
                    <input class="getDateApp" hidden value=${currItemDate}>
                    <input class="scheduleID" hidden value=${item.scheduleID}>                                
                    <span value=${currItemDate} class="date-text-small">${moment(currItemDate).format('ddd')} </span>
                </button>
            </li>
            `)
        }

    })
}

function setTinySlider(data: any, currentIndex: any) {
    try {
        tns({
            container: '.mly-date-slider',
            loop: false,
            gutter: 8,
            items: 7,
            slideBy: 'page',
            nav: false,
            autoplay: false,
            speed: 400,
            swipeAngle: false,
            autoplayButtonOutput: false,
            mouseDrag: true,
            lazyload: true,
            startIndex: currentIndex,
            controlsContainer: "#customize-controls",
            responsive: {
                350: {
                    items: 5,
                },
                640: {
                    items: 5,
                },
                768: {
                    items: 5,
                },
                1024: {
                    items: 7,
                }
            }
        });
        
    } catch (error) {

    }
}

function handleLeadFlow(bookingPayload: any) {
    bookingPayload.isLeadOnly = true;
    sessionStorage.setItem('IsLeadOnly',bookingPayload.isLeadOnly);
    callBookingApi(bookingPayload);
}

async function initCalendarScreen(bookingPayload: any) {
    const elems = document.querySelectorAll('#form-section .form-step');
    elems.forEach((el: any) => {
        if (el?.className.includes('appointment-details-section')) {
            el.classList.add('form-step-active');
        }
        else {
            el.classList.remove('form-step-active');
        }
    });
    let progressBar = document.querySelector("#progress") as HTMLElement;
    progressBar.style.width = "100%";
    let bars = document.querySelectorAll('.progress-step');
    bars[1].classList.add("prograssbar-length");
    bars[1].classList.add("progress-step-active");
    const type = bookingPayload.jobFrequency?.toLowerCase();
    const recurheading = document.querySelector('.recurring-estimation-heading') as HTMLElement;
    const otheading = document.querySelector('.one-time-heading-wrap') as HTMLElement;
    if (type?.includes('recurring')) {
        recurheading.style.display = 'flex';
        otheading.style.display = 'none';
        bookingPayload.serviceText = 'Estimate';
    } else {
        recurheading.style.display = 'none';
        otheading.style.display = 'flex';
        bookingPayload.serviceText = 'One Time Clean';
    }
    const conceptCode = (document.getElementById("conceptCode") as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    let pauseLeadFlag = brandData.pause_lead_flag;
   if(!pauseLeadFlag) {
    const deviceInfo = isTouchDevice();
    const legalText = document.querySelector('.heading-thanyou-text');
    if (legalText && legalText?.textContent?.trim() !== '') {
        const bookingLeadSentCheck = sessionStorage.getItem('bookingLeadSent');
        if(deviceInfo !== true && bookingLeadSentCheck === 'false') {     
                 
            window.onbeforeunload = async function () {
                window.onbeforeunload = null;
                bookingPayload.isLeadOnly = true;
                bookingPayload.serviceText = 'Lead Only';
                sessionStorage.setItem("fakeBrowserCloseEvent",'true');
                await callBookingApi(bookingPayload, true);
                sessionStorage.removeItem("fakeBrowserCloseEvent");
            }
        }
   }
    
   }

}
let mcfObj:any;
export function calendarFlowMLY(bookingPayload: any) {
    const div = document.querySelector('.calendar-container');
    if (div) {
        const sessionZip = sessionStorage.getItem('zipcode');
        const estFlag = sessionStorage.getItem('TA_IsEstimate');
        if(sessionZip && estFlag && (bookingPayload.zipCode === sessionZip && bookingPayload.jobFrequency === estFlag) ){
            displayEstimate(bookingPayload);
            stopLoader();
            return;
        }
        sessionStorage.setItem('zipcode', bookingPayload.zipCode);
        sessionStorage.setItem('TA_IsEstimate', bookingPayload.jobFrequency);
        mcfObj = new MollyCalendarFlow(bookingPayload);
        checkTechAvailability(mcfObj.jobFreq)
            .then((response: any) => {
                if (response?.resultCollection) {
                    const data = response?.resultCollection;
                    let currentIndex = data.findIndex((dt: any) => dt.isAvailable);
                    if (currentIndex!= -1) {
                        displayEstimate(bookingPayload);       
                        date = data[currentIndex]?.startDate;
                        time = '';
                        if (mcfObj.dateSlider) {
                            mcfObj.dateSlider.innerHTML = setCard(data).join('');
                        }
                        mcfObj.selectedDateTime.innerHTML = `${date ? moment(date).format('ddd MMM DD') : "Please Select a Date"}, <span class="selected-time"> Please Select a Time </span>`;
                        mcfObj.addListner(data);
                        let startIndex;
                        let numSlots = getSlotPeriodicity(data);
                        if(mcfObj.jobFreq.toLowerCase().includes("recurring")){
                            startIndex = Math.floor(currentIndex / 13);
                        }
                        else{
                            startIndex = Math.floor(currentIndex / numSlots);
                        }
                        
                        setTinySlider(data, startIndex);
                        mcfObj.setTimeUl(data, date.split('T')[0]);
                        stopLoader();
                    }
                    else {
                        console.log("Tech Availability API has no available slots => Submitting Lead flow ");
                        window.onbeforeunload = null;
                        handleLeadFlow(bookingPayload);
                    }

                }
                else {
                    console.log("Tech Availability API returns empty => Submitting Lead flow ");
                    window.onbeforeunload = null;
                    handleLeadFlow(bookingPayload);
                }

            })
            .catch((error) => {
                console.error("Tech Availability API fail=> Submitting lead flow:  ", error);
                window.onbeforeunload = null;
                handleLeadFlow(bookingPayload);
            });
    }
    else {
        console.error("Error in HTML");
    }

}



function displayEstimate(bookingPayload:any):void {
    const divTag = document.querySelector('.one-time-estimate-wrap') as HTMLElement;
    const estimateExists = !calcEstFail() && validEstimate() && bookingPayload.jobFrequency.toLowerCase().includes("one-time") as boolean;
    initCalendarScreen(bookingPayload);
    configureMollyEstimate()
    .then((CESCheck:any)=>{
        if (estimateExists){
            let htmlStr;
            const estimate = sessionStorage.getItem('mollyEstimate');
            const estimateData = JSON.parse(estimate as string);
            const estimateContainer = document.querySelector('.one-time-amount') as HTMLElement;
            const oneTimeClean = (document.getElementById('ont-time-clean') as HTMLInputElement)?.checked;
            const moveOutClean = (document.getElementById('move-out-clean') as HTMLInputElement)?.checked;
            const dateDay = getDayOfWeek(date);
            if(oneTimeClean){
                if(dateDay === 'Saturday'){
                    htmlStr = `$${Math.round(estimateData?.estimateOccasionalRangeLowSaturday)} - $${Math.round(estimateData?.estimateOccasionalRangeHighSaturday)}`;
                }
                else{
                    htmlStr = `$${Math.round(estimateData?.estimateOccasionalRangeLow)} - $${Math.round(estimateData?.estimateOccasionalRangeHigh)}`;
                }
            }
            else if(moveOutClean){
                if(dateDay === 'Saturday'){
                    htmlStr = `$${Math.round(estimateData?.estimateMoveRangeLowSaturday)} - $${Math.round(estimateData?.estimateMoveRangeHighSaturday)}`;
                }
                else{
                    htmlStr = `$${Math.round(estimateData?.estimateMoveRangeLow)} - $${Math.round(estimateData?.estimateMoveRangeHigh)}`;
                }
            }
            estimateContainer.innerHTML = htmlStr as string;
            // Extract low and high values to check if they are zero
            const lowEstimate = oneTimeClean ?
                (dateDay === 'Saturday' ? estimateData?.estimateOccasionalRangeLowSaturday : estimateData?.estimateOccasionalRangeLow) :
                (dateDay === 'Saturday' ? estimateData?.estimateMoveRangeLowSaturday : estimateData?.estimateMoveRangeLow);

            const highEstimate = oneTimeClean ?
                (dateDay === 'Saturday' ? estimateData?.estimateOccasionalRangeHighSaturday : estimateData?.estimateOccasionalRangeHigh) :
                (dateDay === 'Saturday' ? estimateData?.estimateMoveRangeHighSaturday : estimateData?.estimateMoveRangeHigh);
           
            let priceText = `Price: {${htmlStr}}`;
            if(CESCheck && divTag && lowEstimate > 0 && highEstimate > 0){
                showEstimate(); 
                sessionStorage.setItem("PriceNote",priceText);
            }
            else{
                hideEstimate();
                sessionStorage.setItem("PriceNote",`${priceText}, No Calendar Pricing`);
            }
            
        }
        else{
            hideEstimate();
        }
    }).catch((err:any)=>{
        hideEstimate();
    }); 
    
}

function hideEstimate(){
    const estimateDiv = document.querySelector('.one-time-estimate-wrap') as HTMLElement;
    if(estimateDiv){
        estimateDiv.classList.add('hidden');
    }
    
}
function showEstimate(){
    const estimateDiv = document.querySelector('.one-time-estimate-wrap') as HTMLElement;
    if(estimateDiv){
        estimateDiv.classList.remove('hidden');
    }
    
}

export function getDayOfWeek(dateString: any) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
}

export class MollyCalendarFlow {
    public dateSlider: HTMLElement;
    private availableHrs: HTMLElement;
    public selectedDateTime: HTMLElement;
    private requestCallBack: HTMLInputElement;
    private backBtn: HTMLButtonElement;
    private confirmBtn: HTMLButtonElement;
    public bookingPayload: any;
    private containerClass: HTMLElement;
    private mollyModalClose: HTMLElement | null;
    public jobFreq: any;
    public pauseLeadFlag: boolean;
    constructor(payload: any) {
        this.bookingPayload = payload;
        this.jobFreq = this.bookingPayload.jobFrequency;
        this.dateSlider = document.querySelector('.date-details-wrapper #start-index') as HTMLElement;
        this.selectedDateTime = document.querySelector('.appointment-date-wrap .appointment-section-text') as HTMLElement;
        this.requestCallBack = document.querySelector('.sheduling-time-box #link-checkbox') as HTMLInputElement;
        this.backBtn = document.querySelector('.btn-container .btn-cancel') as HTMLButtonElement;
        this.confirmBtn = document.querySelector('.btn-container .primary-btn') as HTMLButtonElement;
        this.containerClass = document.querySelector('.calendar-container') as HTMLElement;
        const element = document.querySelector('#mly-mobile-close') as HTMLInputElement | null;
        this.mollyModalClose = element? element : null;
        this.pauseLeadFlag = false;
        this.initListeners();
        this.availableHrs = this.getCalendarRow(); 
    }
    removeListeners(){
        this.backBtn.removeEventListener("click", this.backListener);
        this.confirmBtn.removeEventListener("click", this.handleConfirmBtn);
        this.requestCallBack.removeEventListener("click", this.handleDisableApp);
        if (!this.pauseLeadFlag) this.mollyModalClose?.removeEventListener("click", this.handleMobileClose);
    }
    async initListeners() {
        const conceptCode = (document.getElementById("conceptCode") as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        this.pauseLeadFlag = brandData.pause_lead_flag;
        this.removeListeners();
        this.backBtn.addEventListener('click', this.backListener.bind(this));
        this.confirmBtn.addEventListener('click', this.handleConfirmBtn.bind(this));
        this.requestCallBack.addEventListener('click', this.handleDisableApp.bind(this));
        if (!this.pauseLeadFlag) {
            this.mollyModalClose?.removeEventListener('click', closeFunctionality);
            this.mollyModalClose?.addEventListener('click', this.handleMobileClose.bind(this))
        }
    }
    backListener(e: any) {
        e.preventDefault();
        const elems = document.querySelectorAll('#form-section .form-step');
        elems.forEach((el: any) => {
            if (el?.className.includes('appointment-details-section')) {
                el.classList.remove('form-step-active');
            }
            else {
                el.classList.add('form-step-active');
            }
        });
        let progressBar = document.querySelector("#progress") as HTMLElement;
        progressBar.style.width = "0%";
        let bars = document.querySelectorAll('.progress-step');
        bars[1].classList.remove("prograssbar-length");
        bars[1].classList.remove("progress-step-active");
    };
    handleConfirmBtn(e:any){
        e.preventDefault();
        if (this.requestCallBack.checked) {
            // Request callback flow
            this.bookingPayload.isLeadOnly = true;
            this.bookingPayload.serviceText = 'Lead Only';
            sessionStorage.setItem('IsLeadOnly',this.bookingPayload.isLeadOnly);
        }
        if (this.validateForm() && !sessionStorage.getItem("callFromCalendar")) {
            if (!this.requestCallBack.checked) {
                this.storeDateTime();
            }
            this.bookingPayload.scheduleID = schaduleId;
            if(sessionStorage.getItem('IsLeadOnly') == 'true'){
                this.bookingPayload.isLeadOnly = true;
            }
            else{
                this.bookingPayload.isLeadOnly = false;
            }
            window.onbeforeunload = null;
            sessionStorage.setItem("callFromCalendar","true");
            callBookingApi(this.bookingPayload);
        }
    }
    hideDateTimeError() {
        const dataTimeErrorModal = document.getElementById('mly-appointment-date-time-error-msg') as HTMLElement;
        if (dataTimeErrorModal) {
            dataTimeErrorModal.classList.add('hidden');
        }
        const requestCallbackErrorModal = document.getElementById('mly-link-checkbox-error-msg') as HTMLElement;
        if (requestCallbackErrorModal) {
            requestCallbackErrorModal.classList.add('hidden');
        }
    }

    async handleMobileClose() {
        const deviceInfo = isTouchDevice();
        const bookingLeadSent = sessionStorage.getItem("bookingLeadSent");
        const legalText = document.querySelector('.heading-thanyou-text');
        if (legalText && legalText?.textContent?.trim() !== '') {
            if (deviceInfo && bookingLeadSent === 'false') {
                this.bookingPayload.isLeadOnly = true;
                this.bookingPayload.serviceText = 'Lead Only';
                await callBookingApi(this.bookingPayload, true);
            }
        }
    }

    private validateForm(): boolean {
        const dateTime = document.getElementById('mly-appointment-date-time-error-msg') as HTMLElement;
        const requestCallbackCheckbox = document.getElementById('mly-link-checkbox-error-msg') as HTMLElement;
        const linkCheckbox: HTMLInputElement | null = document.getElementById('link-checkbox') as HTMLInputElement | null;

        if ((date && time) || linkCheckbox?.checked) {
            return true;
        } else {
            if (dateTime) {
                dateTime.classList.remove('hidden');
                if (!dateTime?.innerText) {
                    dateTime.innerText = "Please select a date and time";
                }
            }
            if (requestCallbackCheckbox) {
                requestCallbackCheckbox.classList.remove('hidden');

                if (!requestCallbackCheckbox?.innerText) {
                    requestCallbackCheckbox.innerText = "or choose to get a call back.";
                }
            }
            return false;
        }
    }
    handleDisableApp() {
        if ( this.containerClass && this.requestCallBack.checked) {
            this.containerClass.classList.add('disable-calendar');
        }
        else if ( this.containerClass && !(this.requestCallBack.checked)) {
            this.containerClass.classList.remove('disable-calendar');
        }
        const callbackwrapper = document.querySelector('.callback-wrapper');
        if (callbackwrapper) {
            callbackwrapper?.classList.toggle('callback-requested');
        }

        this.hideDateTimeError();

    }

    getCalendarRow() {
        let rows: any;
        document.querySelectorAll('.calendar-row').forEach((row)=>{
            row.innerHTML = '';
        });
        if (this.jobFreq.toLowerCase().includes('recurring')) {
            rows = document.querySelector('.recurring-calendar');
        }
        else {
            rows = document.querySelector('.one-time-calendar');
        }
        return rows;
    }
    storeDateTime() {
        sessionStorage.setItem('selectedDay', getDayOfWeek(date));
        let timeOfDay:string;
        if(this.jobFreq.toLowerCase().includes('recurring')){
            timeOfDay = getTime(time);
        }
        else{
            timeOfDay = time;
        }   
        sessionStorage.setItem('timeOfDay', timeOfDay);
        sessionStorage.setItem('userSelectDatae', `${date}, ${time}`);
    }
    setTimeUl(allAppData: any, dateVal: any) {
        const calendarRow = this.getCalendarRow();
        if (!calendarRow) return;
    
        const targetDate = moment(dateVal).format('DD MMM, YYYY');
    
        // Combine filtering and mapping in a single loop to reduce iteration complexity
        const ulTimeData = allAppData?.reduce((acc: string[], x: any) => {
            const startDateFormatted = moment(x.startDate).format('DD MMM, YYYY');
    
            // Only process if the start date matches the target date
            if (startDateFormatted === targetDate) {
                acc.push(`<li class="calendar-row-item">
                    <button type="button" value="${x.scheduleID}" 
                        class="calendar-col btn-time ${!x.isAvailable ? 'disable-btn' : ''}" 
                        id="${x.displayText}">
                        ${x.displayText}
                    </button>
                </li>`);
            }
    
            return acc;
        }, []).join('');
    
        // Update the DOM once, after all processing
        calendarRow.innerHTML = ulTimeData;
    
        // Attach listeners to the buttons
        this.setListnerOnUlBtn();
    }

    addListner(allAppData: any) {
        document.querySelectorAll('.dateapp')?.forEach((item: any) => {
            item.addEventListener('click', (e: any) => {
                const rm = document.querySelectorAll('.dateapp')
                rm.forEach(e => e.classList.remove('date-details-active'));
                date = moment(item.querySelector('.getDateApp').value).format('ddd DD MMM, YYYY');
                displayEstimate(this.bookingPayload);
                time = '';
                item.classList.toggle('date-details-active');
                schaduleId = item.querySelector('.scheduleID').value;
                document.querySelector('.appointment-section-text')!.innerHTML = `${moment(item.querySelector('.getDateApp').value).format('ddd MMM DD')}, <span class="selected-time"> Please Select a Time </span>`;
                this.setTimeUl(allAppData, item.querySelector('.getDateApp').value);
            })
        })
    }

    setListnerOnUlBtn() {
        const timesBtn = document.querySelectorAll('.calendar-col');
        let self = this;
        if (timesBtn) {
            timesBtn.forEach((item: any) => {
                item.classList.remove('active');
                item.addEventListener('click', (e: any) => {
                    timesBtn.forEach(e => e.classList.remove('active'));
                    item.classList.toggle('active');
                    const timecontainer = document.querySelector('.selected-time')
                    if (timecontainer)
                        timecontainer.innerHTML = item.innerHTML
                    schaduleId = item.value;
                    time = item.id;
                    self.hideDateTimeError();
                })

            })
        }
    }

}


function getTime(arg: string): string {
    const timeString = arg.trim().split(' - ')
    const parsedTime = moment(timeString, ['h:mm A', 'hh:mm A']);
    const formattedTime = Number(parsedTime.format('HH'));
    if (formattedTime <= 12) {
        return 'Morning';
    }
    else if (formattedTime > 12 && formattedTime <= 17) {
        return 'Afternoon';
    }
    else {
        return 'Evening'
    }

}

function getSlotPeriodicity(data:any){
    /*type Counts = {
        [key: string]: number;
    };
    const counts: Counts = {};*/
    let startingDate = date.split('T')[0];
    let filteredData = data.filter((entry:any)=>{
        let sDate = entry.startDate.split('T')[0];
        return (sDate === startingDate);
    });
    /*data.forEach((entry: any) => {
        let { startDate } = entry;
        startDate = startDate.split['T'][0];
        if (!counts[startDate]) {
            counts[startDate] = 0;
        }
        counts[startDate]++;
    });
    const date1 = date.split('T')[0];
    const specificDateCount = counts[date1] || 0;*/
    return filteredData.length;
}


