
export function getPageInfo(){
        let pageTypeObj:any = {};
        let matchedPageType: any;
        let originUrl = window.location.origin;
        let indexUrl = window.location.href;
        const pageType = (document.getElementById("pageType") as HTMLInputElement)?.value;
        const weblocationInfo = window?.crownpeakWeblocationsByIdDetail;
        const isStateHomePage = localStorage?.brandDetails?.include_statename && weblocationInfo && indexUrl === `${originUrl}/${weblocationInfo?.stateName}/${localStorage.dbaName}/` || indexUrl === `${originUrl}/${localStorage?.brandDetails?.weblocation_path_group}/${weblocationInfo?.stateName}/${localStorage.dbaName}/`;  

        if (originUrl === indexUrl.substring(0, indexUrl.length - 1) || indexUrl === `${originUrl}/${localStorage.dbaName}` 
        || indexUrl === `${originUrl}/${localStorage.dbaName}/` || indexUrl === `${originUrl}/${localStorage?.brandDetails?.weblocation_path_group}/${localStorage.dbaName}/` || isStateHomePage)
        {
            return "Homepage";
        } 
        try {
        if(localStorage?.brandDetails && JSON.parse(localStorage?.brandDetails)?.pageTypeObj)
        {
         //pick the page pageTypeObj from BrandJson for future implementation
         pageTypeObj = JSON.parse(localStorage?.brandDetails)?.pageTypeObj;
        }else {
            pageTypeObj = {
                pageArray: [
                  "Location Map",
                  "Blog",
                  "Service",
                  "Contact Us",
                  "Lead Flow",
                  "Content Page",
                  "White Paper",
                  "Photo Gallery",
                  "Our Team",
                  "Expert Tips",
                  "SiteMap",
                  "Confirmation",
                  "Event"
                ],
              };
        }
        for (const item of pageTypeObj.pageArray) {
            if (pageType?.replace(/\s/g, "")?.toLowerCase()?.includes(item?.replace(/\s/g, "")?.toLowerCase()))
               {
                matchedPageType = item.toLowerCase();
               }
          }
          if (matchedPageType === "Content Page".toLowerCase()) {
            const checkForContentPage = (originUrl === indexUrl.substring(0, indexUrl.length - 1) || indexUrl === `${originUrl}/${localStorage.dbaName}/`);
            if(!checkForContentPage){
                matchedPageType = "Others";
            }else {
                matchedPageType = "Homepage";
            }
          }
          return matchedPageType;
    } catch(error){
        console.log("An error occurred in adding PagetypeDatalayer: ", error);
    }
}
export  function getLocalization(): string
{
   const dbaName =  (document.getElementById('dbaName') as HTMLInputElement)?.value
    if (localStorage.dbaName || dbaName)
    {
        return "Local";
    }
    else
    {
        return "National";
    }
}