//Please note all values are in minutes
export const apiCaching = {
    TestimonyAndReview : 60,
    DynamicMenu : 60,
    IsnRedirectConfig: 60,
    POSSystemLookup : 5,
    FranchisePOSLookup : 5,
    FullAttributeResponse : 60,
    LocateLocationApiWithRoundRobinTrue: 60,
    TechAvailability: 2,
    SiteConfig: 60,
    BrandJsonConfig: 60,
    HereMapConfig: 60,
    OffersLanding: 20,
    OffersPopup: 20,
    OfferTeaser: 20,
    serviceAccordion: 60
  };