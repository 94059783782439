export function createModal(selectorName: any,  pauseLeadFlag: boolean) {
    const divElement = document.querySelector(selectorName) as HTMLElement;
    if (divElement) {
        const divStyling: any = {
            position: 'relative',
        };
        Object.assign(divElement.style, divStyling)
        if (!pauseLeadFlag) {
            const button = document.createElement('button');
            button.type = 'button';
            button.className = 'close';
            button.setAttribute('data-dismiss', 'modal');
            button.setAttribute('aria-label', 'Close');

            button.style.position = 'absolute';
            button.style.top = '-1.5rem';
            button.style.display = 'grid';
            button.style.right = '0.25rem';
            button.style.height = '3.25rem';
            button.style.width = '3.25rem';
            button.style.background = '#fff';
            button.style.borderRadius = '50%';
            button.style.placeItems = 'center';
            button.style.boxShadow = 'rgba(90, 90, 90, 0.15) 7px 9px 17px -1px';

            button.id = 'mly-mobile-close';
            button.title = 'Close';
            button.addEventListener("click", closeFunctionality);
            const span = document.createElement('span');
            span.setAttribute('aria-hidden', 'true');
            span.textContent = 'x';
            span.style.display = 'inline-block';
            span.style.width = '3rem';
            span.style.height = '3rem';
            span.style.top = '0';
            span.style.backgroundImage = 'url(/brand/_assets/images/brand-img/icons/close-circle.svg)';
            span.style.color = 'rgba(0, 0, 0, 0)';
            span.style.overflow = 'hidden';
            span.style.backgroundSize = '3rem';
            span.style.backgroundRepeat = 'no-repeat';
            span.style.backgroundPosition = 'center';
            span.style.fontSize = '0';
            span.style.opacity = '0.8';
            span.style.pointerEvents = 'none';
            button.appendChild(span);
            divElement.appendChild(button);
        }


        const innerFormElement = document.querySelector(`${selectorName} .form-section.mly-lead-flow`) as HTMLElement;
        if (innerFormElement) {
            innerFormElement.style.padding = '2rem 1rem';
            innerFormElement.style.marginTop = '2rem';
            innerFormElement.style.boxShadow = 'rgba(90, 90, 90, 0.5) 7px 9px 17px -1px';
            innerFormElement.style.borderRadius = '0.5rem';
        }
    }
}

export function closeFunctionality() {
    if (!document.referrer?.includes(window.location.origin) || !document.referrer) {
        window.location.href = window.location.origin;
    }
    else {
        window.history.back();
    }
}
