export class Concert {
    private bioBtns: HTMLCollectionOf<Element>;
    private memberBios: any;

    constructor() {
        this.bioBtns = document.getElementsByClassName("bio-btn");
        this.memberBios = document.getElementsByClassName("member-bio");
        

        for (let i = 0; i < this.bioBtns.length; i++) {
            this.bioBtns[i].addEventListener("click", (e) => {
                // Prevent the click event from propagating to parent elements
                e.stopPropagation();
                this.toggleMemberBio(i);
            });
        }
    }

    private toggleMemberBio(index: number): void {
        const bioBtnIcon : any = document.querySelectorAll(".bio-btn svg");
        const memberBio = this.memberBios[index] as HTMLElement; // Cast to HTMLElement
        if (memberBio !== null && memberBio !== undefined) {
            if (memberBio.style.display !== "block") {
                memberBio.style.display = "block";
                bioBtnIcon[index].style.transform = "rotate(-90deg)";
            } else {
                memberBio.style.display = "none";
                bioBtnIcon[index].style.transform = "rotate(90deg)";
            }
        }
        
    }
}
