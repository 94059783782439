import { gtmCustomEventLeadFlow } from "./gtm-custom-event-lead-flow";

export function requestEstimate(submitType: string, formType: string, formStatus: string, formDescription: string) {
    const doingBusinessAs = localStorage.getItem('doingBusinessAs')?localStorage.getItem('doingBusinessAs'):'';
    const weblocID = localStorage.getItem('weblocationId') ?localStorage.getItem('weblocationId') : '';
    let custEvObj = {
        event: 'request_estimate',
        locationID: weblocID,
        locationDBA: doingBusinessAs,
        submitType: submitType,
        formType: formType,
        formDescription: formDescription,
        formStatus: formStatus
    }
    gtmCustomEventLeadFlow(custEvObj);
    sessionStorage.setItem('request_estimate', JSON.stringify(custEvObj));
}